import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';

function CustomTooltip(props) {
    const { title, placement, active, children } = props;
    const [open, setOpen] = useState(active ? true : false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        setOpen(active ? true : false);
    }, [active])
    return (
        <Tooltip
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            placement={placement ? placement : 'top'}
            title={
                <Typography variant='body1'>
                    {title}
                </Typography>
            }
        >
            {children}
        </Tooltip>
    );
};
CustomTooltip.propTypes = {
    title: PropTypes.string,
    placement: PropTypes.string,
    active: PropTypes.bool
};
export default CustomTooltip;