import axios from 'axios';

const getToken = () => sessionStorage.getItem('log-ide');

const requestPost = (route, data) => {
    return axios.post(route, data, {
        headers: { 'authorization': getToken() }
    })
        .then(res => res)
        .catch(err => {
            throw new Error(err.response?.data?.message || err.message || 'Error en la solicitud');
        });
};

const requestGet = (route, params, run) => {
    if (!run) {
        return Promise.resolve({ data: {} });
    }
    return axios.get(route, {
        params,
        headers: { 'authorization': getToken() }
    })
        .then(res => res)
        .catch(err => {
            throw new Error(err.response?.data?.message || err.message || 'Error en la solicitud');
        });
};

export { requestPost, requestGet };