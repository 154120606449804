import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack } from '@mui/material';
import { GroupTwoTone, GroupsTwoTone, ContentPasteSearchTwoTone, QueryStatsTwoTone, WifiFindTwoTone } from '@mui/icons-material';
import AppBar from '../../../components/appbar/Appbar';
import Widgets from '../../../components/widgets/Widgets';
import InvRangeTime from '../../../components/range/InvRangeTime';
import Products from './panels/Products';
import Researchers from './panels/Researchers';
import Groups from './panels/Groups';
import Projects from './panels/Projects';
import Areas from './panels/Areas';
import { requestPost } from '../../../../utils/request';
import { filterDependencesInv, metricsInv, formatMessage, getParamsRoute } from '../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../constants';

function Campus(props) {
    const { dependence, investigation, settings, setInvestigation, setSettings } = props;
    const [metric, setMetric] = useState('');

    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const widgets = [
        {
            title: t('dependence.investigation.products'),
            total: investigation.metrics.general[constants.METRIC_PUBLICATIONS_FIELD].processed + investigation.metrics.general[constants.METRIC_THESIS_FIELD].processed,
            filter: true,
            icon: <ContentPasteSearchTwoTone />,
            step: 'products',
            subtitle: t('dependence.investigation.citations'),
            sub_total: investigation.metrics.general[constants.METRIC_CITATIONS_FIELD].processed,
        },
        {
            title: t('dependence.investigation.researchers'),
            total: investigation.metrics.general[constants.METRIC_AUTHORS_VALUE_FIELD].processed,
            filter: false,
            icon: < GroupTwoTone />,
            step: 'researchers'
        },
        {
            title: t('dependence.investigation.groups'),
            total: investigation.metrics.general[constants.METRIC_GROUPS_FIELD].processed,
            filter: false,
            icon: <GroupsTwoTone />,
            step: 'groups'
        },
        {
            title: t('dependence.investigation.projects'),
            total: investigation.metrics.general[constants.METRIC_PROJECTS_FIELD].processed,
            filter: true,
            icon: <QueryStatsTwoTone />,
            step: 'projects'
        },
        {
            title: t('dependence.investigation.themesAndWorkAreas'),
            filter: false,
            icon: <WifiFindTwoTone />,
            step: 'themesAndWorkAreas'
        }
    ];

    const handleRoute = (resource) => {
        params.resource = resource;
        const route = getParamsRoute(params);
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        const hierarchy = filterDependencesInv('campus', dependence, params, investigation, location, widgets, navigate);
        if (hierarchy.metrics) {
            if (metric !== hierarchy.metrics.general) {
                setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoCampus')) });
                setMetric(hierarchy.metrics.general);
                Promise.all([
                    requestPost(`${URL_API_INV}/investigation/general_metrics`, {
                        _id: hierarchy.metrics.general,
                        record: hierarchy
                    })
                ])
                    .then(res => {
                        setInvestigation(
                            metricsInv(
                                Object.keys(investigation.metrics.general),
                                res[0].data,
                                hierarchy,
                                investigation,
                                settings
                            )
                        );
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, dependence]);
    return (
        <Box sx={{ bgcolor: 'white' }}>
            <AppBar hierarchy='research' params={params} value={params.campus}>
                <Widgets
                    isAuthor={false}
                    widgets={widgets}
                    stepTab={params.resource}
                    handleStepTab={(resource) => handleRoute(resource)}
                />
            </AppBar>
            <Container
                component='main'
                maxWidth='xl'
            >
                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    sx={{ mt: 1 }}
                >
                    <InvRangeTime />
                </Stack>
                <Stack direction={{ md: 'column' }} sx={{ mt: 1, pb: 3 }} spacing={3}>
                    <Paper elevation={4} sx={{ bgcolor: '#DDDDDD', boxShadow: 0 }}>
                        <TabContext value={params.resource}>
                            <TabPanel value='products'>
                                <Products />
                            </TabPanel>
                            <TabPanel value='researchers'>
                                <Researchers />
                            </TabPanel>
                            <TabPanel value='groups'>
                                <Groups />
                            </TabPanel>
                            <TabPanel value='projects'>
                                <Projects />
                            </TabPanel>
                            <TabPanel value='themesAndWorkAreas'>
                                <Areas />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Stack>
            </Container>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Campus);