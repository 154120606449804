import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { Box, IconButton, Grid, DialogContentText, TextField, Accordion, AccordionSummary, Button, Typography, AccordionDetails, Avatar, Card, CardHeader } from '@mui/material';
import { ExpandMore, EditTwoTone, DeleteTwoTone } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '../../../../../components/Dialog';
// import UploadSingleFile from '../../../../../components/upload/UploadSingleFile';
import { formatLowerCase, formatDate, formatMessage, formatDisplay } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../../constants';

function News(props) {
    const { login, investigation, settings, setSettings, setLogin } = props;
    const [values, setValues] = useState({
        dialog: false,
        type: '',
        label: {
            title: '',
            description: ''
        },
        onDrop: false
    });
    const navigate = useNavigate();
    const defaultHierarchy = investigation.hierarchy;
    const validationSchema = yup.object({});
    const validationSchemaAdd = yup.object({
        [constants.NEWS_TITLE_ENGLISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_TITLE_ENGLISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_TITLE_SPANISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_TITLE_SPANISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_DESCRIPTION_ENGLISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_DESCRIPTION_SPANISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_DESCRIPTION_SPANISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_DATE_FIELD.field]: yup
            .string('Ingrese la ' + formatLowerCase(constants.NEWS_DATE_FIELD.label))
            .required('El campo es requerido.'),
        // [constants.NEWS_IMAGE_FIELD.field]: yup
        //     .string('Ingrese el ' + formatLowerCase(constants.NEWS_IMAGE_FIELD.label))
        //     .required('El campo es requerido.'),
        // [constants.NEWS_IMAGE64_FIELD.field]: yup
        //     .string('Ingrese el ' + formatLowerCase(constants.NEWS_IMAGE64_FIELD.label))
        //     .required('El campo es requerido.'),
        [constants.NEWS_TYPE_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_TYPE_FIELD.label))
            .required('El campo es requerido.'),
    });
    const validationSchemaEdit = yup.object({
        [constants.NEWS_ID_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_ID_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_TITLE_ENGLISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_TITLE_ENGLISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_TITLE_SPANISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_TITLE_SPANISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_DESCRIPTION_ENGLISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_DESCRIPTION_SPANISH_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_DESCRIPTION_SPANISH_FIELD.label))
            .required('El campo es requerido.'),
        [constants.NEWS_DATE_FIELD.field]: yup
            .string('Ingrese la ' + formatLowerCase(constants.NEWS_DATE_FIELD.label))
            .required('El campo es requerido.')
    });
    const validationSchemaDelete = yup.object({
        [constants.NEWS_ID_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.NEWS_ID_FIELD.label))
            .required('El campo es requerido.'),
    });
    const formikNews = useFormik({
        initialValues: {
            [constants.NEWS_ID_FIELD.field]: '',
            [constants.NEWS_TITLE_ENGLISH_FIELD.field]: '',
            [constants.NEWS_TITLE_SPANISH_FIELD.field]: '',
            [constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field]: '',
            [constants.NEWS_DESCRIPTION_SPANISH_FIELD.field]: '',
            [constants.NEWS_DATE_FIELD.field]: '',
            [constants.NEWS_IMAGE_FIELD.field]: '',
            [constants.NEWS_IMAGE64_FIELD.field]: '',
            [constants.NEWS_TYPE_FIELD.field]: ''
        },
        validationSchema: values[constants.NEWS_TYPE_FIELD.field] === 'edit'
            ? validationSchemaEdit
            : values[constants.NEWS_TYPE_FIELD.field] === 'delete'
                ? validationSchemaDelete
                : values[constants.NEWS_TYPE_FIELD.field] === 'add'
                    ? validationSchemaAdd
                    : validationSchema,
        onSubmit: (data) => {
            setSettings({ backdrop: formatMessage(true, '', 'Procesando la información.') });
            const hierarchy = {
                ...defaultHierarchy,
                resource: 'Lista de noticias',
                subResource: values.label.title,
                action: { type: 'Información de la noticia', value: JSON.stringify({ ...data, image64: 'codeStr' }) },
            };
            Promise.all([
                requestPost(`${URL_API_INV}/news/add`, {
                    type: values[constants.NEWS_TYPE_FIELD.field],
                    data: data,
                    record: hierarchy
                })
            ])
                .then(res => {
                    if (res[0].data.access) {
                        setLogin({ news: { infoNews: res[0].data.infoNews } });
                        setSettings({ snackbar: formatMessage(true, 'success', res[0].data.message) });
                        handleClose();
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err)
                    });
                });
        },
    });
    const handleClose = () => {
        setValues({
            dialog: false,
            type: '',
            label: {
                title: '',
                description: ''
            },
        });
    };
    const handleDatePicker = (field, value) => {
        formikNews.setFieldValue(field, value, true);
    };
    const handleAdd = (type) => {
        formikNews.setFieldValue(constants.NEWS_ID_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_TITLE_ENGLISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_TITLE_SPANISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_DESCRIPTION_SPANISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_DATE_FIELD.field, '', true);
        // formikNews.setFieldValue(constants.NEWS_IMAGE_FIELD.field, type === 'text' ? '1' : '', true);
        // formikNews.setFieldValue(constants.NEWS_IMAGE64_FIELD.field, type === 'text' ? '1' : '', true);
        formikNews.setFieldValue(constants.NEWS_TYPE_FIELD.field, type, true);
        setValues({
            ...values,
            dialog: true,
            type: 'add',
            label: {
                title: 'Agregar noticia',
                description: 'Por favor ingrese los datos de la nueva noticia.'
            },
        });
    };
    const handleEdit = (infoNew) => {
        formikNews.setFieldValue(constants.NEWS_ID_FIELD.field, infoNew[constants.NEWS_ID_FIELD.field], true);
        formikNews.setFieldValue(constants.NEWS_TITLE_ENGLISH_FIELD.field, infoNew[constants.NEWS_TITLE_ENGLISH_FIELD.field], true);
        formikNews.setFieldValue(constants.NEWS_TITLE_SPANISH_FIELD.field, infoNew[constants.NEWS_TITLE_SPANISH_FIELD.field], true);
        formikNews.setFieldValue(constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field, infoNew[constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field], true);
        formikNews.setFieldValue(constants.NEWS_DESCRIPTION_SPANISH_FIELD.field, infoNew[constants.NEWS_DESCRIPTION_SPANISH_FIELD.field], true);
        formikNews.setFieldValue(constants.NEWS_DATE_FIELD.field, infoNew[constants.NEWS_DATE_FIELD.field], true);
        // formikNews.setFieldValue(constants.NEWS_IMAGE_FIELD.field, '', true);
        // formikNews.setFieldValue(constants.NEWS_IMAGE64_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_TYPE_FIELD.field, infoNew[constants.NEWS_TYPE_FIELD.field], true);
        setValues({
            ...values,
            dialog: true,
            type: 'edit',
            label: {
                title: 'Editar noticia',
                description: 'Por favor ingrese la nueva información.'
            },
        });
    };
    const handleDelete = (infoNew) => {
        formikNews.setFieldValue(constants.NEWS_ID_FIELD.field, infoNew[constants.NEWS_ID_FIELD.field], true);
        formikNews.setFieldValue(constants.NEWS_TITLE_ENGLISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_TITLE_SPANISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_DESCRIPTION_SPANISH_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_DATE_FIELD.field, '', true);
        // formikNews.setFieldValue(constants.NEWS_IMAGE_FIELD.field, infoNew[constants.NEWS_IMAGE_FIELD.field], true);
        // formikNews.setFieldValue(constants.NEWS_IMAGE64_FIELD.field, '', true);
        formikNews.setFieldValue(constants.NEWS_TYPE_FIELD.field, infoNew[constants.NEWS_TYPE_FIELD.field], true);
        setValues({
            ...values,
            dialog: true,
            type: 'delete',
            label: {
                title: 'Eliminar noticia',
                description: '¿Estas seguro que deseas eliminar a ' + infoNew[constants.NEWS_TITLE_FIELD + settings.language] + '?'
            },
        });
    };
    // const onDrop = async (acceptedFiles, accept) => {
    //     const files = acceptedFiles.filter(file => file[constants.NEWS_TYPE_FIELD.field].startsWith(accept));
    //     if (files.length > 0) {
    //         const file = acceptedFiles[0];
    //         const fileBase64 = await fileToBase64(file);
    //         formikNews.setFieldValue(constants.NEWS_IMAGE_FIELD.field, Object.assign(file, { preview: URL.createObjectURL(file) }), true);
    //         formikNews.setFieldValue(constants.NEWS_IMAGE64_FIELD.field, fileBase64, true);
    //         setValues({ ...values, onDrop: false });
    //     } else {
    //         formikNews.setFieldValue(constants.NEWS_IMAGE_FIELD.field, '', true);
    //         formikNews.setFieldError(constants.NEWS_IMAGE_FIELD.field, '');
    //         setValues({ ...values, onDrop: true });
    //     }
    // }
    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de noticias.') });
            const hierarchy = {
                ...defaultHierarchy,
                resource: 'Lista de noticias'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/news/panel`, { record: hierarchy }),
            ])
                .then(res => {
                    if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                        setLogin({ news: { infoNews: res[0].data.infoNews } });
                    } else if (res[0].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);
    return (
        <Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                >
                    <Typography>Carrusel de imágenes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button
                        fullWidth
                        variant='contained'
                        onClick={() => handleAdd('image')}
                        sx={{ mb: 3 }}
                    >
                        Agregar imagen
                    </Button>
                    {login.news.infoNews
                        .filter(item => item[constants.NEWS_TYPE_FIELD.field] === 'image')
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map(item => (
                            <Card key={item._id} sx={{ mb: 1 }}>
                                <CardHeader
                                    title={item[constants.NEWS_TITLE_FIELD + settings.language]}
                                    subheader={formatDate(item[constants.NEWS_DATE_FIELD.field]) + ' - ' + item[constants.NEWS_DESCRIPTION_FIELD + settings.language]}
                                    action={
                                        <Box>
                                            <IconButton onClick={() => handleEdit(item)} sx={{ mr: 0.5 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35,
                                                        bgcolor: theme => theme.palette.primary.main,
                                                    }}
                                                >
                                                    <EditTwoTone />
                                                </Avatar>
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(item)} sx={{ mr: 0.5 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35,
                                                        bgcolor: theme => theme.palette.error.main,
                                                    }}
                                                >
                                                    <DeleteTwoTone />
                                                </Avatar>
                                            </IconButton>
                                        </Box>
                                    }
                                />
                            </Card>
                        ))}
                    {/* <ImageList>
                        <ImageListItem key='Subheader' cols={2} sx={{ mb: 1 }} />
                        {login.news.infoNews.filter(item => item[constants.NEWS_TYPE_FIELD.field] === 'image').map(item => (
                            <ImageListItem key={item._id}>
                                <img
                                    src={`${URL_API_INV}/images/${item[constants.NEWS_IMAGE_FIELD.field]}`}
                                    alt={item[constants.NEWS_TITLE_FIELD + settings.language]}
                                    loading='lazy'
                                />
                                <ImageListItemBar
                                    title={item[constants.NEWS_TITLE_FIELD + settings.language]}
                                    subtitle={formatDate(item[constants.NEWS_DATE_FIELD.field]) + ' - ' + item[constants.NEWS_DESCRIPTION_FIELD  + settings.language]}
                                    actionIcon={
                                        <Box>
                                            <IconButton onClick={() => handleEdit(item)} sx={{ mr: 0.5 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35,
                                                        bgcolor: theme => theme.palette.primary.main,
                                                    }}
                                                >
                                                    <EditTwoTone />
                                                </Avatar>
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(item)} sx={{ mr: 0.5 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35,
                                                        bgcolor: theme => theme.palette.error.main,
                                                    }}
                                                >
                                                    <DeleteTwoTone />
                                                </Avatar>
                                            </IconButton>
                                        </Box>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList> */}
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                >
                    <Typography>Carrusel de texto</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button
                        fullWidth
                        variant='contained'
                        onClick={() => handleAdd('text')}
                        sx={{ mb: 3 }}
                    >
                        Agregar texto
                    </Button>
                    {login.news.infoNews
                        .filter(item => item[constants.NEWS_TYPE_FIELD.field] === 'text')
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map(item => (
                            <Card key={item._id} sx={{ mb: 1 }}>
                                <CardHeader
                                    title={item[constants.NEWS_TITLE_FIELD + settings.language]}
                                    subheader={formatDate(item[constants.NEWS_DATE_FIELD.field]) + ' - ' + item[constants.NEWS_DESCRIPTION_FIELD + settings.language]}
                                    action={
                                        <Box>
                                            <IconButton onClick={() => handleEdit(item)} sx={{ mr: 0.5 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35,
                                                        bgcolor: theme => theme.palette.primary.main,
                                                    }}
                                                >
                                                    <EditTwoTone />
                                                </Avatar>
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(item)} sx={{ mr: 0.5 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35,
                                                        bgcolor: theme => theme.palette.error.main,
                                                    }}
                                                >
                                                    <DeleteTwoTone />
                                                </Avatar>
                                            </IconButton>
                                        </Box>
                                    }
                                />
                            </Card>
                        ))}
                </AccordionDetails>
            </Accordion>
            <Dialog
                _id='form-user'
                open={values.dialog}
                title={values.label.title}
                width='md'
                handleClose={handleClose}
                handleBack={{
                    active: false,
                    label: '',
                    function: null
                }}
                handleNext={{
                    active: true,
                    label: 'Aceptar',
                    function: null
                }}
            >
                <DialogContentText>
                    {values.label.description}
                </DialogContentText>
                <form onSubmit={formikNews.handleSubmit} id='form-user'>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={5} sx={{ display: formatDisplay(values[constants.NEWS_TYPE_FIELD.field], 'title') }}>
                            <TextField
                                fullWidth
                                id={constants.NEWS_TITLE_ENGLISH_FIELD.field}
                                name={constants.NEWS_TITLE_ENGLISH_FIELD.field}
                                label={constants.NEWS_TITLE_ENGLISH_FIELD.label}
                                value={formikNews.values[constants.NEWS_TITLE_ENGLISH_FIELD.field]}
                                onChange={formikNews.handleChange}
                                error={formikNews.touched[constants.NEWS_TITLE_ENGLISH_FIELD.field] && Boolean(formikNews.errors[constants.NEWS_TITLE_ENGLISH_FIELD.field])}
                                helperText={formikNews.touched[constants.NEWS_TITLE_ENGLISH_FIELD.field] && formikNews.errors[constants.NEWS_TITLE_ENGLISH_FIELD.field]}
                            />
                        </Grid>
                        <Grid item xs={12} md={5} sx={{ display: formatDisplay(values[constants.NEWS_TYPE_FIELD.field], 'title') }}>
                            <TextField
                                fullWidth
                                id={constants.NEWS_TITLE_SPANISH_FIELD.field}
                                name={constants.NEWS_TITLE_SPANISH_FIELD.field}
                                label={constants.NEWS_TITLE_SPANISH_FIELD.label}
                                value={formikNews.values[constants.NEWS_TITLE_SPANISH_FIELD.field]}
                                onChange={formikNews.handleChange}
                                error={formikNews.touched[constants.NEWS_TITLE_SPANISH_FIELD.field] && Boolean(formikNews.errors[constants.NEWS_TITLE_SPANISH_FIELD.field])}
                                helperText={formikNews.touched[constants.NEWS_TITLE_SPANISH_FIELD.field] && formikNews.errors[constants.NEWS_TITLE_SPANISH_FIELD.field]}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: formatDisplay(values[constants.NEWS_TYPE_FIELD.field], 'date') }}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                id={constants.NEWS_DATE_FIELD.field}
                                name={constants.NEWS_DATE_FIELD.field}
                                label={constants.NEWS_DATE_FIELD.label}
                                value={dayjs(formikNews.values[constants.NEWS_DATE_FIELD.field])}
                                onChange={(value) => handleDatePicker(constants.NEWS_DATE_FIELD.field, value)}
                                error={formikNews.touched[constants.NEWS_DATE_FIELD.field] && Boolean(formikNews.errors[constants.NEWS_DATE_FIELD.field])}
                                helperText={formikNews.touched[constants.NEWS_DATE_FIELD.field] && formikNews.errors[constants.NEWS_DATE_FIELD.field]}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: formatDisplay(values[constants.NEWS_TYPE_FIELD.field], 'description') }}>
                            <TextField
                                fullWidth
                                id={constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field}
                                name={constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field}
                                label={constants.NEWS_DESCRIPTION_ENGLISH_FIELD.label}
                                value={formikNews.values[constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field]}
                                onChange={formikNews.handleChange}
                                error={formikNews.touched[constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field] && Boolean(formikNews.errors[constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field])}
                                helperText={formikNews.touched[constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field] && formikNews.errors[constants.NEWS_DESCRIPTION_ENGLISH_FIELD.field]}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: formatDisplay(values[constants.NEWS_TYPE_FIELD.field], 'description') }}>
                            <TextField
                                fullWidth
                                id={constants.NEWS_DESCRIPTION_SPANISH_FIELD.field}
                                name={constants.NEWS_DESCRIPTION_SPANISH_FIELD.field}
                                label={constants.NEWS_DESCRIPTION_SPANISH_FIELD.label}
                                value={formikNews.values[constants.NEWS_DESCRIPTION_SPANISH_FIELD.field]}
                                onChange={formikNews.handleChange}
                                error={formikNews.touched[constants.NEWS_DESCRIPTION_SPANISH_FIELD.field] && Boolean(formikNews.errors[constants.NEWS_DESCRIPTION_SPANISH_FIELD.field])}
                                helperText={formikNews.touched[constants.NEWS_DESCRIPTION_SPANISH_FIELD.field] && formikNews.errors[constants.NEWS_DESCRIPTION_SPANISH_FIELD.field]}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sx={{ display: formikNews.values[constants.NEWS_TYPE_FIELD.field] === 'text' ? 'none' : formatDisplay(values[constants.NEWS_TYPE_FIELD.field], 'image') }}>
                            <UploadSingleFile
                                accept='image/*'
                                file={formikNews.values[constants.NEWS_IMAGE_FIELD.field]}
                                error={formikNews.touched[constants.NEWS_DESCRIPTION_FIELD.field] && Boolean(formikNews.errors[constants.NEWS_IMAGE_FIELD.field])}
                                helperText={
                                    formikNews.touched[constants.NEWS_DESCRIPTION_FIELD.field] && (
                                        <FormHelperText error sx={{ px: 2 }}>
                                            {values.onDrop ? 'El tipo de archivo seleccionado no es el requerido.' : formikNews.errors[constants.NEWS_IMAGE_FIELD.field]}
                                        </FormHelperText>
                                    )
                                }
                                onDropFormik={(value) => onDrop(value, 'image/')}
                            />
                        </Grid> */}
                    </Grid>
                </form>
            </Dialog>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    login: state.login,
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(News);