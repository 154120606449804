import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, SpeedDial } from '@mui/material';
import { MapsUgcTwoTone } from '@mui/icons-material';
import Tooltip from '../../../components/Tooltip';
import Dialog from '../../../components/Dialog';
import Stepper from './Stepper';
import { requestPost } from '../../../../utils/request';
import { formatMessage } from '../../../../utils/utils';
import { URL_API_INV } from '../../../../constants';

const defaultValues = {
    dialog: false,
    activeStep: 0,
    form: {
        validation: {
            value: '',
            error: '',
            data: ''
        },
        email: {
            value: '',
            error: ''
        },
        code: {
            value: '',
            error: ''
        },
        name: {
            value: '',
            error: ''
        },
        relation: {
            value: '',
            error: ''
        },
        campus: {
            value: '',
            error: ''
        },
        qg1: {
            label: '1. ¿Considera que la herramienta es útil para la visibilidad de las capacidades científicas de la UNAL?',
            value: '',
            error: '',
            options: [
                'Muy útil',
                'Útil',
                'Regular',
                'Poco útil',
                'Nada útil'
            ]
        },
        qg2: {
            label: '2. Por favor, justifique su respuesta sobre la visibilidad de capacidades científicas.',
            value: '',
            error: ''
        },
        qg3: {
            label: '3. ¿Considera pertinentes y relevantes los indicadores y métricas implementados actualmente en el Sistema?',
            value: '',
            error: '',
            options: [
                'Sí',
                'No'
            ]
        },
        qg4: {
            label: '4. ¿Qué otro indicador de investigación recomendaría usted incluir en la plataforma?',
            value: '',
            error: ''
        },
        qg5: {
            label: '5. Al realizar una búsqueda en el panel de búsqueda para temas o palabras clave sobre el área de su especialidad, ¿los resultados encontrados concuerdan con la temática?',
            value: '',
            error: '',
            options: [
                'Son relacionados',
                'Medianamente relacionados',
                'No guardan relación'
            ]
        },
        qg6: {
            label: '6. ¿Qué inconvenientes encontró al realizar la búsqueda, qué recomendaciones realizaría para mejorar la coincidencia en los resultados?',
            value: '',
            error: ''
        },
        qg7: {
            label: '7. ¿Considera que las cifras de los indicadores y métricas presentados por el Sistema son representativos de la realidad de la actividad de Investigación de la Universidad y sus Dependencias?',
            value: '',
            error: '',
            options: [
                'Sí',
                'No'
            ]
        },
        qg8: {
            label: '8. Por favor, justifique su respuesta sobre la representatividad de los datos del Sistema.',
            value: '',
            error: ''
        },
        qg9: {
            label: '9. Teniendo en cuenta su experiencia de navegación en HORUS, ¿cómo considera usted la navegación?',
            value: '',
            error: '',
            options: [
                'Muy fácil',
                'Fácil',
                'Regular',
                'Difícil',
                'Muy difícil'
            ]
        },
        qg10: {
            label: '10. Por favor, justifique su respuesta sobre la experiencia de navegación.',
            value: '',
            error: ''
        },
        qg11: {
            label: '11. ¿Considera que la disposición y visualización gráfica de los compontes del Sistema es correcta?',
            value: '',
            error: '',
            options: [
                'Sí',
                'No'
            ]
        },
        qg12: {
            label: '12. Por favor, justifique su respuesta sobre la disposición y visualización de componentes indicando si presentó problemas de visualización (si es el caso indique desde qué dispositivo y navegador visualiza la plataforma).',
            value: '',
            error: ''
        },
        qt1: {
            label: '1. Teniendo en cuenta las fuentes de información que se incluyen en HORUS (Scopus, Research Gate, Web of Science, PubMed, HERMES, SARA, Catálogo UN y Tesis de repositorio institucional), ¿considera que los indicadores reflejan la investigación que usted realiza?',
            value: '',
            error: '',
            options: [
                'Totalmente',
                'En su mayoría',
                'Poco',
                'Muy poco'
            ]
        },
        qt2: {
            label: '2. Por favor, justifique su respuesta anterior indicando si considera relevante incluir otra fuente de información.',
            value: '',
            error: ''
        },
        qt3: {
            label: '3. Teniendo en cuenta las fuentes que se incluyen en la plataforma, ¿Considera usted que su mapa de coautoría refleja sus redes de trabajo?',
            value: '',
            error: '',
            options: [
                'Totalmente',
                'En su mayoría',
                'Poco',
                'Muy poco'
            ]
        },
        qt4: {
            label: '4. Por favor, justifique su respuesta anterior indicando si existe alguna inconsistencia en su red de coautoría.',
            value: '',
            error: ''
        },
        qt5: {
            label: '5. Teniendo en cuenta las fuentes de información, ¿considera usted que los diferentes niveles temáticos la nube de palabras representa los temas en las cuales usted trabaja?',
            value: '',
            error: '',
            options: [
                'Totalmente',
                'En su mayoría',
                'Poco',
                'Muy poco'
            ]
        },
        qt6: {
            label: '6. Por favor, justifique su respuesta respecto de la representatividad de la nube de palabras.',
            value: '',
            error: ''
        },
        qt7: {
            label: '7. ¿La información presentada en su perfil requiere correcciones? Agradecemos indicar específicamente qué datos presentan inconsistencias así como las fuentes en las cuales se pueda realizar las correspondientes validadciones.',
            value: '',
            error: ''
        },
        qt8: {
            label: '8. Agradecemos realizar sus observaciones adicionales frente al sistema HORUS.',
            value: '',
            error: ''
        }
    }
};

function Feedback(props) {
    const { setSettings } = props;
    const [values, setValues] = useState(defaultValues);
    
    const handleDialog = (value) => {
        setValues({ ...defaultValues, dialog: value });
    };

    const handleBack = (value) => {
        setValues({ ...values, activeStep: value - 1 });
    };

    const handleNext = (value) => {
        let keys = [];
        let error = false;
        let keysError = values.form;
        const activeStep = value + 1;
        if (activeStep === 3) {
            if (values.form.email.value !== '') {
                if (values.form.validation.value !== '') {
                    if (values.form.validation.value === 'teacher') {
                        keys = ['code'];
                    } else {
                        keys = ['name', 'relation', 'campus'];
                    }
                } else {
                    keys = ['validation'];
                }
            } else {
                keys = ['email'];
            }
        } else if (activeStep === 4) {
            keys = ['qg1', 'qg2', 'qg3', 'qg4', 'qg5', 'qg6', 'qg7', 'qg8', 'qg9', 'qg10', 'qg11', 'qg12'];
        } else if (activeStep === 5) {
            keys = ['qt1', 'qt2', 'qt3', 'qt4', 'qt5', 'qt6', 'qt7', 'qt8'];
        }
        keys.forEach(key => {
            if (values.form[key].value === '') {
                keysError[key] = {
                    ...keysError[key],
                    error: 'El campo es requerido.'
                };
                error = true;
            }
        });
        if (error) {
            setValues({ ...values, form: keysError });
        } else {
            if (activeStep === 3 && values.form.validation.value === 'teacher') {
                setSettings({ backdrop: formatMessage(true, '', 'Validando el código de verificación.') });
                Promise.all([
                    requestPost(`${URL_API_INV}/feedback/code`, {
                        _id: values.form.validation.data,
                        code: values.form.code.value
                    })
                ])
                    .then(res => {
                        if (res[0].data.code) {
                            setValues({
                                ...values,
                                activeStep: activeStep
                            });
                        } else {
                            setSettings({
                                snackbar: formatMessage(true, 'error', 'Lo sentimos, el código que ingreso no es correcto. Por favor vuelva a intentar.')
                            });
                        }
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            } else if ((activeStep === 4 && values.form.validation.value === 'noTeacher') || (activeStep === 5)) {
                setSettings({ backdrop: formatMessage(true, '', 'Guardando la información del formulario.') });
                Promise.all([
                    requestPost(`${URL_API_INV}/feedback/form`, { form: values.form })
                ])
                    .then(res => {
                        if (res[0].data.form) {
                            setSettings({
                                snackbar: formatMessage(true, 'success', 'La información del formulario se guardo correctamente. Muchas gracias por tu aporte.')
                            });
                            setValues(defaultValues);
                        }
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            } else {
                setValues({
                    ...values,
                    activeStep: activeStep
                });
            }
        }
    };

    const handleValidation = (value) => {
        setSettings({ backdrop: formatMessage(true, '', 'Validando el correo electrónico.') });
        Promise.all([
            requestPost(`${URL_API_INV}/feedback/validation`, { email: value })
        ])
            .then(res => {
                if (res[0].data.validation === 'error') {
                    setSettings({
                        snackbar: formatMessage(true, 'error', 'Hubo un problema al enviar el código de verificación, por favor vuelva a intentar.')
                    });
                } else {
                    setValues({
                        ...values,
                        form: {
                            ...values.form,
                            validation: {
                                value: res[0].data.validation,
                                data: res[0].data.data,
                                error: ''
                            },
                        }
                    });
                }
                setSettings({ backdrop: formatMessage(false, '', '') });
            })
            .catch(err => {
                setSettings({
                    backdrop: formatMessage(false, '', ''),
                    snackbar: formatMessage(true, 'error', err),
                });
            });
    };

    const handleValues = (type, value) => {
        setValues({
            ...values,
            form: {
                ...values.form,
                validation: {
                    value: type === 'email' ? '' : values.form.validation.value,
                    data: type === 'email' ? '' : values.form.validation.data,
                    error: ''
                },
                [type]: {
                    ...values.form[type],
                    value: value,
                    error: ''
                }
            }
        });
    };

    return (
        <Box>
            <Tooltip title='Presione para abrir el formulario de retroalimentación'>
                <SpeedDial
                    ariaLabel='SpeedDialFeedback'
                    sx={{ position: 'fixed', bottom: 30, right: 30 }}
                    icon={<MapsUgcTwoTone />}
                    onClick={() => handleDialog(!values.dialog)}
                />
            </Tooltip>
            <Dialog
                _id={null}
                open={values.dialog}
                title='Formulario de retroalimentación'
                width='md'
                handleClose={() => handleDialog(!values.dialog)}
                handleBack={{
                    active: true,
                    label: 'Atrás',
                    function: () => handleBack(values.activeStep)
                }}
                handleNext={{
                    active: true,
                    label: 'Siguiente',
                    function: () => handleNext(values.activeStep)
                }}
            >
                <Stepper
                    activeStep={values.activeStep}
                    form={values.form}
                    handleValues={(type, value) => handleValues(type, value, values.form)}
                    handleValidation={(value) => handleValidation(value)}
                />
            </Dialog>
        </Box>
    );
}
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);