import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardHeader, Avatar, Typography, Stack, CardContent, Chip, Button } from '@mui/material';
import { SupervisedUserCircleTwoTone, ClosedCaptionOffTwoTone, FingerprintTwoTone, EventAvailableTwoTone } from '@mui/icons-material';
import Tooltip from '../../../../components/Tooltip';
import { requestPost } from '../../../../../utils/request';
import { formatDate, sortArrayValue, formatUpperCase, getParamsRoute, getParamsRouteString, getPositionGroup } from '../../../../../utils/utils';
import { COLORS_GROUP } from '../../../../../assets/theme/palette';
import { constants, URL_API_INV } from '../../../../../constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function GroupsAuthor(props) {
    const { author, params, investigation, setMetricsDefault, setInvestigation } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const groups = getPositionGroup(author, t);

    const handleRoute = (group) => {
        setMetricsDefault();
        delete params.resource;
        params.group = group[constants.GROUP_NAME_FIELD];
        params.resource = getParamsRouteString('calls');
        const route = getParamsRoute(params);
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        const hierarchy = {
            ...investigation.hierarchy,
            resource: 'Grupos de Investigación'
        };
        setInvestigation({ hierarchy: hierarchy });
        requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ m: 1 }}>
            <Stack direction={{ xs: 'column', sm: 'row', }} spacing={2}>
                {groups.length === 0 ? (
                    <Card sx={{ width: '100%' }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: theme => theme.palette.info.main }}>S</Avatar>
                            }
                            title={
                                <Typography variant='h6'>
                                    {t('dependence.investigation.researchGroups.noResearchGroups')}
                                </Typography>
                            }
                        />
                    </Card>
                ) : (
                    sortArrayValue(groups, constants.AUTHOR_POSITION_FIELD, t('components.graphics.Líder')).map((group, key) => {
                        return (
                            <Card
                                key={key}
                                sx={{
                                    mb: 2,
                                    width: '100%'
                                }}
                                elevation={4}
                            >
                                <CardHeader sx={{ mt: 1, ml: 2 }}
                                    avatar={
                                        <Tooltip title={t('dependence.investigation.researchGroups.category') + ': ' + group[constants.GROUP_COL_CATEGORY_FIELD]}>
                                            <Avatar sx={{ bgcolor: COLORS_GROUP[group[constants.GROUP_COL_CATEGORY_FIELD]] }}>
                                                {formatUpperCase(group[constants.GROUP_COL_CATEGORY_FIELD][0])}
                                            </Avatar>
                                        </Tooltip>
                                    }
                                    title={
                                        <Typography variant='h6'>
                                            {group[constants.GROUP_NAME_FIELD]}
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography variant='body1' color='text.secondary'>
                                            <EventAvailableTwoTone /> {formatDate(group[constants.GROUP_DATE_FIELD])}
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ ml: 2, pt: 0 }}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                        <Tooltip title={t('dependence.investigation.researchGroups.memberType')}>
                                            <Chip
                                                icon={<SupervisedUserCircleTwoTone />}
                                                label={
                                                    <Typography variant='body1' color='text.secondary'>
                                                        {group[constants.AUTHOR_POSITION_FIELD]}
                                                    </Typography>
                                                }
                                                variant='outlined'
                                            />
                                        </Tooltip>
                                        <Tooltip title={t('dependence.investigation.researchGroups.hermesId')}>
                                            <Chip
                                                icon={<FingerprintTwoTone />}
                                                label={
                                                    <Typography variant='body1' color='text.secondary'>
                                                        {group[constants.GROUP_ID_FIELD]}
                                                    </Typography>
                                                }
                                                variant='outlined'
                                            />
                                        </Tooltip>
                                        <Tooltip title={t('dependence.investigation.researchGroups.colcienciasCode')}>
                                            <Chip
                                                icon={<ClosedCaptionOffTwoTone />}
                                                label={
                                                    <Typography variant='body1' color='text.secondary'>
                                                        {group[constants.GROUP_COL_CODE_FIELD]}
                                                    </Typography>
                                                }
                                                variant='outlined'
                                            />
                                        </Tooltip>
                                    </Stack>
                                    <Button
                                        sx={{
                                            mt: 2,
                                            bgcolor: theme => theme.palette['green'].main,
                                            color: 'white',
                                            p: 1,
                                            pr: 2,
                                            pl: 2,
                                            '&:hover': {
                                                bgcolor: theme => theme.palette['green'].light,
                                            },
                                            '&:focus': {
                                                outline: 'transparent',
                                            },
                                        }}
                                        startIcon={<InfoOutlinedIcon />}
                                        onClick={() => handleRoute(group)}
                                    >
                                        <Typography variant='body1'>
                                            {t('dependence.investigation.researchGroups.information')}
                                        </Typography>
                                    </Button>
                                </CardContent>
                            </Card>
                        )
                    })
                )}
            </Stack>
        </Box>
    );
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT',
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupsAuthor);