import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, SpeedDial } from '@mui/material';
import { Cached } from '@mui/icons-material';
import CardGraphics from '../../../../../components/card/CardGraphics';
import BoxesTree from '../../../../../components/graphics/BoxesTree';
import PieDonut from '../../../../../components/graphics/PieDonut';
import BarGradient from '../../../../../components/graphics/BarGradient';
import Tooltip from '../../../../../components/Tooltip';
import { formatMessage, formatTFUsability } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { URL_API_INV } from '../../../../../../constants';

function ReportIndicator(props) {
    const { login, setLogin, setSettings } = props;
    const navigate = useNavigate();
    const handleOnChange = (value, state) => {
        setLogin({
            usability: {
                reportIndicator: {
                    ...login.usability.reportIndicator,
                    [state]: value,
                },
            }
        });
    }
    const handleRequest = () => {
        setSettings({ backdrop: formatMessage(true, '', 'Consultando información del reporte de indicadores.') });
        Promise.all([
            requestPost(`${URL_API_INV}/usability/report/indicator`, {}),
        ])
            .then(res => {
                if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                    setLogin({
                        usability: {
                            reportIndicator: {
                                tree: {
                                    ...login.usability.reportIndicator.tree,
                                    processed: formatTFUsability(res[0].data.infoUsability.tree)
                                },
                                dependence: {
                                    ...login.usability.reportIndicator.dependence,
                                    processed: res[0].data.infoUsability.dependence
                                },
                                hierarchy: {
                                    ...login.usability.reportIndicator.hierarchy,
                                    processed: res[0].data.infoUsability.hierarchy
                                },
                                hierarchyType: {
                                    ...login.usability.reportIndicator.hierarchyType,
                                    processed: res[0].data.infoUsability.hierarchyType
                                },
                                hierarchySearch: {
                                    ...login.usability.reportIndicator.hierarchySearch,
                                    processed: res[0].data.infoUsability.hierarchySearch
                                },
                                resource: {
                                    ...login.usability.reportIndicator.resource,
                                    processed: res[0].data.infoUsability.resource
                                },
                                subResource: {
                                    ...login.usability.reportIndicator.subResource,
                                    processed: res[0].data.infoUsability.subResource
                                },
                                action: {
                                    ...login.usability.reportIndicator.action,
                                    processed: res[0].data.infoUsability.action
                                },
                                actionType: {
                                    ...login.usability.reportIndicator.actionType,
                                    processed: res[0].data.infoUsability.actionType
                                }
                            },
                        }
                    });
                } else if (res[0].data.access) {
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                } else {
                    sessionStorage.removeItem('log-ide');
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                }
                setSettings({ backdrop: formatMessage(false, '', '') });
            })
            .catch(err => {
                setSettings({
                    snackbar: formatMessage(true, 'error', err),
                    backdrop: formatMessage(false, '', '')
                });
            });
    }
    useEffect(() => {
        handleRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={{ m: 2 }}>
            <Tooltip title='Presione para recargar la información de usabilidad.'>
                <SpeedDial
                    ariaLabel='requestUsability'
                    sx={{ position: 'relative', top: 10, right: 10 }}
                    direction='left'
                    icon={<Cached />}
                    onClick={() => handleRequest()}
                />
            </Tooltip>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <CardGraphics
                    title='Mapa de indicadores'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.tree,
                        onChange: (value) => handleOnChange(value, 'tree'),
                        columns: {
                            axis: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <BoxesTree
                        infoGraph={{
                            data: login.usability.reportIndicator.tree.processed,
                            name: 'Mapa de indicadores'
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Indicadores por dependencia'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.dependence,
                        onChange: (value) => handleOnChange(value, 'dependence'),
                        columns: {
                            type: 'Dependencia',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.usability.reportIndicator.dependence.processed,
                            colorGender: false,
                            formatValue: 'number',
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title='Indicadores por jerarquía'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.hierarchy,
                        onChange: (value) => handleOnChange(value, 'hierarchy'),
                        columns: {
                            type: 'Jerarquía',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.usability.reportIndicator.hierarchy.processed,
                            colorGender: false,
                            formatValue: 'number',
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Indicadores por tipo de jerarquía'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.hierarchyType,
                        onChange: (value) => handleOnChange(value, 'hierarchyType'),
                        columns: {
                            type: 'Tipo de jerarquía',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <BarGradient
                        infoGraph={{
                            data: login.usability.reportIndicator.hierarchyType.processed,
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Indicadores por tema de búsqueda'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.hierarchySearch,
                        onChange: (value) => handleOnChange(value, 'hierarchySearch'),
                        columns: {
                            type: 'Tipo de jerarquía',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <BarGradient
                        infoGraph={{
                            data: login.usability.reportIndicator.hierarchySearch.processed,
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title='Indicadores por recurso'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.resource,
                        onChange: (value) => handleOnChange(value, 'resource'),
                        columns: {
                            type: 'Recurso',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.usability.reportIndicator.resource.processed,
                            colorGender: false,
                            formatValue: 'number',
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Indicadores por sub recurso'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.subResource,
                        onChange: (value) => handleOnChange(value, 'subResource'),
                        columns: {
                            type: 'Sub recurso',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <BarGradient
                        infoGraph={{
                            data: login.usability.reportIndicator.subResource.processed,
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title='Indicadores por acción'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.action,
                        onChange: (value) => handleOnChange(value, 'action'),
                        columns: {
                            type: 'Acción',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.usability.reportIndicator.action.processed,
                            colorGender: false,
                            formatValue: 'number',
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Indicadores por tipo de acción'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportIndicator.actionType,
                        onChange: (value) => handleOnChange(value, 'actionType'),
                        columns: {
                            type: 'Tipo de acción',
                            value: 'Cantidad de visitas',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <BarGradient
                        infoGraph={{
                            data: login.usability.reportIndicator.actionType.processed,
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    />
                </CardGraphics>
            </Stack>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    login: state.login,
    metrics: state.metrics,
});
const mapDispatchToProps = (dispatch) => ({
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportIndicator);