import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Box } from '@mui/material';
import G6 from '@antv/g6';
import { configDiagramForce, configColor } from '../../../assets/theme/g2Theme';
import PersonRounded from '../../../assets/icons/PersonRounded.svg';
import PersonOutlineRounded from '../../../assets/icons/PersonOutlineRounded.svg';

function DiagramForce(props) {
    const { infoGraph } = props;
    const ref = useRef(null);
    let graph = null;
    function refreshDragedNodePosition(e) {
        const model = e.item.get('model');
        model.fx = e.x;
        model.fy = e.y;
        model.x = e.x;
        model.y = e.y;
    }
    useEffect(() => {
        if (!graph && infoGraph.data.nodes.length !== 0) {
            const container = ReactDOM.findDOMNode(ref.current);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            graph = new G6.Graph({
                ...configDiagramForce,
                container: container,
                width: container.scrollWidth,
            });
            graph.on('node:dragstart', function (e) {
                const forceLayout = graph.get('layoutController').layoutMethods[0];
                forceLayout.stop()
            });
            graph.on('node:drag', function (e) {
                refreshDragedNodePosition(e);
                graph.layout()
            });
            if (typeof window !== 'undefined') {
                window.onresize = () => {
                    if (!graph || graph.get('destroyed')) return;
                    if (!container || !container.scrollWidth || !container.scrollHeight) return;
                    graph.changeSize(container.scrollWidth, container.scrollHeight);
                };
            }
            const edges = infoGraph.data.edges;
            const nodes = infoGraph.data.nodes;
            const nodeMap = new Map();
            const clusterMap = new Map();
            let clusterId = 0;
            nodes.forEach(function (node) {
                nodeMap.set(node.id, node);
                if (node.cluster && clusterMap.get(node.cluster) === undefined) {
                    clusterMap.set(node.cluster, clusterId);
                    clusterId++;
                }
                const id = clusterMap.get(node.cluster);
                node.style = {
                    fill: configColor[id % configColor.length],
                };
                node.label = '       ' + node.name;
                node.icon = {
                    show: true,
                    img: node?.['is-in'] ? PersonRounded : PersonOutlineRounded,
                    width: 20,
                    height: 20,
                };
            });
            edges.forEach((edge) => {
                const source = nodeMap.get(edge.source);
                edge.color = source.style.fill;
                edge.size = 2;
            });
            graph.data(infoGraph.data);
            graph.render();
            return () => {
                graph.destroy();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoGraph.data]);
    return (
        <Box ref={ref}></Box>
    );
}
DiagramForce.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.object,
    })
};
export default DiagramForce;