import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CardPublicationsList from '../../../../../components/card/CardPublicationsList';
import { formatMessage } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { URL_API_INV } from '../../../../../../constants';

function Corrections(props) {
    const { login, investigation, setLogin, setSettings } = props;
    const navigate = useNavigate();
    const hierarchy = {
        ...investigation.hierarchy,
        resource: 'Lista de Productos eliminadas'
    };
    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', 'Consultando información de publicaciones eliminadas.') });
        Promise.all([
            requestPost(`${URL_API_INV}/curator/deleted`, { record: hierarchy })
        ])
            .then(res => {
                if (res[0].data.access && res[0].data.infoUser.dependence === 'Curaduria') {
                    setLogin({
                        curator: {
                            deleted: res[0].data.infoPublications
                        }
                    });
                } else if (res[0].data.access) {
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                } else {
                    sessionStorage.removeItem('log-ide');
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                }
                setSettings({ backdrop: formatMessage(false, '', '') });
            })
            .catch(err => {
                setSettings({
                    backdrop: formatMessage(false, '', ''),
                    snackbar: formatMessage(true, 'error', err),
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {login.curator.deleted.length > 0 && (
                <CardPublicationsList
                    data={login.curator.deleted}
                    params={{ teacher: 'Curaduria' }}
                    hierarchy={hierarchy}
                    options={{
                        deleted: true,
                        edit: true,
                        order: 'citations',
                        relevance: false,
                        author: []
                    }}
                />
            )}
        </Box>
    )
}
const mapStateToProps = (state) => ({
    login: state.login,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Corrections);