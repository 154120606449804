import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Box } from '@mui/material';
import SearchDependences from './SearchDependences';
import SearchTopics from './SearchTopics';
import { formatDependencesInv, formatDependencesExt } from '../../../utils/utils';

function Search(props) {
    const { hierarchy, params, value, dependence, extension, } = props;
    const [search, setSearch] = useState({
        isSearch: false,
        activeTab: 0,
        dataTree: []
    });
    
    const { t } = useTranslation();

    const handleChange = (event, value) => {
        setSearch({ ...search, activeTab: value });
    };

    useEffect(() => {
        const isSearch = 'query' in params;
        const dataTree = hierarchy === 'research'
            ? formatDependencesInv(dependence)
            : formatDependencesExt([extension.filters.dependence.default]);
        setSearch({
            isSearch: isSearch,
            activeTab: isSearch ? 1 : 0,
            dataTree: dataTree
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, dependence, extension]);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={search.activeTab}
                onChange={handleChange}
                sx={{
                    height: '25px',
                    minHeight: '25px'
                }}
            >
                <Tab
                    label={t('components.search.SearchDependences.name')}
                    sx={{
                        fontWeight: 600,
                        backgroundColor: 'white',
                        height: '10px',
                        minHeight: '10px',
                        '&:focus': {
                            outline: 'transparent',
                        }
                    }}
                />
                {hierarchy !== 'extension' && (
                    <Tab
                        label={t('components.search.SearchTopics.name')}
                        sx={{
                            fontWeight: 600,
                            backgroundColor: 'white',
                            height: '10px',
                            minHeight: '10px',
                            '&:focus': {
                                outline: 'transparent',
                            }
                        }}
                    />
                )}
            </Tabs>
            {search.activeTab === 0 ? (
                <SearchDependences
                    dataTree={search.dataTree}
                    hierarchy={hierarchy}
                    params={params}
                    value={search.isSearch ? '' : value}
                />
            ) : (
                <SearchTopics
                    dataTree={search.dataTree}
                    hierarchy={hierarchy}
                    params={params}
                    value={search.isSearch ? value : ''}
                />
            )}
        </Box>
    );
}
Search.propTypes = {
    hierarchy: PropTypes.string,
    params: PropTypes.object,
    value: PropTypes.string
};
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    extension: state.extension,
    settings: state.settings
});
export default connect(mapStateToProps)(Search);