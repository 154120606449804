import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Paper, Typography } from '@mui/material';
import Scrollbar from '../Scrollbar';
import { Line } from '@antv/g2plot';

function ListText(props) {
    const { infoGraph } = props;
    return (
        <Scrollbar sx={{ maxHeight: 350 }}>
            {infoGraph.data.map((item, key) => {
                return (
                    <Paper key={key} elevation={2} sx={{ m: 1, p: 1, bgcolor: '#E5E5E5' }}>
                        <Badge badgeContent={item.value} color='primary' sx={{ p: 0.5 }}>
                            <Typography variant='body0'>
                                {item.type}
                            </Typography>
                        </Badge>
                    </Paper>
                )
            })}
        </Scrollbar>
    );
}
Line.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.array
    }),
};
export default ListText;