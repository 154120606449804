import React from 'react';
import PropTypes from 'prop-types';
import { useMap, MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Badge, Box, Typography, styled } from '@mui/material';
import CardEmpty from '../card/CardEmpty';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -15,
        top: 9,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
function CenterView(props) {
    const { coords, handleDefault } = props;
    const map = useMap();
    map.setView(coords, 18);
    handleDefault();
    return null;
}
function LeafletMap(props) {
    const { data, center, handleClick, handleDefault } = props;
    const bounds = [data.map(item => [item.lat || 4.6367064, item.lon || -74.0894101])];
    return (
        <Box>
            {data.length === 0 ? (
                <CardEmpty />
            ) : (
                <MapContainer
                    className='markercluster-map'
                    zoom={4}
                    maxZoom={18}
                    bounds={bounds}
                >
                    <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <MarkerClusterGroup>
                        {data.map((item, key) => {
                            return (
                                <Marker
                                    key={key}
                                    position={[item.lat || 4.6367064, item.lon || -74.0894101]}
                                    eventHandlers={{
                                        click: (e) => handleClick(item.info.item),
                                    }}
                                >
                                    <Tooltip>
                                        <Typography variant='subtitle1'>
                                            {item.info.title}
                                        </Typography>
                                        <StyledBadge badgeContent={item.info.item.length} color='error'>
                                            <Typography variant='body2'>
                                                {item.info.description}
                                            </Typography>
                                        </StyledBadge>
                                    </Tooltip>
                                </Marker>
                            )
                        })}
                    </MarkerClusterGroup>
                    {center !== null && (
                        <CenterView
                            coords={center}
                            handleDefault={handleDefault}
                        />
                    )}
                </MapContainer>
            )}
        </Box>
    );
}
LeafletMap.propTypes = {
    data: PropTypes.array,
    center: PropTypes.array,
    handleClick: PropTypes.func,
    handleDefault: PropTypes.func
};
export default LeafletMap;