import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Container } from '@mui/material';
import Breadcrumbs from '../Breadcrumbs';
import GenAppbar from './GenAppbar';
import InvAppbar from './InvAppbar';
import ExtAppbar from './ExtAppbar';
import appbar from '../../../assets/images/horus/appbar.png';

function CustomAppBar(props) {
    const { hierarchy, value, params, children } = props;
    return (
        <Grid
            container
            justifyContent='center'
            sx={{
                backgroundImage: `url(${appbar})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}
        >
            {hierarchy === 'general' && (<GenAppbar hierarchy={hierarchy} params={params} value={value} />)}
            {hierarchy === 'research' && (<InvAppbar hierarchy={hierarchy} params={params} value={value} />)}
            {hierarchy === 'extension' && (<ExtAppbar hierarchy={hierarchy} params={params} value={value} />)}
            <Container
                maxWidth='xl'
                sx={{ mt: hierarchy === 'general' ? 1 : { xs: 22, sm: 22.5, md: 14 }, ml: 3, mr: 3 }}
            >
                {children}
                <Box sx={{ mt: 3, mb: 2, color: 'white' }}>
                    <Breadcrumbs hierarchy={hierarchy} params={params} />
                </Box>
            </Container>
        </Grid >
    );
}
CustomAppBar.propTypes = {
    hierarchy: PropTypes.string,
    params: PropTypes.object,
    value: PropTypes.string
};
export default CustomAppBar;