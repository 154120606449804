import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configBarGrouped } from '../../../assets/theme/g2Theme';

function BarGrouped(props) {
    const { infoGraph } = props;
    return (
        <ReactG2Plot
            Ctor={Bar}
            options={{
                ...{
                    data: infoGraph.data,
                    xAxis: { title: { text: infoGraph.axis.xAxis } },
                    yAxis: { title: { text: infoGraph.axis.yAxis } },
                },
                ...configBarGrouped,
            }}
        />
    );
}
BarGrouped.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.array,
        axis: PropTypes.object
    })
};
export default BarGrouped;
