import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Pie } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configPieHalfStacked, configColorGenderF, configColorGenderM } from '../../../assets/theme/g2Theme';

function PieHalfStacked(props) {
    const { infoGraph } = props;
    return (
        <Box>
            <ReactG2Plot
                Ctor={Pie}
                options={{
                    ...{
                        data: infoGraph.data.slice(0, 2),
                        color: infoGraph.colorGender ? configColorGenderM : null,
                    },
                    ...configPieHalfStacked
                }}
            />
            <ReactG2Plot
                Ctor={Pie}
                options={{
                    ...{
                        data: infoGraph.data.slice(2, 4),
                        color: infoGraph.colorGender ? configColorGenderF : null,
                    },
                    ...configPieHalfStacked
                }}
            />
        </Box>
    );
}
PieHalfStacked.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.array,
        colorGender: PropTypes.bool
    })
};
export default PieHalfStacked;
