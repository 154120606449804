import React, { useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';
import LDAvis from '../d3/LDAvis/LDAvis';
import styles from './styles.module.css';

const TopicsVisualization = (props) => {
    const { data, width, height } = props;
    const id = useRef(uniqueId('LDAvis-'));
    const vis = useRef(null);

    const generateVis = () => {
        if (Object.keys(data).length === 0) return;
        vis.current.innerHTML = '';
        new LDAvis('#' + id.current, data, width, height);
    };

    useEffect(() => {
        if (Object.keys(data).length === 0) return;
        generateVis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (Object.keys(data).length === 0) {
        return <div></div>;
    }

    return (
        <div
            ref={vis}
            id={id.current}
            className={styles.LDAvis}
            style={{ textAlign: 'center', overflowX: 'auto', width: '100%' }}
        ></div>
    );
};

TopicsVisualization.defaultProps = {
    name: '',
    width: '',
    height: ''
};
export default TopicsVisualization;