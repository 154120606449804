import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Badge, Button, Menu, MenuItem, TextField, Box, Chip, Typography } from '@mui/material';
import { ManageHistoryTwoTone, FilterAlt, ArrowDropDown } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Tooltip from '../Tooltip';
import { requestPost } from '../../../utils/request';
import { metricsInv } from '../../../utils/utils';
import { constants, URL_API_INV } from '../../../constants';

function InvRangeTime(props) {
    const { investigation, settings, setInvestigation } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const resource = investigation.hierarchy.resource;
    const rangeDefault = investigation.filters.rangeTime.default;
    const rangeApplied = investigation.filters.rangeTime.applied;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        const hierarchy = { ...investigation.hierarchy, resource: 'Filtro de tiempo' };
        requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
    };

    const handleFilter = (start, end) => {
        investigation.filters.rangeTime.type = true;
        investigation.filters.rangeTime.applied = [start, end];
        investigation.hierarchy.resource = 'General';
        setInvestigation(
            metricsInv(
                Object.keys(investigation.metrics.general),
                investigation.metrics.general.source,
                investigation.hierarchy,
                investigation,
                settings
            )
        );
        investigation.hierarchy.resource = resource;
        setInvestigation(
            metricsInv(
                Object.keys(investigation.metrics[constants.RESOURCES[resource]]),
                investigation.metrics[constants.RESOURCES[resource]].source,
                investigation.hierarchy,
                investigation,
                settings
            )
        );
        const hierarchy = {
            ...investigation.hierarchy,
            resource: 'Filtro de tiempo',
            subResource: 'Cambio en filtro de tiempo',
            action: { type: 'Rango de tiempo', value: ['Fecha inicio: ', start, ' Fecha fin: ', end].join('') }
        };
        requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
    };

    return (
        <Box
            sx={{
                justifyContent: 'flex-start',
                display: 'flex',
            }}
        >
            <Tooltip title={t('components.range.invRangeTime.tooltip')}>
                <Badge
                    color={((rangeDefault[0] !== rangeApplied[0]) || (rangeDefault[1] !== rangeApplied[1])) ? 'primary' : 'transparent'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    badgeContent={((rangeDefault[0] !== rangeApplied[0]) || (rangeDefault[1] !== rangeApplied[1])) && (
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 800
                            }}
                        >
                            {rangeApplied[0]} - {rangeApplied[1]}
                        </Typography>
                    )}
                >
                    <Button
                        variant='text'
                        startIcon={<ManageHistoryTwoTone />}
                        endIcon={<ArrowDropDown />}
                        onClick={handleClick}
                        sx={{ borderRadius: 1, '&:focus': { outline: 'transparent' } }}
                    >
                        {t('components.range.invRangeTime.filter')}
                    </Button>
                </Badge>
            </Tooltip>
            <Menu
                disableScrollLock
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ mt: 1, borderRadius: 1, zIndex: 1 }}
            >
                <MenuItem>
                    <DatePicker
                        views={['year']}
                        label={t('components.range.invRangeTime.startYear')}
                        value={dayjs().year(rangeApplied[0])}
                        minDate={dayjs().year(rangeDefault[0])}
                        maxDate={dayjs().year(rangeApplied[1])}
                        onChange={(value) => handleFilter(value.$y, rangeApplied[1])}
                        renderInput={(params) => (
                            <TextField size='small' {...params} />
                        )}
                    />
                </MenuItem>
                <MenuItem>
                    <DatePicker
                        views={['year']}
                        label={t('components.range.invRangeTime.endYear')}
                        value={dayjs().year(rangeApplied[1])}
                        minDate={dayjs().year(rangeApplied[0])}
                        maxDate={dayjs().year(rangeDefault[1])}
                        onChange={(value) => handleFilter(rangeApplied[0], value.$y)}
                        renderInput={(params) => (
                            <TextField size='small' {...params} />
                        )}
                    />
                </MenuItem>
                {
                    ((rangeDefault[0] !== rangeApplied[0]) || (rangeDefault[1] !== rangeApplied[1])) && (
                        <Tooltip title={t('components.range.invRangeTime.applied')} placement='bottom'>
                            <MenuItem>
                                <Chip
                                    sx={{ mx: 'auto', fontSize: 16 }}
                                    color='primary'
                                    variant='outlined'
                                    icon={<FilterAlt />}
                                    label={' [ ' + rangeApplied[0] + ' - ' + rangeApplied[1] + ' ] '}
                                    onDelete={() => handleFilter(rangeDefault[0], rangeDefault[1])}
                                />
                            </MenuItem>
                        </Tooltip>
                    )
                }
            </Menu>
        </Box >
    );
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(InvRangeTime);