import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DataGrid, esES, enUS, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}
function DataTable(props) {
    const { id, columns, rows, density, sortModel, pageSizeDefault, settings } = props;
    const [pageSize, setPageSize] = useState(pageSizeDefault ? pageSizeDefault : 10);

    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                autoHeight
                getRowId={(row) => row[id]}
                columns={columns}
                rows={rows}
                density={density}
                localeText={(settings.language === 'es' ? esES : enUS).components.MuiDataGrid.defaultProps.localeText}
                sx={{ fontSize: 14 }}
                slots={{ toolbar: CustomToolbar }}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pageSizeOptions={[10, 20, 30, 40, 50, 100]}
                initialState={{
                    sorting: { sortModel: sortModel, },
                    pagination: { paginationModel: { pageSize: pageSize } },
                }}
            />
        </Box>
    );
}
DataTable.propTypes = {
    id: PropTypes.string,
    columns: PropTypes.array,
    rows: PropTypes.array,
    density: PropTypes.string,
};
DataTable.defaultProps = {
    id: '',
    columns: [],
    rows: [],
    density: 'compact',
    sortModel: [],
    fileName: ''
};
const mapStateToProps = (state) => ({
    settings: state.settings
});
export default connect(mapStateToProps)(DataTable);