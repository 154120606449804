import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Box, Button, Typography, Stack } from '@mui/material';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Carousel(props) {
    const { functionalities, settings } = props;
    const [activeStep, setActiveStep] = useState(0);
    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const handleDotClick = (index) => {
        setActiveStep(index);
    };
    return (
        <Box sx={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
            <AutoPlaySwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                interval={10000}
                enableMouseEvents
            >
                {functionalities.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => (
                    <Box key={index} sx={{height:'100%' , justifyContent:'center' , alignItems:'center' , display:'flex'}}>
                        <Stack>
                            <Typography
                                variant='h5'
                                color={theme => theme.palette['green'].main}
                                sx={{ lineHeight: '0.8', pt: 1, pb: 0.5 }}
                                dangerouslySetInnerHTML={{ __html: item['title_' + settings.language] }}
                            />
                            <Typography
                                variant='body0'
                                color='black'
                                sx={{
                                    display: 'block',
                                    marginTop: '5px',
                                    lineHeight: '1.1',
                                }}
                                dangerouslySetInnerHTML={{ __html: item['description_' + settings.language] }}
                            />
                        </Stack>
                    </Box>
                ))}
            </AutoPlaySwipeableViews>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {functionalities.map((_, index) => (
                    <Button
                        key={index}
                        sx={{
                            minHeight: 6,
                            minWidth: 6,
                            maxHeight: 6,
                            maxWidth: 6,
                            borderRadius: '100%',
                            bgcolor: index === activeStep ? '#555555' : 'grey.400',
                            m: 0.3,
                            p: 0,
                            '&:focus': {
                                outline: 'transparent',
                            },
                            '&:hover': {
                                bgcolor: theme => theme.palette['green'].main,
                            },
                        }}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </Box>
        </Box>
    );
};
Carousel.propTypes = {
    functionalities: PropTypes.array
};
const mapStateToProps = (state) => ({
    settings: state.settings
});
export default connect(mapStateToProps)(Carousel);