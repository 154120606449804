import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack } from '@mui/material';
import { MonitorOutlined, EmojiPeopleOutlined, InfoOutlined, StarOutlineOutlined } from '@mui/icons-material';
import AppBar from '../../../components/appbar/Appbar';
import Widgets from '../../../components/widgets/Widgets';
import ExtRangeTime from '../../../components/range/ExtRangeTime';
import ExtIntervalTime from '../../../components/range/ExtIntervalTime';
import GeneralInformation from './panels/GeneralInformation';
import Projects from './panels/Projects';
import Opportunities from './panels/Opportunities';
import Activities from './panels/Activities';
import { requestPost } from '../../../../utils/request';
import { formatMessage, getStepResource } from '../../../../utils/utils';
import { URL_API_EXT, URL_API_INV } from '../../../../constants';

function Search(props) {
    const { extension, setExtension, setSettings } = props;

    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const widgets = [
        {
            title: t('dependence.extension.generalInformation'),
            color: 'green',
            filter: false,
            icon: <InfoOutlined />,
            step: 'generalInformation',
        },
        {
            title: t('dependence.extension.projects'),
            color: 'gray',
            filter: false,
            icon: <MonitorOutlined />,
            step: 'projects'
        },
        {
            title: t('dependence.extension.opportunities'),
            color: 'cyan',
            filter: false,
            icon: <EmojiPeopleOutlined />,
            step: 'opportunities'
        },
        {
            title: t('dependence.extension.activities'),
            color: 'salmon',
            filter: false,
            icon: <StarOutlineOutlined />,
            step: 'activities'
        },
    ];

    const handleRoute = (resource) => {
        const route = `/search/extension/query/${params.query}/resource/${resource}`;
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        getStepResource(params, widgets, navigate, location);
        setSettings({ backdrop: formatMessage(true, '', t('components.search.SearchTopics.consultingInfoSearch')) });
        let gate = true;
        if (location.state) {
            gate = location.state.gate;
        }
        if (extension.hierarchy.stepHome) {
            const keyDep = location.state?.key === 'hierarchy' ? false : true;
            window.history.replaceState({}, document.title);
            const hierarchy = {
                ...extension.hierarchy,
                hierarchy: {
                    ...extension.hierarchy.hierarchy,
                    dependence: [keyDep, 'Extensión'],
                    hierarchy: ['Búsqueda por tema', params.query]
                },
                params: params,
                resource: 'General',
                stepKey: true
            };
            Promise.all([
                requestPost(`${URL_API_EXT}/extension/metrics-search`, {
                    gate: gate,
                    value: params.query
                }),
                requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy })
            ])
                .then(res => {
                    setExtension({
                        hierarchy: hierarchy,
                        search: { search: res[0].data.search }
                    });
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, extension.hierarchy.stepHome]);
    return (
        <Box sx={{ bgcolor: 'white' }}>
            <AppBar hierarchy='extension' params={params} value={params.query}>
                <Widgets
                    isAuthor={false}
                    widgets={widgets}
                    stepTab={params.resource}
                    handleStepTab={(resource) => handleRoute(resource)}
                />
            </AppBar>
            <Container
                component='main'
                maxWidth='xl'
            >
                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    sx={{ mt: 1 }}
                >
                    <ExtRangeTime />
                    <ExtIntervalTime />
                </Stack>
                <Stack direction={{ md: 'column' }} sx={{ mt: 1, pb: 3 }} spacing={3}>
                    <Paper elevation={4} sx={{ bgcolor: '#DDDDDD', boxShadow: 0 }}>
                        <TabContext value={params.resource}>
                            <TabPanel value='generalInformation'>
                                <GeneralInformation />
                            </TabPanel>
                            <TabPanel value='projects'>
                                <Projects />
                            </TabPanel>
                            <TabPanel value='opportunities'>
                                <Opportunities />
                            </TabPanel>
                            <TabPanel value='activities'>
                                <Activities />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Stack>
            </Container>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    extension: state.extension
});
const mapDispatchToProps = (dispatch) => ({
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Search);