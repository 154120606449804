import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TabPanel from '../../../../../components/TabPanel';
import ReportUser from './ReportUser';
import ReportIndicator from './ReportIndicator';
import ReportTree from './ReportTree';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
export default function Usability() {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box >
            <Tabs
                centered
                value={value}
                onChange={handleChange}
            >
                <Tab label='Usuarios' {...a11yProps(0)} />
                <Tab label='Indicadores' {...a11yProps(1)} />
                <Tab label='Trazabilidad' {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ReportUser />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ReportIndicator />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ReportTree />
            </TabPanel>
        </Box>
    );
}