import React from 'react';
import PropTypes from 'prop-types';
import { Chord } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configDiagramChord } from '../../../assets/theme/g2Theme';

function DiagramChord(props) {
    const { infoGraph } = props;
    return (
        <ReactG2Plot
            Ctor={Chord}
            options={{
                ...{
                    data: infoGraph.data.edges
                },
                ...configDiagramChord
            }}
        />
    );
}
DiagramChord.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.object
    })
};
export default DiagramChord;