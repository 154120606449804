import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CardPublicationsList from '../../../../../components/card/CardPublicationsList';
import { formatMessage } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { URL_API_INV } from '../../../../../../constants';

function Publications(props) {
    const { investigation, login, setInvestigation, setLogin, setSettings } = props;

    const navigate = useNavigate();
    const hierarchy = {
        ...investigation.hierarchy,
        resource: 'Lista de Productos'
    };

    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de publicaciones. Por favor, tenga paciencia. La consulta de todas las publicaciones puede tardar aproximadamente un minuto.') });
            Promise.all([
                requestPost(`${URL_API_INV}/curator/publications`, { record: hierarchy })
            ])
                .then(res => {
                    if (res[0].data.access && res[0].data.infoUser.dependence === 'Curaduria') {
                        setLogin({
                            admin: {
                                infoUser: res[0].data.infoUser,
                            },
                            curator: {
                                publications: res[0].data.infoPublications
                            }
                        });
                        setInvestigation({ hierarchy: hierarchy });
                    } else if (res[0].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);

    return (
        <Box>
            {login.curator.publications.length > 0 && (
                <CardPublicationsList
                    data={login.curator.publications}
                    params={{ author: 'Curaduria' }}
                    hierarchy={hierarchy}
                    options={{
                        deleted: false,
                        edit: true,
                        order: 'citations',
                        relevance: false,
                        author: []
                    }}
                />
            )}
        </Box>
    )
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    login: state.login
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Publications);