import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Box } from '@mui/material';
import G6 from '@antv/g6';
import { configDiagramArc, configColor } from '../../../assets/theme/g2Theme';
import PersonRounded from '../../../assets/icons/PersonRounded.svg';
import PersonOutlineRounded from '../../../assets/icons/PersonOutlineRounded.svg';

function DiagramArc(props) {
    const { infoGraph } = props;
    const ref = useRef(null);
    let graph = null;
    useEffect(() => {
        if (!graph && infoGraph.data.nodes.length !== 0) {
            const container = ReactDOM.findDOMNode(ref.current);
            const width = container.scrollWidth;
            const height = configDiagramArc.height;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            graph = new G6.Graph({
                container: container,
                ...configDiagramArc
            });
            graph.on('edge:mouseenter', function (e) {
                const edge = e.item;
                graph.setItemState(edge, 'hover', true);
            });
            graph.on('edge:mouseleave', function (e) {
                const edge = e.item;
                graph.setItemState(edge, 'hover', false);
            });
            if (typeof window !== 'undefined') {
                window.onresize = () => {
                    if (!graph || graph.get('destroyed')) return;
                    if (!container || !container.scrollWidth || !container.scrollHeight) return;
                    graph.changeSize(container.scrollWidth, container.scrollHeight);
                };
            }
            const origin = [width / 2, height / 2];
            const radius = width < height ? width / 3 : height / 3;
            const edges = infoGraph.data.edges;
            const nodes = infoGraph.data.nodes;
            const nodeMap = new Map();
            const clusterMap = new Map();
            let clusterId = 0;
            const n = nodes.length;
            const angleSep = (Math.PI * 2) / n;
            nodes.forEach(function (node, i) {
                const angle = i * angleSep;
                node.x = radius * Math.cos(angle) + origin[0];
                node.y = radius * Math.sin(angle) + origin[1];
                node.angle = angle;
                nodeMap.set(node.id, node);
                if (node.cluster && clusterMap.get(node.cluster) === undefined) {
                    clusterMap.set(node.cluster, clusterId);
                    clusterId++;
                }
                const id = clusterMap.get(node.cluster);
                node.style = {
                    fill: configColor[id % configColor.length],
                };
                node.label = '       ' + node.name;
                node.labelCfg = { style: { rotate: angle } };
                node.icon = {
                    show: true,
                    img: node?.['is-in'] ? PersonRounded : PersonOutlineRounded,
                    width: 20,
                    height: 20,
                };
            });
            edges.forEach((edge) => {
                edge.type = 'quadratic';
                const source = nodeMap.get(edge.source);
                edge.controlPoints = [{
                    x: origin[0],
                    y: origin[1],
                }];
                edge.color = source.style.fill;
            });
            graph.data(infoGraph.data);
            graph.render();
            return () => {
                graph.destroy();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoGraph.data]);
    return (
        <Box ref={ref}></Box>
    );
}
DiagramArc.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.object,
    })
};
export default DiagramArc;