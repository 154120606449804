import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import { metricsExt } from '../../../utils/utils';
import { requestPost } from '../../../utils/request';
import { constants, URL_API_INV } from '../../../constants';

function ExtIntervalTime(props) {
    const { extension, settings, setExtension } = props;
    const [menu, setMenu] = useState(null);
    const { t } = useTranslation();

    const resource = extension.hierarchy.resource;
    const intervalDefault = extension.filters.intervalTime.default;
    const intervalApplied = extension.filters.intervalTime.applied;

    const handleClick = (event) => {
        setMenu(event.currentTarget);
    };
    const handleClose = () => {
        setMenu(null);
    };
    const handleFilter = (value) => {
        extension.filters.rangeTime.type = true;
        extension.filters.intervalTime.applied = value;
        setExtension(
            metricsExt(
                Object.keys(extension.metrics[constants.RESOURCES[resource]]),
                extension.metrics[constants.RESOURCES[resource]].source,
                extension.hierarchy,
                extension
            )
        );
        const hierarchy = {
            ...extension.hierarchy,
            resource: 'Filtro de intervalo de tiempo',
            subResource: 'Cambio en filtro de intervalo de tiempo',
            action: { type: 'Intervalo de tiempo', value: value.label_es }
        };
        requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
    };
    return (
        <Box sx={{ ml: 1 }}>
            <Tooltip title={t('components.range.extIntervalTime.tooltip')}>
                <Button
                    variant='text'
                    endIcon={<KeyboardArrowDown />}
                    onClick={handleClick}
                    sx={{ borderRadius: 1, '&:focus': { outline: 'transparent' } }}
                >
                    {t('components.range.extIntervalTime.name')}
                </Button>
            </Tooltip>
            <Menu
                disableScrollLock
                anchorEl={menu}
                open={Boolean(menu)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ mt: 1, borderRadius: 1, zIndex: 1 }}
            >
                {intervalDefault.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => handleFilter(item)}
                            selected={item.value[0] === intervalApplied.value[0]}
                        >
                            {item['label_' + settings.language]}
                        </MenuItem>
                    )
                })}
            </Menu>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    extension: state.extension,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ExtIntervalTime);