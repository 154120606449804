import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, DialogContentText, Paper, Alert } from '@mui/material';
import Timeline from '../../../../../components/timeline/Timeline';
import Dialog from '../../../../../components/Dialog';
import { formatTLCurator } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../../constants';

function Resume(props) {
    const { isDeleted, login, investigation } = props;
    const [dialog, setDialog] = useState({
        open: false,
        value: {}
    });

    const handleClose = () => {
        setDialog({ open: false, value: {} });
    };

    const handleClick = (open, value) => {
        if (Object.keys(value).length !== 0) {
            setDialog({
                open: open,
                value: value
            });
        }
    };

    useEffect(() => {
        if (investigation.hierarchy.stepSubKey) {
            const hierarchy = {
                ...investigation.hierarchy,
                subResource: 'Resumen de correcciones',
                action: {
                    type: 'Lista de correcciones',
                    value: 'Visualización de lista de correcciones',
                }
            };
            requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepSubKey]);

    return (
        <Box>
            <Alert icon={false} severity='info' sx={{ fontSize: 16, fontWeight: 600, mb: 2 }}>
                {login.curator.infoPublication[isDeleted ? 'publicationBackup' : 'publication']?.[constants.PUBLICATION_TITLE_FIELD]}
            </Alert>
            <Timeline
                data={formatTLCurator(login.curator.infoPublication, isDeleted ? 'publicationBackup' : 'publication')}
                handleClick={(value) => handleClick(true, value)}
            />
            <Dialog
                _id='viewer'
                open={dialog.open}
                title='Información de cambios'
                width='sm'
                handleClose={handleClose}
                handleBack={{
                    active: false,
                    label: '',
                    function: null
                }}
                handleNext={{
                    active: false,
                    label: '',
                    function: null
                }}
            >
                {Object.keys(dialog.value.report || []).length !== 0 && (
                    <Box>
                        <DialogContentText>
                            Reportes:
                        </DialogContentText>
                        <Paper
                            elevation={6}
                            sx={{
                                mt: 3, mb: 3, p: 3,
                                bgcolor: 'rgb(0, 30, 60)',
                                whiteSpace: 'pre-wrap',
                                position: 'relative',
                                color: 'white',
                            }}
                        >
                            {JSON.stringify(dialog.value.report, null, '\t')}
                        </Paper>
                    </Box>
                )}
                {Object.keys(dialog.value.changes || []).length !== 0 && (
                    <Box>
                        <DialogContentText>
                            Cambios:
                        </DialogContentText>
                        <Paper
                            elevation={6}
                            sx={{
                                mt: 3, mb: 3, p: 3,
                                bgcolor: 'rgb(0, 30, 60)',
                                whiteSpace: 'pre-wrap',
                                position: 'relative',
                                color: 'white',
                            }}
                        >
                            {JSON.stringify(dialog.value.changes, null, '\t')}
                        </Paper>
                    </Box>
                )}
            </Dialog>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    login: state.login,
    investigation: state.investigation,
});
export default connect(mapStateToProps)(Resume);