import 'simplebar/dist/simplebar.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import i18n from 'i18next';
import { Provider } from 'react-redux';
import Backend from 'i18next-fs-backend';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import typography from './assets/theme/typography';
import palette from './assets/theme/palette';
import store from './redux/store';
import Router from './router/Router';
import { constants } from './constants.js';
import './assets/css/main.css';
import './assets/js/unal.js';
import './assets/js/accessibility.js';
import './assets/theme/g2Theme.js';

const theme = createTheme({ typography, palette: palette.light });
i18n.use(Backend).init({
    'fallbackLng': 'es',
    'debug': false,
    'resources': {...constants.TRANSLATIONS},
    'interpolation': {
        'escapeValue': false
    }
});

const App = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={theme}>
                        <Router />
                    </ThemeProvider>
                </LocalizationProvider>
            </I18nextProvider>
        </Provider>
    );
};
createRoot(document.getElementById('root')).render(<App />);