import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack } from '@mui/material';
import { ListAltTwoTone, DescriptionTwoTone } from '@mui/icons-material';
import AppBar from '../../../../components/appbar/Appbar';
import Drawer from '../../../../components/Drawer';
import Navigation from '../../../../components/Navigation';
import Resume from './panels/Resume';
import Form from './panels/Form';
import { formatMessage } from '../../../../../utils/utils';
import { requestPost } from '../../../../../utils/request';
import { URL_API_INV } from '../../../../../constants';

function Publication(props) {
    const { login, settings, investigation, setLogin, setSettings, setInvestigation } = props;

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isNew = params.add ? true : false;
    const isDeleted = params.deleted ? true : false;

    const [stepTab, setStepTab] = useState(isNew ? 1 : 0);

    const optionsNavigation = [
        {
            label: 'Resumen',
            icon: <DescriptionTwoTone />,
            display: !isNew
        },
        {
            label: 'Formulario de publicación',
            icon: <ListAltTwoTone />,
            display: !isDeleted
        }
    ];

    useEffect(() => {
        if (investigation.hierarchy.stepHome) {
            const keyDep = location.state?.key === 'hierarchy' ? false : true;
            window.history.replaceState({}, document.title);
            const hierarchy = {
                ...investigation.hierarchy,
                hierarchy: {
                    ...investigation.hierarchy.hierarchy,
                    dependence: [keyDep, 'Autenticación'],
                    hierarchy: ['Inicio de sesión', 'Curaduria'],
                },
                resource: isNew ? 'Nueva publicación' : isDeleted ? 'Visualizar publicación' : 'Corrección de publicación',
                params: params,
                stepSubKey: true
            };
            setInvestigation({ hierarchy: hierarchy });
            requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
            if (!isNew) {
                setSettings({ backdrop: formatMessage(true, '', 'Consultando información de la publicación.') });
                Promise.all([
                    requestPost(`${URL_API_INV}/curator/publication`, {
                        _id: params.publication,
                        record: hierarchy,
                        deleted: isDeleted
                    })
                ])
                    .then(res => {
                        if (res[0].data.access && res[0].data.infoUser.dependence === 'Curaduria') {
                            setLogin({
                                admin: { infoUser: res[0].data.infoUser },
                                curator: { infoPublication: res[0].data.infoPublication }
                            });
                        } else if (res[0].data.access) {
                            navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                        } else {
                            sessionStorage.removeItem('log-ide');
                            navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                        }
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            } else {
                Promise.all([
                    requestPost(`${URL_API_INV}/auth/login`, { record: 'none' }),
                ])
                    .then(res => {
                        if (res[0].data.access && res[0].data.infoUser.dependence === 'Curaduria') {
                            setLogin({
                                admin: { infoUser: res[0].data.infoUser }
                            });
                        } else if (res[0].data.access) {
                            navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                        } else {
                            sessionStorage.removeItem('log-ide');
                            navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                        }
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepHome]);

    return (
        <Box>
            <Drawer view='login' data={login.admin.infoUser} />
            <Box
                component='main'
                sx={{
                    width: { sm: `calc(100% - ${settings.openDrawer ? settings.widthDrawer : 0}px)` },
                    ml: { sm: `${settings.openDrawer ? settings.widthDrawer : 0}px` }
                }}
            >
                <AppBar
                    hierarchy='general'
                    params={params}
                    value={
                        isNew ? params.add : isDeleted ? params.deleted : 'Corrección de publicación'
                    }
                />
                <Container sx={{ mt: 3, mb: 3 }}>
                    <Stack direction='column' sx={{ m: 3 }} spacing={3}>
                        <Paper elevation={4}>
                            <TabContext value={stepTab.toString()}>
                                <Navigation
                                    options={optionsNavigation}
                                    value={stepTab}
                                    handleStep={(newValue) => setStepTab(newValue)}
                                />
                                <TabPanel value='0'>
                                    <Resume isDeleted={isDeleted} />
                                </TabPanel>
                                <TabPanel value='1'>
                                    <Form isEdit={isNew ? false : isDeleted ? false : true} />
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Stack>
                </Container>
            </Box>
        </Box >
    );
};
const mapStateToProps = (state) => ({
    login: state.login,
    settings: state.settings,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Publication);