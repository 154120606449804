import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ExcelJS from 'exceljs';
import { Box, Typography, IconButton, SpeedDial, Stack } from '@mui/material';
import { CloudDownloadTwoTone } from '@mui/icons-material';
import DataTable from '../../../../../components/DataTable';
import Tooltip from '../../../../../components/Tooltip';
import CardGraphics from '../../../../../components/card/CardGraphics';
import PieDonut from '../../../../../components/graphics/PieDonut';
import ListText from '../../../../../components/graphics/ListText';
import { formatMessage, formatDate, formatFeedbackDownload, formatFeedback } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { URL_API_INV, constants } from '../../../../../../constants';

function Feedback(props) {
    const { login, investigation, setSettings, setLogin } = props;
    const defaultHierarchy = investigation.hierarchy;
    const navigate = useNavigate();
    const handleOnChange = (value, state) => {
        setLogin({
            feedback: {
                ...login.feedback,
                [state]: {
                    ...login.feedback[state],
                    ...value,
                },
            }
        });
    };
    const handleDownload = (values) => {
        const value = formatFeedbackDownload(values);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Retroalimentación');
        worksheet.columns = value.headers;
        worksheet.addRows(value.data);
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Feedback.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };
    const columnsUsers = [
        {
            field: constants.FEEDBACK_DATE_FIELD, headerName: 'Fecha', flex: 1,
            renderCell: (params) => formatDate(params.row[constants.FEEDBACK_DATE_FIELD])
        },
        {
            field: 'name', headerName: 'Nombre', flex: 1,
            renderCell: (params) => params.row[constants.FEEDBACK_USER_FIELD]
                ? params.row[constants.FEEDBACK_USER_INFO_FIELD][constants.AUTHOR_NAME_FIELD]
                : params.row[constants.FEEDBACK_USER_NO_FIELD][constants.FEEDBACK_USER_NO_NAME_FIELD]
        },
        {
            field: 'email', headerName: 'Correo electrónico', flex: 1,
            renderCell: (params) => params.row[constants.FEEDBACK_USER_FIELD]
                ? params.row[constants.FEEDBACK_USER_INFO_FIELD][constants.AUTHOR_EMAIL_FIELD]
                : params.row[constants.FEEDBACK_USER_NO_FIELD][constants.FEEDBACK_USER_NO_EMAIL_FIELD]
        },
        {
            field: 'position', headerName: 'Relación', flex: 1,
            renderCell: (params) => params.row[constants.FEEDBACK_USER_FIELD]
                ? params.row[constants.FEEDBACK_USER_INFO_FIELD][constants.AUTHOR_POSITION_FIELD]
                : params.row[constants.FEEDBACK_USER_NO_FIELD][constants.FEEDBACK_USER_NO_POSITION_FIELD]
        },
        {
            field: '_id', headerName: 'Opciones', flex: 1, align: 'center',
            renderCell: (params) => (
                <Tooltip title='Descargar reporte'>
                    <IconButton aria-label='Descargar reporte' onClick={() => handleDownload([params.row])}>
                        <CloudDownloadTwoTone />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];
    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de retroalimentación.') });
            const hierarchy = {
                ...defaultHierarchy,
                resource: 'Retroalimentación'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/feedback/feedback`, { record: hierarchy }),
            ])
                .then(res => {
                    if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                        setLogin({ feedback: formatFeedback(res[0].data.feedback, login.feedback) });
                    } else if (res[0].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);
    return (
        <Box>
            <Tooltip title='Presione para descargar el reporte completo de retroalimentación.'>
                <SpeedDial
                    ariaLabel='downloadReport'
                    sx={{ position: 'relative', top: 10, right: 10 }}
                    direction='left'
                    icon={<CloudDownloadTwoTone />}
                    onClick={() => handleDownload(login.feedback.source)}
                />
            </Tooltip>
            <DataTable
                id='_id'
                rows={login.feedback.source}
                columns={columnsUsers}
                sortModel={[{ field: constants.FEEDBACK_DATE_FIELD, sort: 'desc' }]}
                fileName='Retroalimentación'
            />
            <Typography
                variant='h4'
                sx={{
                    textAlign: 'center',
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 3, mt: 4
                }}
            >
                Satisfacción general sobre el Sistema
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qg1.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg1,
                        onChange: (value) => handleOnChange(value, 'qg1'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qg1.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qg2.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg2,
                        onChange: (value) => handleOnChange(value, 'qg2'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qg2.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qg3.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg3,
                        onChange: (value) => handleOnChange(value, 'qg3'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qg3.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qg4.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg4,
                        onChange: (value) => handleOnChange(value, 'qg4'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qg4.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qg5.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg5,
                        onChange: (value) => handleOnChange(value, 'qg5'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qg5.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qg6.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg6,
                        onChange: (value) => handleOnChange(value, 'qg6'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qg6.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qg7.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg7,
                        onChange: (value) => handleOnChange(value, 'qg7'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qg7.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qg8.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg8,
                        onChange: (value) => handleOnChange(value, 'qg8'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qg8.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qg9.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg9,
                        onChange: (value) => handleOnChange(value, 'qg9'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qg9.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qg10.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg10,
                        onChange: (value) => handleOnChange(value, 'qg10'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qg10.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qg11.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg11,
                        onChange: (value) => handleOnChange(value, 'qg11'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qg11.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qg12.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qg12,
                        onChange: (value) => handleOnChange(value, 'qg12'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qg12.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Typography
                variant='h4'
                sx={{
                    textAlign: 'center',
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 3, mt: 4
                }}
            >
                Validación de la información del Perfil Docente
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qt1.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt1,
                        onChange: (value) => handleOnChange(value, 'qt1'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qt1.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qt2.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt2,
                        onChange: (value) => handleOnChange(value, 'qt2'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qt2.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qt3.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt3,
                        onChange: (value) => handleOnChange(value, 'qt3'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qt3.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qt4.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt4,
                        onChange: (value) => handleOnChange(value, 'qt4'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qt4.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qt5.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt5,
                        onChange: (value) => handleOnChange(value, 'qt5'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qt5.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qt6.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt6,
                        onChange: (value) => handleOnChange(value, 'qt6'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qt6.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={login.feedback.qt7.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt7,
                        onChange: (value) => handleOnChange(value, 'qt7'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <PieDonut
                        infoGraph={{
                            data: login.feedback.qt7.processed,
                        }}
                    />
                </CardGraphics>
                <CardGraphics
                    title={login.feedback.qt8.label}
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: true,
                        notice: false,
                        dialog: false,
                        dataTable: true,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.feedback.qt8,
                        onChange: (value) => handleOnChange(value, 'qt8'),
                        columns: {
                            type: 'Respuesta',
                            value: 'Cantidad',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <ListText
                        infoGraph={{
                            data: login.feedback.qt8.processed,
                        }}
                    />
                </CardGraphics>
            </Stack>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    login: state.login,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);