import React from 'react';

const MathText = ({ text }) => {
    const parsedText = text
        .replace(/<inf>(.*?)<\/inf>/g, (match, p1) => `<sub>${p1}</sub>`)
        .replace(/<inf>(.*?)<\/fif>/g, (match, p1) => `<sub>${p1}</sub>`)
        .replace(/<inf>(.*?)<\/ff>/g, (match, p1) => `<sub>${p1}</sub>`)
        .replace(/<LIF>(.*?)<\/IFT>/g, (match, p1) => `<sub>${p1}</sub>`)
        .replace(/<lif>(.*?)<\/ift>/g, (match, p1) => `<sub>${p1}</sub>`)
        .replace(/<up>(.*?)<\/up>/g, (match, p1) => `<sup>${p1}</sup>`);
    return <span dangerouslySetInnerHTML={{ __html: parsedText }} />;
};
export default MathText;