import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import empty from '../../../assets/images/horus/empty.png';

export default function CardEmpty() {
    const { t } = useTranslation();
    
    return (
        <Box sx={{ flexGrow: 1, m: 3 }}>
            <Grid container sx={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                    <Box component='img' src={empty} height={150} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' color='text.secondary'>
                        {t('components.card.cardEmpty.empty')}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}