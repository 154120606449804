import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography, Chip } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

const TreeItemRoot = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
        paddingRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

export default function StyleTreeItem(props) {
    const {
        bgColor,
        color,
        LabelType,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <TreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pr: 0 }}>
                    {LabelType && <Chip label={LabelType} color='primary' size='small' />}
                    <Typography variant='body1' sx={{ ml: 0.5, fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant='caption' color='inherit' sx={{ mr: 0.5 }}>
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}
StyleTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelType: PropTypes.string,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string,
};