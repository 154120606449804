import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete, ExpandMore } from '@mui/icons-material';
import { formatMessage } from '../../utils/utils';
import { requestPost } from '../../utils/request';
import { URL_API_INV, URL_API_EXT } from '../../constants';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
const focusedStyle = {
    borderColor: '#2196f3'
};
const acceptStyle = {
    borderColor: '#00e676'
};
const rejectStyle = {
    borderColor: '#ff1744'
};

function Dragndrop(props) {
    const { investigation, login, namefile, value, setSettings } = props;
    const [myFiles, setMyFiles] = useState([]);
    const defaultHierarchy = investigation.hierarchy;
    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles]);
    }, [myFiles])
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        // acceptedFiles
    } = useDropzone({
        accept: { 'text/csv': ['.csv', '.xlsx'] }, onDrop, maxFiles: 1
    });
    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }
    async function sendFiles(value) {
        const formData = new FormData();
        formData.append('file', myFiles[0]);
        Promise.all([
            requestPost(`${URL_API_EXT}/extension/upload/${value}`, formData)
        ])
            .then(res => {
                setSettings({ snackbar: formatMessage(true, 'success', res[0].data.upload), });
            })
            .catch(err => {
                setSettings({ snackbar: formatMessage(true, 'error', err) });
            });
        const hierarchy = {
            ...defaultHierarchy,
            resource: namefile,
            subResource: 'Cargue de archivo',
            action: {
                type: 'Archivo tipo ' + myFiles[0].name.split('.')?.[1],
                value: ['Identificador: ', login.admin.infoUser._id, ' Usuario: ', login.admin.infoUser.user, ' Archivo: ', myFiles[0].name.split('.')?.[0]].join('')
            }
        };
        requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
        requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
        setMyFiles([]);
    }
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const files = myFiles.map(file => (
        <li key={file.path}>
            <Typography variant='p' component='div' sx={{ textAlign: 'start', display: 'inline-block' }}> {file.path} </Typography>
            <IconButton
                onClick={removeFile(file)}
                edge='end'
                color='secondary'
            >
                <Delete />
            </IconButton>
        </li>
    ))
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ backgroundColor: '#94B43B' }}
                >
                    <Typography>{namefile}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant='p'
                        component='div'
                        sx={{ textAlign: 'start', display: 'inline-block', mt: 0.5, mb: 1 }}
                    >
                        Carga aquí el archivo de {namefile}
                    </Typography>
                    <div {...getRootProps({ style })} >
                        <input {...getInputProps()} />
                        <p style={{ margin: 20 }}>Arrastra y suelta archivos aqui o clikea para seleccionarlos</p>
                        <em>(1 archivo como máximo)</em>
                    </div>
                    <aside>
                        <Typography
                            variant='h4'
                            component='div'
                            sx={{ textAlign: 'start', mt: 1 }}
                        >
                            Archivos seleccionados
                        </Typography>
                        <ul>{files}</ul>
                    </aside>
                    {files.length > 0 &&
                        <LoadingButton
                            loadingPosition='center'
                            variant='contained'
                            m='0.7'
                            onClick={() => sendFiles(value)}
                        >
                            Enviar archivo
                        </LoadingButton>
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
Dragndrop.propTypes = {
    namefile: PropTypes.string,
    value: PropTypes.string
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    login: state.login,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Dragndrop);