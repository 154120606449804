import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Stepper, Step, StepLabel, Grid, TextField, MenuItem, IconButton, Avatar, FormControl } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { CheckCircleTwoTone } from '@mui/icons-material';
import Tooltip from '../../../components/Tooltip';
import imgDatos from '../../../../assets/images/horus/datos.png';

function CustomStepper(props) { 
    const { activeStep, form, handleValues, handleValidation } = props;
    return (
        <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
                <Step key='0'>
                    <StepLabel>
                        <Typography variant='h5'>
                            Información
                        </Typography>
                    </StepLabel>
                </Step>
                <Step key='1'>
                    <StepLabel>
                        <Typography variant='h5'>
                            Aviso de privacidad protección de datos personales
                        </Typography>
                    </StepLabel>
                </Step>
                <Step key='2'>
                    <StepLabel>
                        <Typography variant='h5'>
                            Datos personales
                        </Typography>
                    </StepLabel>
                </Step>
                <Step key='3'>
                    <StepLabel>
                        <Typography variant='h5'>
                            Satisfacción general sobre el Sistema
                        </Typography>
                    </StepLabel>
                </Step>
                <Step key='4' sx={{ display: form.validation.value === 'teacher' ? 'inline-block' : 'none' }}>
                    <StepLabel>
                        <Typography variant='h5'>
                            Validación de la información del Perfil Docente
                        </Typography>
                    </StepLabel>
                </Step>
            </Stepper>
            <TabContext value={activeStep.toString()}>
                <TabPanel value='0'>
                    <Typography variant='body0'>
                        Con el fin de mejorar y aumentar la calidad de los datos recopilados, ponemos a disposición de los usuarios del Sistema la presente encuesta para conocer la percepción de la comunidad académica frente a la plataforma, así como también casos particulares y errores que puedan presentarse en los diversos componentes o información imprecisa mostrada en el aplicativo web. De esta forma, a partir de su interacción por el Sistema, agradecemos formule sus observaciones y responda las preguntas contenidas en este formulario.
                    </Typography>
                </TabPanel>
                <TabPanel value='1'>
                    <Typography variant='body0'>
                        Al enviar este formulario se entiende aceptada la Política de Tratamiento de Datos Personales de la Universidad, disponible en: <a href='https://unal.edu.co/proteccion-de-datos-personales' target='_blank' rel='noreferrer'>https://unal.edu.co/proteccion-de-datos-personales</a>
                    </Typography>
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <img
                            src={imgDatos}
                            alt='Tratamiento de datos'
                            width='84%'
                        />
                    </Box>
                </TabPanel>
                <TabPanel value='2'>
                    <Grid container spacing={3}>
                        <Grid item xs={form.email.value === '' ? 12 : 11}>
                            <TextField
                                fullWidth
                                label='Ingrese su correo electrónico institucional (o personal si es externo a la Universidad).'
                                value={form.email.value}
                                onChange={(event) => handleValues('email', event.target.value)}
                                error={Boolean(form.email.error)}
                                helperText={form.email.error}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={form.email.value === '' ? 0 : 1}
                            sx={{ display: form.email.value !== '' ? 'inline-block' : 'none' }}
                        >
                            <Tooltip
                                title='Presione para validar su correo electrónico'
                                active={form.email.value === '' ? false : true}
                            >
                                <IconButton onClick={() => handleValidation(form.email.value)}>
                                    <Avatar
                                        sx={{
                                            width: 45,
                                            height: 45,
                                            bgcolor: theme => Boolean(form.validation.error)
                                                ? theme.palette.error.main
                                                : form.validation.value !== ''
                                                    ? theme.palette.primary.main
                                                    : '',
                                            fontSize: 16,
                                        }}
                                    >
                                        <CheckCircleTwoTone />
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: form.validation.value === 'teacher' ? 'inline-block' : 'none' }}
                        >
                            <TextField
                                fullWidth
                                label='Ingrese el código de verificación enviado a su correo electrónico'
                                value={form.code.value}
                                onChange={(event) => handleValues('code', event.target.value)}
                                error={Boolean(form.code.error)}
                                helperText={form.code.error}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: form.validation.value === 'noTeacher' ? 'inline-block' : 'none' }}
                        >
                            <TextField
                                fullWidth
                                label='Ingrese su nombre completo'
                                value={form.name.value}
                                onChange={(event) => handleValues('name', event.target.value)}
                                error={Boolean(form.name.error)}
                                helperText={form.name.error}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: form.validation.value === 'noTeacher' ? 'inline-block' : 'none' }}
                        >
                            <TextField
                                select
                                fullWidth
                                label='Especifique su relación con la Universidad'
                                value={form.relation.value}
                                onChange={(event) => handleValues('relation', event.target.value)}
                                error={Boolean(form.relation.error)}
                                helperText={form.relation.error}
                            >
                                <MenuItem value='Directivo'>Directivo</MenuItem>
                                <MenuItem value='Administrativo'>Administrativo</MenuItem>
                                <MenuItem value='Estudiante'>Estudiante</MenuItem>
                                <MenuItem value='Egresado'>Egresado</MenuItem>
                                <MenuItem value='Externo'>Externo</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: form.validation.value === 'noTeacher' ? 'inline-block' : 'none' }}
                        >
                            <TextField
                                select
                                fullWidth
                                label='Especifique la Sede a la que pertenece'
                                value={form.campus.value}
                                onChange={(event) => handleValues('campus', event.target.value)}
                                error={Boolean(form.campus.error)}
                                helperText={form.campus.error}
                            >
                                <MenuItem value='Bogotá'>Bogotá</MenuItem>
                                <MenuItem value='Medellín'>Medellín</MenuItem>
                                <MenuItem value='Manizales'>Manizales</MenuItem>
                                <MenuItem value='Palmira'>Palmira</MenuItem>
                                <MenuItem value='De la Paz'>De la Paz</MenuItem>
                                <MenuItem value='Tumaco'>Tumaco</MenuItem>
                                <MenuItem value='Orinoquía'>Orinoquía</MenuItem>
                                <MenuItem value='Amazonía'>Amazonía</MenuItem>
                                <MenuItem value='Caribe'>Caribe</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value='3'>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg1.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qg1.value}
                                    onChange={(event) => handleValues('qg1', event.target.value)}
                                    error={Boolean(form.qg1.error)}
                                    helperText={form.qg1.error}
                                >
                                    {
                                        form.qg1.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg2.label}
                                </Typography>
                                <TextField
                                    value={form.qg2.value}
                                    onChange={(event) => handleValues('qg2', event.target.value)}
                                    error={Boolean(form.qg2.error)}
                                    helperText={form.qg2.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg3.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qg3.value}
                                    onChange={(event) => handleValues('qg3', event.target.value)}
                                    error={Boolean(form.qg3.error)}
                                    helperText={form.qg3.error}
                                >
                                    {
                                        form.qg3.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg4.label}
                                </Typography>
                                <TextField
                                    value={form.qg4.value}
                                    onChange={(event) => handleValues('qg4', event.target.value)}
                                    error={Boolean(form.qg4.error)}
                                    helperText={form.qg4.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg5.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qg5.value}
                                    onChange={(event) => handleValues('qg5', event.target.value)}
                                    error={Boolean(form.qg5.error)}
                                    helperText={form.qg5.error}
                                >
                                    {
                                        form.qg5.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg6.label}
                                </Typography>
                                <TextField
                                    value={form.qg6.value}
                                    onChange={(event) => handleValues('qg6', event.target.value)}
                                    error={Boolean(form.qg6.error)}
                                    helperText={form.qg6.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg7.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qg7.value}
                                    onChange={(event) => handleValues('qg7', event.target.value)}
                                    error={Boolean(form.qg7.error)}
                                    helperText={form.qg7.error}
                                >
                                    {
                                        form.qg7.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg8.label}
                                </Typography>
                                <TextField
                                    value={form.qg8.value}
                                    onChange={(event) => handleValues('qg8', event.target.value)}
                                    error={Boolean(form.qg8.error)}
                                    helperText={form.qg8.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg9.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qg9.value}
                                    onChange={(event) => handleValues('qg9', event.target.value)}
                                    error={Boolean(form.qg9.error)}
                                    helperText={form.qg9.error}
                                >
                                    {
                                        form.qg9.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg10.label}
                                </Typography>
                                <TextField
                                    value={form.qg10.value}
                                    onChange={(event) => handleValues('qg10', event.target.value)}
                                    error={Boolean(form.qg10.error)}
                                    helperText={form.qg10.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg11.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qg11.value}
                                    onChange={(event) => handleValues('qg11', event.target.value)}
                                    error={Boolean(form.qg11.error)}
                                    helperText={form.qg11.error}
                                >
                                    {
                                        form.qg11.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qg12.label}
                                </Typography>
                                <TextField
                                    value={form.qg12.value}
                                    onChange={(event) => handleValues('qg12', event.target.value)}
                                    error={Boolean(form.qg12.error)}
                                    helperText={form.qg12.error}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value='4'>
                    <Typography variant='body0'>
                        En esta sección, le agradecemos ingresar a su perfil como docente y revisar la información que allí se presenta teniendo en cuenta las fuentes de información incluidas hasta el momento: Scopus, ResearchGate, Web of Science, PubMed, HERMES, SARA, Catálogo UN Tesis del Repositorio Institucional. A partir de la revisión que realice, por favor, contestar las siguientes preguntas.
                    </Typography>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt1.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qt1.value}
                                    onChange={(event) => handleValues('qt1', event.target.value)}
                                    error={Boolean(form.qt1.error)}
                                    helperText={form.qt1.error}
                                >
                                    {
                                        form.qt1.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt2.label}
                                </Typography>
                                <TextField
                                    value={form.qt2.value}
                                    onChange={(event) => handleValues('qt2', event.target.value)}
                                    error={Boolean(form.qt2.error)}
                                    helperText={form.qt2.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt3.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qt3.value}
                                    onChange={(event) => handleValues('qt3', event.target.value)}
                                    error={Boolean(form.qt3.error)}
                                    helperText={form.qt3.error}
                                >
                                    {
                                        form.qt3.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt4.label}
                                </Typography>
                                <TextField
                                    value={form.qt4.value}
                                    onChange={(event) => handleValues('qt4', event.target.value)}
                                    error={Boolean(form.qt4.error)}
                                    helperText={form.qt4.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt5.label}
                                </Typography>
                                <TextField
                                    select
                                    value={form.qt5.value}
                                    onChange={(event) => handleValues('qt5', event.target.value)}
                                    error={Boolean(form.qt5.error)}
                                    helperText={form.qt5.error}
                                >
                                    {
                                        form.qt5.options.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt6.label}
                                </Typography>
                                <TextField
                                    value={form.qt6.value}
                                    onChange={(event) => handleValues('qt6', event.target.value)}
                                    error={Boolean(form.qt6.error)}
                                    helperText={form.qt6.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt7.label}
                                </Typography>
                                <TextField
                                    value={form.qt7.value}
                                    onChange={(event) => handleValues('qt7', event.target.value)}
                                    error={Boolean(form.qt7.error)}
                                    helperText={form.qt7.error}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <Typography variant='body1' color='text.secondary' noWrap={false}>
                                    {form.qt8.label}
                                </Typography>
                                <TextField
                                    value={form.qt8.value}
                                    onChange={(event) => handleValues('qt8', event.target.value)}
                                    error={Boolean(form.qt8.error)}
                                    helperText={form.qt8.error}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Box>
    );
}
CustomStepper.propTypes = {
    activeStep: PropTypes.number,
    form: PropTypes.object,
    handleValues: PropTypes.func,
    handleValidation: PropTypes.func
};
export default CustomStepper;