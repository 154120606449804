import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});
function CustomDialog(props) {
    const { _id, open, title, width, handleClose, handleBack, handleNext, children } = props;
    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={width}
            TransitionComponent={Transition}
            onClose={handleClose}
            keepMounted
        >
            {title !== '' && (
                <DialogTitle>
                    {title}
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions sx={{ p: 3, pt: 3 }}>
                {handleBack.active && (
                    <Button onClick={handleBack.function} variant='contained'>
                        {handleBack.label}
                    </Button>
                )}
                {handleNext.active && (
                    <Button onClick={handleNext.function} variant='contained' type='submit' form={_id}>
                        {handleNext.label}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
CustomDialog.propTypes = {
    _id: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    width: PropTypes.string,
    handleClose: PropTypes.func,
    handleBack: PropTypes.shape({
        active: PropTypes.bool,
        label: PropTypes.string,
        function: PropTypes.func
    }),
    handleNext: PropTypes.shape({
        active: PropTypes.bool,
        label: PropTypes.string,
        function: PropTypes.func
    })
};
export default CustomDialog;