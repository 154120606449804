import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { filterData, SearchType } from 'filter-data';
import ExcelJS from 'exceljs';
import { Stack, Grid, Typography, Avatar, Box, Chip, IconButton, Card, CardHeader, CardContent, TextField, FormControl, InputLabel, Select, MenuItem, ButtonGroup, Button, TablePagination, Autocomplete } from '@mui/material';
import { DownloadForOfflineTwoTone, FormatQuoteTwoTone, PaidTwoTone, MonitorHeartTwoTone, BorderColorTwoTone, LaunchTwoTone, CalendarMonthTwoTone, ArrowDropDownTwoTone, ArrowDropUpTwoTone, LanguageTwoTone, EditOffTwoTone, Diversity3TwoTone, HourglassTopTwoTone } from '@mui/icons-material';
import CardEmpty from './CardEmpty';
import Tooltip from '../Tooltip';
import Scrollbar from '../Scrollbar';
import MathText from '../MathText';
import TreeSearch from '../tree/TreeSearch';
import usePagination from '../../../utils/pagination';
import { requestPost } from '../../../utils/request';
import { formatDate, formatDateLarge, formatDateExt, getOptionsType, getOptionsAuthor, getOptionsAuthorValue, getParams, getParamsRouteString, formatText, formatDownload, formatCurrency, formatCapitalize } from '../../../utils/utils';
import { constants, URL_API_INV } from '../../../constants';

function CardPublicationsList(props) {
    const { data, params, hierarchy, options, dependence, settings, setMetricsDefault } = props;
    const [pagination, setPagination] = useState({
        page: 0,
        rowsPerPage: 10
    });
    const [filter, setFilter] = useState({
        result: [],
        search: '',
        dependence: '',
        type: [],
        author: [],
        typeOptions: [],
        authorOptions: [],
        order: { type: options.order, variant: 'dsc' }
    });

    const navigate = useNavigate();
    const { t } = useTranslation();
    const count = filter.result.length;
    var _DATA = usePagination(filter.result, pagination.rowsPerPage);
    const activeDependence = hierarchy.hierarchy.dependence[1] === 'Autenticación'
        ? 'Investigación'
        : hierarchy.hierarchy.dependence[1] || 'Investigación';

    const handleChangePage = (event, newPage) => {
        setPagination({ ...pagination, page: newPage });
        _DATA.jump(newPage);
        handleSubResource('Cambio en paginación', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        });
        _DATA.jump(0);
        handleSubResource('Cambio en número de publicaciones por pagina', parseInt(event.target.value, 10));
    };

    const handleSearch = (event) => {
        filterPublications(event.target.value, filter.type, filter.author, filter.order, {});
    };

    const handleType = (event, value) => {
        filterPublications(filter.search, value, filter.author, filter.order, {});
        handleSubResource('Cambio en tipo de ' + activeDependence === 'Investigación' ? 'publicaciones' : 'modalidad', value.join(' - '));
    };

    const handleAuthor = (value) => {
        const author = getOptionsAuthorValue([value]);
        filterPublications(filter.search, filter.type, author, filter.order, {});
        handleSubResource('Cambio en autor', author.join(' - '));
    };

    const handleAuthorValue = (event, value) => {
        const author = event.type === 'keydown' ? value.pop() : value;
        filterPublications(filter.search, filter.type, author, filter.order, {});
        handleSubResource('Cambio en autor', author.join(' - '));
    };

    const handleOrderType = (event) => {
        const typeOrder = {
            date: 'Ordenar por fecha',
            citations: activeDependence === 'Investigación' ? 'Ordenar por citaciones' : 'Ordenar por valor',
            relevance: 'Ordenar por relevancia'
        };
        const newOrder = {
            type: event.target.value,
            variant: filter.order.variant
        };
        filterPublications(filter.search, filter.type, filter.author, newOrder, {});
        handleSubResource(['Ordenar por fecha', options.relevance ? activeDependence === 'Investigación' ? ', citaciones y relevancia' : ', valor y relevancia' : activeDependence === 'Investigación' ? ' y citaciones' : ' y valor'].join(''), typeOrder[event.target.value]);
    };

    const handleOrderVariant = (value) => {
        const newOrder = {
            type: filter.order.type,
            variant: value
        };
        filterPublications(filter.search, filter.type, filter.author, newOrder, {});
        handleSubResource('Ordenar por ascendente o descendente', value === 'asc' ? 'Ascendente' : 'Descendente');
    };

    const handleDownload = () => {
        const value = formatDownload(filter.result);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Lista de Productos');
        worksheet.columns = activeDependence === 'Investigación' ? [
            { header: 'Título original', key: constants.PUBLICATION_TITLE_FIELD },
            { header: 'Título en español', key: constants.PUBLICATION_SPANISH_TITLE_FIELD },
            { header: 'Título en ingles', key: constants.PUBLICATION_ENGLISH_TITLE_FIELD },
            { header: 'Descripción original', key: constants.PUBLICATION_ABSTRACT_FIELD },
            { header: 'Descripción en español', key: constants.PUBLICATION_SPANISH_ABSTRACT_FIELD },
            { header: 'Descripción en ingles', key: constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD },
            { header: 'Revista / Conferencia', key: constants.PUBLICATION_PUBNAME_FIELD },
            { header: 'Coautores', key: constants.PUBLICATION_AUTHORS_VALUE_FIELD },
            { header: 'Doi', key: constants.PUBLICATION_DOI_FIELD },
            { header: 'ISBN', key: constants.PUBLICATION_ISBN_FIELD },
            { header: 'ISSN', key: constants.PUBLICATION_ISSN_FIELD },
            { header: 'Citaciones', key: constants.PUBLICATION_CITATION_FIELD },
            { header: 'Idioma', key: constants.PUBLICATION_LANGUAGE_FIELD },
            { header: 'Fecha', key: constants.PUBLICATION_DATE_FIELD },
            { header: 'Tipo', key: constants.PUBLICATION_SOURCE_TYPE_FIELD },
            // { header: 'Fuente', key: constants.PUBLICATION_SOURCE_FIELD },
            { header: 'Enlace', key: constants.PUBLICATION_URL_FIELD },
        ] : [
            { header: 'Entidad contratante', key: constants.PUBLICATION_TITLE_FIELD },
            { header: 'Modalidad', key: constants.PUBLICATION_SOURCE_TYPE_FIELD },
            { header: 'Nombre u objeto', key: constants.PUBLICATION_ABSTRACT_FIELD },
            { header: 'Directores', key: constants.PUBLICATION_AUTHORS_FIELD },
            { header: 'Valor', key: constants.PUBLICATION_CITATION_FIELD },
            { header: 'Idioma', key: constants.PUBLICATION_LANGUAGE_FIELD },
            { header: 'Fecha inicio', key: constants.PUBLICATION_DATE_FIELD },
            { header: 'Fecha fin', key: constants.PUBLICATION_DATE_END_FIELD },
        ];
        worksheet.addRows(value);
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell, colNumber) => {
            if (cell.value) {
                cell.font = { bold: true };
                cell.border = { bottom: { style: 'thin' } };
            }
        });
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = [params.author, ' - ', formatDateLarge(new Date()), '.xlsx'].join('');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
        handleSubResource('Descargar en formato csv.', 'Descarga de archivo csv.');
    };

    const handleUrl = (url) => {
        handleSubResource('Visualizar publicación en enlace externo (URL)', url);
    };

    const handleRoute = (name) => {
        setMetricsDefault();
        let route = getParams(dependence, name);
        route = [route, 'resource', getParamsRouteString(params.resource)].join('/');
        navigate(route, { state: { key: 'hierarchy' } });
    };

    const handleCurator = (_id, deleted) => {
        setMetricsDefault();
        const isDeleted = deleted ? '/deleted/Publicación eliminada' : '';
        navigate(`/login/Inicio de sesión/curator/Curaduria${isDeleted}/publication/${_id}`, { state: { key: 'hierarchy' } });
    };

    const filterPublications = (search, type, author, order, optionsData) => {
        const authorOptions = getOptionsAuthorValue(filter.authorOptions);
        const searchConditions = [
            {
                key: [
                    constants.PUBLICATION_TITLE_FIELD,
                    constants.PUBLICATION_SPANISH_TITLE_FIELD,
                    constants.PUBLICATION_ENGLISH_TITLE_FIELD,
                    constants.PUBLICATION_ABSTRACT_FIELD,
                    constants.PUBLICATION_SPANISH_ABSTRACT_FIELD,
                    constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD
                ],
                value: search,
                type: SearchType.LK
            }
        ];
        var newFilter = filterData(data, searchConditions);
        newFilter = type.length !== filter.typeOptions.length
            ? newFilter.filter((item) => type.includes(item[constants.PUBLICATION_SOURCE_TYPE_FIELD]))
            : newFilter;
        newFilter = author.length !== authorOptions.length
            ? newFilter.filter((item) => author.some(subItem => item[constants.PUBLICATION_AUTHORS_VALUE_FIELD].includes(subItem)))
            : newFilter;
        newFilter = order.type === 'date'
            ? newFilter.sort((a, b) => {
                let dateA = a[constants.PUBLICATION_DATE_FIELD] ? new Date(a[constants.PUBLICATION_DATE_FIELD]).getTime() : '';
                let dateB = b[constants.PUBLICATION_DATE_FIELD] ? new Date(b[constants.PUBLICATION_DATE_FIELD]).getTime() : '';
                if (order.variant === 'asc') {
                    dateA = dateA === '' ? new Date() : dateA;
                    dateB = dateB === '' ? new Date() : dateB;
                    if (dateA > dateB) return 1;
                    else return -1;
                } else {
                    if (dateA < dateB) return 1;
                    else return -1;
                }
            }) : order.type === 'citations'
                ? newFilter.sort((a, b) => {
                    const aValue = parseInt(a[constants.PUBLICATION_CITATION_FIELD] || 0);
                    const bValue = parseInt(b[constants.PUBLICATION_CITATION_FIELD] || 0);
                    if (order.variant === 'asc') {
                        if (aValue > bValue) return 1;
                        else return -1;
                    } else {
                        if (aValue < bValue) return 1;
                        else return -1;
                    }
                }) : order.type === 'relevance'
                    ? newFilter.sort((a, b) => {
                        const aValue = parseInt(a.relevance || 0);
                        const bValue = parseInt(b.relevance || 0);
                        if (order.variant === 'asc') {
                            if (aValue > bValue) return 1;
                            else return -1;
                        } else {
                            if (aValue < bValue) return 1;
                            else return -1;
                        }
                    }) : newFilter.sort((a, b) => {
                        const aValue = a[constants.PUBLICATION_TITLE_FIELD];
                        const bValue = b[constants.PUBLICATION_TITLE_FIELD];
                        if (order.variant === 'asc') {
                            if (aValue > bValue) return 1;
                            else return -1;
                        } else {
                            if (aValue < bValue) return 1;
                            else return -1;
                        }
                    });

        const init = Object.keys(optionsData).length > 0 ? true : false;
        setFilter({
            ...filter,
            result: newFilter,
            search: search,
            type: type,
            author: author,
            typeOptions: init ? optionsData.typeOptions : filter.typeOptions,
            authorOptions: init ? optionsData.authorOptions : filter.authorOptions,
            order: order
        });
        setPagination({ ...pagination, page: 0 });
        _DATA.jump(0);
    };

    const handleSubResource = (type, value) => {
        const hierarchyDefault = {
            ...hierarchy,
            subResource: 'Lista de Productos',
            action: { type: type, value: value }
        };
        requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchyDefault });
    };

    useEffect(() => {
        const type = getOptionsType(data, constants.PUBLICATION_SOURCE_TYPE_FIELD);
        const authorOptions = getOptionsAuthor(data, constants.PUBLICATION_AUTHORS_FIELD);
        let author = getOptionsAuthorValue(authorOptions);

        if (options.author.length > 0) {
            author = options.author;
        }

        filterPublications('', type, author, filter.order, { typeOptions: type, authorOptions: authorOptions });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <TextField
                    sx={{ mt: 2 }}
                    label={t('components.card.cardPublicationsList.searchContent')}
                    value={filter.search}
                    variant='outlined'
                    onChange={handleSearch}
                    fullWidth
                />
                <Box
                    sx={{
                        mt: 2,
                        ml: 0.08,
                        borderRadius: 1,
                        border: '1px solid #C0C0C0',
                        width: '100%'
                    }}
                >
                    <Autocomplete
                        freeSolo
                        multiple
                        options={[]}
                        limitTags={2}
                        value={filter.author}
                        onChange={(event, value) => { handleAuthorValue(event, value) }}
                        onKeyUp={(event) => setFilter({ ...filter, dependence: event.target.value })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                label={activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.authorTrue') : t('components.card.cardPublicationsList.authorFalse')}
                                placeholder={activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.authorTrue') : t('components.card.cardPublicationsList.authorFalse')}
                            />
                        )}
                        sx={{ pr: 1, pl: 1, mb: 1 }}
                    />
                    <TreeSearch
                        height={200}
                        dataTree={filter.authorOptions}
                        params={params}
                        search={filter.dependence}
                        handleClick={(value) => handleAuthor(value)}
                    />
                </Box>
                <Autocomplete
                    id='type'
                    value={filter.type}
                    onChange={handleType}
                    options={filter.typeOptions}
                    multiple
                    limitTags={2}
                    filterSelectedOptions={true}
                    sx={{ mt: 2 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.typeTrue') : t('components.card.cardPublicationsList.typeFalse')}
                        />
                    )}
                />
                <FormControl sx={{ mt: 2 }} fullWidth>
                    <InputLabel id='order'>
                        {options.relevance
                            ? activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.orderTrueTrue') : t('components.card.cardPublicationsList.orderTrueFalse')
                            : activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.orderFalseTrue') : t('components.card.cardPublicationsList.orderFalseFalse')
                        }
                    </InputLabel>
                    <Select
                        labelId='order'
                        value={filter.order.type}
                        label={options.relevance
                            ? activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.orderTrueTrue') : t('components.card.cardPublicationsList.orderTrueFalse')
                            : activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.orderFalseTrue') : t('components.card.cardPublicationsList.orderFalseFalse')
                        }
                        onChange={handleOrderType}
                    >
                        <MenuItem value='date'>{t('components.card.cardPublicationsList.orderDate')}</MenuItem>
                        <MenuItem value='citations'>{t('components.card.cardPublicationsList.orderCitations')}{activeDependence === 'Investigación' ? t('components.card.cardPublicationsList.orderCitationsTrue') : t('components.card.cardPublicationsList.orderCitationsFalse')}</MenuItem>
                        {options.relevance && <MenuItem value='relevance'>{t('components.card.cardPublicationsList.orderRelevance')}</MenuItem>}
                    </Select>
                </FormControl>
                <FormControl sx={{ mt: 2 }} fullWidth>
                    <ButtonGroup fullWidth disableElevation>
                        <Button
                            onClick={() => handleOrderVariant('asc')}
                            variant={filter.order.variant === 'asc' ? 'contained' : 'outlined'}
                            startIcon={<ArrowDropUpTwoTone />}
                        >
                            {t('components.card.cardPublicationsList.orderAsc')}
                        </Button>
                        <Button
                            onClick={() => handleOrderVariant('dsc')}
                            variant={filter.order.variant === 'dsc' ? 'contained' : 'outlined'}
                            endIcon={<ArrowDropDownTwoTone />}
                        >
                            {t('components.card.cardPublicationsList.orderDsc')}
                        </Button>
                    </ButtonGroup>
                </FormControl>
                <Button
                    fullWidth
                    startIcon={<DownloadForOfflineTwoTone />}
                    onClick={handleDownload}
                    variant='contained'
                    sx={{ mt: 2 }}
                >
                    {t('components.card.cardPublicationsList.download')}
                </Button>
            </Grid>
            <Grid item xs={12} sm={8}>
                {filter.result.length === 0 ? (
                    <CardEmpty />
                ) : (
                    <Box sx={{ m: 2 }}>
                        <TablePagination
                            component='div'
                            count={count}
                            page={pagination.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={pagination.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t('components.card.cardPublicationsList.labelRows')}
                            sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
                        />
                        <Scrollbar sx={{ maxHeight: 500 }}>
                            {_DATA?.currentData()?.map(item => {
                                return (
                                    <Card key={item._id} sx={{ width: '100%', mb: 2 }}>
                                        <CardHeader
                                            title={
                                                <Box>
                                                    <Typography variant='h6'>
                                                        <MathText
                                                            text={formatText(
                                                                settings.language,
                                                                item[constants.PUBLICATION_TITLE_FIELD],
                                                                item[constants.PUBLICATION_SPANISH_TITLE_FIELD],
                                                                item[constants.PUBLICATION_ENGLISH_TITLE_FIELD],
                                                                t('components.card.cardPublicationsList.title')
                                                            )}
                                                        />
                                                    </Typography>
                                                </Box>
                                            }
                                            subheader={
                                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? t('components.card.cardPublicationsList.typeTrue')
                                                                : t('components.card.cardPublicationsList.typeFalse')
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    {item[constants.PUBLICATION_SOURCE_TYPE_FIELD]}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? t('components.card.cardPublicationsList.dateTrue')
                                                                : t('components.card.cardPublicationsList.dateFalse')
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    <CalendarMonthTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? formatDate(item[constants.PUBLICATION_DATE_FIELD])
                                                                            : formatDateExt(item[constants.PUBLICATION_DATE_FIELD], item[constants.PUBLICATION_DATE_END_FIELD])
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? formatCapitalize(t('components.card.cardPublicationsList.orderCitationsTrue'))
                                                                : item.variant === 'ActivitiesCol'
                                                                    ? t('components.card.cardPublicationsList.orderCitationsFalseVariant')
                                                                    : formatCapitalize(t('components.card.cardPublicationsList.orderCitationsFalse'))
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? <FormatQuoteTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? <Diversity3TwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                                : <PaidTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                    }
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? item[constants.PUBLICATION_CITATION_FIELD] || 0
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? item[constants.PUBLICATION_CITATION_FIELD] || 0
                                                                                : formatCurrency(item[constants.PUBLICATION_CITATION_FIELD] || 0)
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    {options.relevance && (
                                                        <Tooltip title={t('components.card.cardPublicationsList.relevance')}>
                                                            <Chip
                                                                variant='outlined'
                                                                label={
                                                                    <Typography variant='body1' color='text.secondary'>
                                                                        <MonitorHeartTwoTone fontSize='small' sx={{ mr: 0.25 }} /> {item.relevance}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip
                                                        title={
                                                            activeDependence === 'Investigación'
                                                                ? t('components.card.cardPublicationsList.languageTrue')
                                                                : item.variant === 'ActivitiesCol'
                                                                    ? t('components.card.cardPublicationsList.languageFalseVariant')
                                                                    : t('components.card.cardPublicationsList.languageFalse')
                                                        }
                                                    >
                                                        <Chip
                                                            variant='outlined'
                                                            label={
                                                                <Typography variant='body1' color='text.secondary'>
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? <LanguageTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? <HourglassTopTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                                : <LanguageTwoTone fontSize='small' sx={{ mr: 0.25 }} />
                                                                    }
                                                                    {
                                                                        activeDependence === 'Investigación'
                                                                            ? constants[settings.language === 'es' ? 'LANGUAGE_ES' : 'LANGUAGE_EN'][item[constants.PUBLICATION_LANGUAGE_FIELD]]
                                                                            : item.variant === 'ActivitiesCol'
                                                                                ? item[constants.intensity] || 0
                                                                                : constants.LANGUAGE[item[constants.PUBLICATION_LANGUAGE_FIELD]]
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </Tooltip>
                                                    {item.corrections && (
                                                        <Tooltip title={t('components.card.cardPublicationsList.correctionsApplied')}>
                                                            <Chip
                                                                color='primary'
                                                                label={
                                                                    <Typography variant='body1'>
                                                                        <BorderColorTwoTone fontSize='small' sx={{ mr: 0.25 }} /> {item.corrections.filter(item => item.state).length}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {item.corrections && (
                                                        <Tooltip title={t('components.card.cardPublicationsList.correctionsPending')}>
                                                            <Chip
                                                                color='primary'
                                                                label={
                                                                    <Typography variant='body1'>
                                                                        <EditOffTwoTone fontSize='small' sx={{ mr: 0.25 }} /> {item.corrections.filter(item => !item.state).length}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            }
                                            action={
                                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
                                                    {(item[constants.PUBLICATION_URL_FIELD]?.length || 0) !== 0 && (
                                                        [item[constants.PUBLICATION_URL_FIELD].find(url => url.includes('dx.doi.org/')) || item[constants.PUBLICATION_URL_FIELD][0]].map((item, index) => (
                                                            <Tooltip key={index} title={t('components.card.cardPublicationsList.linkProduct')}>
                                                                <IconButton href={item} target='_blank' rel='noreferrer' onClick={() => handleUrl(item)}>
                                                                    <Avatar sx={{ bgcolor: theme => theme.palette.primary.main }}>
                                                                        <LaunchTwoTone />
                                                                    </Avatar>
                                                                </IconButton>
                                                            </Tooltip>
                                                        ))
                                                    )}
                                                    {options.edit && (
                                                        <Tooltip title={options.deleted ? t('components.card.cardPublicationsList.curatorTrue') : t('components.card.cardPublicationsList.curatorFalse')}>
                                                            <IconButton
                                                                onClick={() => handleCurator(item[constants.PUBLICATION_ID_FIELD], options.deleted)}
                                                            >
                                                                <Avatar sx={{ bgcolor: theme => theme.palette.info.main }}>
                                                                    <BorderColorTwoTone />
                                                                </Avatar>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            }
                                        />
                                        <CardContent>
                                            <Typography variant='body1' color='text.secondary'>
                                                <Typography variant='bold' component='label' sx={{ mr: 0.4 }}>
                                                    {
                                                        activeDependence === 'Investigación'
                                                            ? t('components.card.cardPublicationsList.authorTrue') + ':'
                                                            : (item.variant === 'ActivitiesCol'
                                                                ? t('components.card.cardPublicationsList.authorFalse') + ':'
                                                                : t('components.card.cardPublicationsList.authorFalseVariant') + ':')
                                                    }
                                                </Typography>
                                                {item[constants.PUBLICATION_AUTHORS_FIELD].map((author, subKey) => {
                                                    const separator = item[constants.PUBLICATION_AUTHORS_FIELD].length === subKey + 1 ? '.' : ', ';
                                                    let name = constants.AUTHOR_NAME_FIELD in author ? author[constants.AUTHOR_NAME_FIELD] : author.name || {};
                                                    name = typeof name === 'string' ? name : name[constants.PUBLICATION_NORMALIZED_SUBFIELD] ?? '';
                                                    if (params.author === name) {
                                                        return (
                                                            <Typography key={subKey} variant='bold' component='label' sx={{ mr: 0.4 }}>
                                                                {name + separator}
                                                            </Typography>
                                                        )
                                                    }
                                                    if (author[constants.AUTHOR_ISIN_FIELD]) {
                                                        return (
                                                            <Tooltip key={subKey} title={t('components.card.cardPublicationsList.linkAuthor')}>
                                                                <Typography
                                                                    component='label'
                                                                    color='primary'
                                                                    sx={{
                                                                        '&:hover': {
                                                                            textDecoration: 'underline'
                                                                        },
                                                                        cursor: 'pointer',
                                                                        mr: 0.4
                                                                    }}
                                                                    onClick={() => handleRoute(name)}
                                                                >
                                                                    {name + separator}
                                                                </Typography>
                                                            </Tooltip>
                                                        )
                                                    } else {
                                                        return (
                                                            <Typography key={subKey} component='label' sx={{ mr: 0.4 }}>
                                                                {name + separator}
                                                            </Typography>
                                                        )
                                                    }
                                                })}
                                            </Typography>
                                            {activeDependence === 'Extensión' && (
                                                <Typography variant='body1' color='text.secondary'>
                                                    <Typography variant='bold' component='label' sx={{ mr: 0.4 }}>
                                                        Uab:
                                                    </Typography>
                                                    {item[constants.DEPENDENCE_UAB_FIELD]}
                                                </Typography>
                                            )}
                                            <Typography variant='body1' color='text.secondary'>
                                                <Typography variant='bold' component='label' sx={{ mr: 0.4 }}>
                                                    {
                                                        activeDependence === 'Investigación'
                                                            ? t('components.card.cardPublicationsList.abstractTrue') + ':'
                                                            : item.variant === 'Projects'
                                                                ? t('components.card.cardPublicationsList.abstractFalseVariant') + ':'
                                                                : t('components.card.cardPublicationsList.abstractFalse') + ':'
                                                    }
                                                </Typography>
                                                <MathText
                                                    text={formatText(
                                                        settings.language,
                                                        item[constants.PUBLICATION_ABSTRACT_FIELD],
                                                        item[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD],
                                                        item[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD],
                                                        t('components.card.cardPublicationsList.abstractTrue')
                                                    )}
                                                />
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </Scrollbar>
                        <TablePagination
                            component='div'
                            count={count}
                            page={pagination.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={pagination.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t('components.card.cardPublicationsList.labelRows')}
                            sx={{ borderTop: 1, borderColor: 'divider', mt: 3 }}
                        />
                    </Box>
                )}
            </Grid >
        </Grid >
    );
};
CardPublicationsList.propTypes = {
    data: PropTypes.array,
    params: PropTypes.object,
    hierarchy: PropTypes.object,
    options: PropTypes.object
};
CardPublicationsList.defaultProps = {
    data: [],
    params: {},
    hierarchy: {},
    options: {
        deleted: false,
        edit: false,
        order: 'date',
        relevance: false,
        author: false
    }
};
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT'
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(CardPublicationsList);