import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as d3GeoProjection from 'd3-geo-projection';
import ColombiaMap from '../maps/ColombiaMap.json';
import { formatCapitalize } from '../../../utils/utils';

const range = (data) => {
    let newRange = []
    data.forEach((item) => {
        newRange.push(item.value)
    });
    newRange.sort(function (a, b) { return a - b });
    return newRange;
}
const drawSvg = ({ inputRef, infoGraph }) => {
    const scaleNum = 9;
    const scaleMin = 2.6;
    const scaleMax = 72.5;
    const square = 20;
    const colorMap = ['#F0F9FF', '#C3E6FB', '#87CEEB', '#5D9CEC', '#3D85C6', '#2C628E', '#1F497D', '#143056', '#0D1C2D'];
    const container = d3.select(inputRef.current);
    const svg = container
        .append('svg')
        .attr('width', infoGraph.width)
        .attr('height', infoGraph.height);
    const tooltip = container
        .append('div')
        .attr('class', 'tooltipChoroplethMap')
        .attr('id', 'tooltip')
        .style('opacity', 0);
    // const zoom = d3.zoom()
    //   .scaleExtent([1, 8])
    //   .translateExtent([[0, 0], [infoGraph.width, infoGraph.height]])
    //   .on('zoom', zoomed);
    // function zoomed() {
    //   svg.selectAll('path')
    //     .attr('transform', d3.event.transform);
    // }
    const projection = d3GeoProjection.geoRobinson().scale(1550).center([-74, 4.2]);
    const path = d3.geoPath().projection(projection);
    const values = range(infoGraph.data);
    const extent = d3.extent(values);
    const color = d3.scaleQuantile()
                .domain(values)
                .range(extent[1] >= scaleNum ? colorMap : colorMap.slice(0, Math.ceil(extent[1])));
    const x = d3.scaleLinear().domain([scaleMin, scaleMax]).rangeRound([600, 860]);
    const legend = svg
        .append('g')
        .attr('id', 'legend');
    const legendG = legend
        .selectAll('g.legend')
        .data(color.range().map(function (d) {
            d = color.invertExtent(d);
            if (d[0] == null) d[0] = x.domain()[0];
            if (d[1] == null) d[1] = x.domain()[1];
            return d;
        }))
        .enter();
    legendG
        .append('rect')
        .attr('x', 20)
        .attr('y', function (d, i) { return infoGraph.height - (i * square) - 2 * square })
        .attr('width', square)
        .attr('height', square)
        // .attr('class', 'square')
        // .attr('id', function (d) { return d[3] })
        .style('fill', function (d) {
            return color(d[0]);
        });
    legendG
        .append('text')
        .attr('x', 50)
        .attr('y', function (d, i) { return infoGraph.height - (i * square) - square - 6 })
        .text(function (d, i) {
            if (i === 0) return '< ' + parseInt(d[1]);
            if (i === ((extent[1] >= scaleNum ? scaleNum : Math.ceil(extent[1])) - 1)) return parseInt(d[0]) + ' >';
            return parseInt(d[0]) + ' - ' + parseInt(d[1]);
        });
    legend
        .append('text')
        .attr('x', 20)
        .attr('y', 470 - ((extent[1] >= scaleNum ? scaleNum : Math.ceil(extent[1])) * square))
        .text('Actividades')
        .style('font-size', '1.1rem');
    svg
        .append('g')
        .attr('class', 'locations')
        .selectAll('path')
        .data(ColombiaMap.features)
        .enter()
        .append('path')
        .attr('class', 'location')
        .attr('data-locations', (d) => d.properties.DPTO)
        .attr('data-value', (d) => {
            const result = infoGraph.data.filter((obj) => parseInt(obj.group) === parseInt(d.properties.DPTO));
            if (result[0]) {
                return result[0].value;
            }
            return null;
        })
        .attr('fill', (d) => {
            const result = infoGraph.data.filter((obj) => parseInt(obj.group) === parseInt(d.properties.DPTO));
            if (result[0]) {
                return color(result[0].value);
            }
            return color(0);
        })
        .attr('d', path)
        // .call(zoom)
        .on('mouseover', function (d) {
            d3.selectAll('.location,.square')
                .transition()
                .duration(200)
                .style('opacity', .5)
                .style('stroke', 'transparent');
            d3.select(this)
                .transition()
                .duration(200)
                .style('opacity', 1)
                .style('stroke', 'black');
            d3.select('#' + d.target.__data__.properties.legend)
                .transition()
                .duration(200)
                .style('opacity', 1)
                .style('stroke', 'black');
            tooltip.style('opacity', 0.9);
            tooltip.html(() => {
                const result = infoGraph.data.filter((obj) => parseInt(obj.group) === parseInt(d.target.__data__.properties.DPTO));
                if (result[0]) {
                    return `<b>${formatCapitalize(d.target.__data__.properties.NOMBRE_DPT)}: ${result[0].value}</b><br/>${result[0].type}`;
                }
                return `<b>${formatCapitalize(d.target.__data__.properties.NOMBRE_DPT)}</b>`;
            })
                .attr('data-value', () => {
                    const result = infoGraph.data.filter((obj) => parseInt(obj.group) === parseInt(d.target.__data__.properties.DPTO));
                    if (result[0]) {
                        return result[0].value;
                    }
                    return 0;
                })
                .style('left', `${d.layerX + 10}px`)
                .style('top', `${d.layerY - 28}px`);
        })
        .on('mouseout', function (d) {
            d3.selectAll('.location')
                .transition()
                .duration(200)
                .style('opacity', 1)
                .style('stroke', 'transparent');
            tooltip.style('opacity', 0);
        });
};
function ChoroplethColMap(props) {
    const { infoGraph } = props;
    const inputRef = useRef(null);
    useEffect(() => {
        d3.select(inputRef.current).select('svg').remove();
        drawSvg({ inputRef, infoGraph });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoGraph.data]);
    return (
        <div id='graph-container' ref={inputRef} />
    );
} 
ChoroplethColMap.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.array,
        width: PropTypes.number,
        height: PropTypes.number,
    })
};
export default ChoroplethColMap;