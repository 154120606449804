import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Container, Paper, Stack, Typography, Grid } from '@mui/material';
import AppBar from '../../../../components/appbar/Appbar';
import Drawer from '../../../../components/Drawer';
import Dragndrop from '../../../../components/Dragndrop';
import { formatMessage } from '../../../../../utils/utils';
import { requestPost } from '../../../../../utils/request';
import { URL_API_INV } from '../../../../../constants';

function Upload(props) {
    const { settings, login, investigation, setSettings, setLogin, setInvestigation } = props;

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', 'Validando la información del usuario.') });
        if (investigation.hierarchy.stepHome) {
            Promise.all([
                requestPost(`${URL_API_INV}/auth/login`, { record: 'none' }),
            ])
                .then(res => {
                    if (res[0].data.access && res[0].data.infoUser.dependence === 'Extension') {
                        setLogin({ admin: { infoUser: res[0].data.infoUser } });
                        const keyDep = location.state?.key === 'hierarchy' ? false : true;
                        window.history.replaceState({}, document.title);
                        const hierarchy = {
                            ...investigation.hierarchy,
                            hierarchy: {
                                ...investigation.hierarchy.hierarchy,
                                dependence: [keyDep, 'Autenticación'],
                                hierarchy: ['Inicio de sesión', 'Extensión'],
                            },
                            params: params,
                            stepKey: true
                        };
                        setInvestigation({ hierarchy: hierarchy });
                        requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy });
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    } else if (res[0].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepHome]);

    return (
        <Box>
            <Drawer view='login' data={login.admin.infoUser} />
            <Box
                component='main'
                sx={{
                    width: { sm: `calc(100% - ${settings.openDrawer ? settings.widthDrawer : 0}px)` },
                    ml: { sm: `${settings.openDrawer ? settings.widthDrawer : 0}px` }
                }}
            >
                <AppBar hierarchy='general' params={params} value={params.upload} />
                <Container sx={{ mt: 3, mb: 3 }}>
                    <Stack direction='column' sx={{ m: 3 }} spacing={3}>
                        <Paper elevation={4}>
                            <Box sx={{ m: 3 }}>
                                <Typography variant='h2'>
                                    Módulo de cargue de archivos de extension de Horus
                                </Typography>
                                <Typography variant='body1'>
                                    Aquí podrás cargar los archivos para mantener la información de extensión actualizada
                                </Typography>
                                <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
                                    <Grid item xs={4}>
                                        <Dragndrop
                                            namefile='Proyectos'
                                            value='1'
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Dragndrop
                                            namefile='Cursos Unidad de Educacion Continua'
                                            value='2'
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Dragndrop
                                            namefile='Actividades y sub actividades'
                                            value='3'
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Stack>
                </Container>
            </Box >
        </Box >
    );
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    login: state.login,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Upload);