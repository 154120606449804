import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Autocomplete, createFilterOptions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { requestPost } from '../../../../../../utils/request';
import { formatMessage, formatDependencesAuthor, formatAutocomplete, getDifferencesForm, formatLowerCase } from '../../../../../../utils/utils';
import { URL_API_INV, constants } from '../../../../../../constants';

dayjs.locale('es');
dayjs.extend(utc);
const filter = createFilterOptions();

function Form(props) {
    const { isEdit, dependence, login, investigation, setSettings } = props;

    const navigate = useNavigate();
    const defaultPublication = login.curator.infoPublication.publication;

    const validationSchema = yup.object({
        [constants.PUBLICATION_TITLE_FIELD]: yup.string('Ingrese el titulo').required('El campo es requerido.'),
        [constants.PUBLICATION_ABSTRACT_FIELD]: yup.string('Ingrese la abstract.').required('El campo es requerido.'),
        [constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD]: yup.string('Ingrese el Revista o conferencia.').required('El campo es requerido.'),
        [constants.PUBLICATION_DATE_FIELD]: yup.string('Ingrese la fecha.').required('El campo es requerido.'),
        [constants.PUBLICATION_LANGUAGE_FIELD]: yup.string('Ingrese el idioma.').required('El campo es requerido.'),
        [constants.PUBLICATION_SOURCE_FIELD]: yup.string('Ingrese la fuente.').required('El campo es requerido.'),
        [constants.PUBLICATION_SOURCE_TYPE_FIELD]: yup.string('Ingrese el tipo de publicación.').required('El campo es requerido.')
    });

    const formik = useFormik({
        initialValues: {
            [constants.PUBLICATION_ID_FIELD]: defaultPublication[constants.PUBLICATION_ID_FIELD],
            [constants.PUBLICATION_TITLE_FIELD]: defaultPublication[constants.PUBLICATION_TITLE_FIELD],
            [constants.PUBLICATION_SPANISH_TITLE_FIELD]: defaultPublication[constants.PUBLICATION_SPANISH_TITLE_FIELD],
            [constants.PUBLICATION_ENGLISH_TITLE_FIELD]: defaultPublication[constants.PUBLICATION_ENGLISH_TITLE_FIELD],
            [constants.PUBLICATION_ABSTRACT_FIELD]: defaultPublication[constants.PUBLICATION_ABSTRACT_FIELD],
            [constants.PUBLICATION_SPANISH_ABSTRACT_FIELD]: defaultPublication[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD],
            [constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD]: defaultPublication[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD],
            [constants.PUBLICATION_PUBNAME_ORIGINAL_FIELD]: defaultPublication[constants.PUBLICATION_PUBNAME_FIELD][constants.PUBLICATION_ORIGINAL_SUBFIELD],
            [constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD]: defaultPublication[constants.PUBLICATION_PUBNAME_FIELD][constants.PUBLICATION_NORMALIZED_SUBFIELD],
            [constants.PUBLICATION_PUBNAME_SCORE_FIELD]: defaultPublication[constants.PUBLICATION_PUBNAME_FIELD][constants.PUBLICATION_SCORE_SUBFIELD],
            [constants.PUBLICATION_AFFIL_COUNTRY_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_AFFILIATION_FIELD]?.[constants.PUBLICATION_AFFIL_COUNTRY_FIELD]),
            [constants.PUBLICATION_AFFIL_CITY_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_AFFILIATION_FIELD]?.[constants.PUBLICATION_AFFIL_CITY_FIELD]),
            [constants.PUBLICATION_AFFIL_NAME_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_AFFILIATION_FIELD]?.[constants.PUBLICATION_AFFIL_NAME_FIELD], constants.PUBLICATION_NORMALIZED_SUBFIELD),
            [constants.PUBLICATION_AUTHORS_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_AUTHORS_FIELD], constants.AUTHOR_NAME_FIELD, constants.PUBLICATION_NORMALIZED_SUBFIELD),
            [constants.PUBLICATION_DOI_FIELD]: defaultPublication[constants.PUBLICATION_DOI_FIELD],
            [constants.PUBLICATION_DATE_FIELD]: dayjs.utc(defaultPublication[constants.PUBLICATION_DATE_FIELD]),
            [constants.PUBLICATION_LANGUAGE_FIELD]: defaultPublication[constants.PUBLICATION_LANGUAGE_FIELD],
            [constants.PUBLICATION_PUBLISHER_FIELD]: defaultPublication[constants.PUBLICATION_PUBLISHER_FIELD],
            [constants.PUBLICATION_THESAURUS_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_THESAURUS_FIELD]),
            [constants.PUBLICATION_SOURCE_FIELD]: defaultPublication[constants.PUBLICATION_SOURCE_FIELD],
            [constants.PUBLICATION_RANGE_START_FIELD]: defaultPublication[constants.PUBLICATION_RANGE_FIELD]?.split('-')?.[0] || 0,
            [constants.PUBLICATION_RANGE_END_FIELD]: defaultPublication[constants.PUBLICATION_RANGE_FIELD]?.split('-')?.[1] || 0,
            [constants.PUBLICATION_SOURCE_TYPE_FIELD]: defaultPublication[constants.PUBLICATION_SOURCE_TYPE_FIELD],
            [constants.PUBLICATION_VOLUME_FIELD]: defaultPublication[constants.PUBLICATION_VOLUME_FIELD],
            [constants.PUBLICATION_ISBN_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_ISBN_FIELD]),
            [constants.PUBLICATION_ISSN_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_ISSN_FIELD]),
            [constants.PUBLICATION_CITATION_FIELD]: defaultPublication[constants.PUBLICATION_CITATION_FIELD],
            [constants.PUBLICATION_URL_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_URL_FIELD]),
            [constants.PUBLICATION_SPANISH_KEYWORDS_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_SPANISH_KEYWORDS_FIELD]),
            [constants.PUBLICATION_ENGLISH_KEYWORDS_FIELD]: formatAutocomplete(defaultPublication[constants.PUBLICATION_ENGLISH_KEYWORDS_FIELD])
        },
        validationSchema: validationSchema,
        onSubmit: (data) => {
            const fieldsDiff = getDifferencesForm(defaultPublication, data);
            if (fieldsDiff.validate > 0) {
                setSettings({
                    snackbar: formatMessage(true, 'success', 'Si se ha registrado cambios. Los cambio no se han realizado, por favor contactar al soporte técnico.')
                });
                // PENDIENTE REVISAR SI LOS CAMBIOS SE REALIZAN CORRECTAMENTE, EL CAMPO DATE PONE PROBLEMAS ASIMILA CAMBIO YA QUE EL VALOR FINAL ES STRING Y EL INICIAL ES TIPO DATE
                {/* {formik.submitCount > 0 && console.log('Validation Errors:', formik.errors)} */}
                
                // setSettings({ backdrop: formatMessage(true, '', 'Procesando la información.') });
                // const hierarchy = {
                //     ...investigation.hierarchy,
                //     subResource: 'Formulario de publicación',
                //     action: {
                //         type: isEdit ? 'Editar publicación' : 'Nueva publicación',
                //         value: JSON.stringify(fieldsDiff),
                //     }
                // };
                // Promise.all([
                //     requestPost(`${URL_API_INV}/curator/update`, {
                //         infoUser: login.admin.infoUser,
                //         edit: isEdit,
                //         defaultPub: defaultPublication,
                //         dataPub: fieldsDiff.update,
                //         record: hierarchy
                //     })
                // ])
                //     .then(res => {
                //         if (res[0].data.access && res[0].data.infoUser.dependence === 'Curaduria') {
                //             if (res[0].data.error) {
                //                 setSettings({ backdrop: formatMessage(true, 'warning', res[0].data.message) });
                //             } else {
                //                 setSettings({ backdrop: formatMessage(true, 'success', res[0].data.message) });
                //                 navigate('/login/Inicio de sesión/curator/Curaduria', { state: { key: 'hierarchy' } });
                //             }
                //         }
                //         else if (res[0].data.access) {
                //             navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                //         } else {
                //             sessionStorage.removeItem('log-ide');
                //             navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                //         }
                //         setSettings({ backdrop: formatMessage(false, '', '') });
                //     })
                //     .catch(err => {
                //         setSettings({
                //             backdrop: formatMessage(false, '', ''),
                //             snackbar: formatMessage(true, 'error', err)
                //         });
                //     });
            } else {
                setSettings({
                    snackbar: formatMessage(true, 'warning', 'No se ha registrado cambios, por favor valide la información y vuelva a intentar.')
                });
            }
        },
    });

    const handleOnChange = (prop, value) => {
        formik.setErrors({});

        let lastValue = formik.values[prop];
        let valueValue = typeof value[value.length - 1] === 'string'
            ? value[value.length - 1]
            : value[value.length - 1]?.label;
        let labelValue = typeof value[value.length - 1] === 'string'
            ? value[value.length - 1]
            : value[value.length - 1]?.label;

        let defaultValue = value[value.length - 1]?.default;
        let newValue = { value: valueValue, label: labelValue, default: defaultValue, isNew: true };

        if (lastValue.length > value.length) {
            formik.setFieldValue(prop, value, true);
        } else {
            if (prop === constants.PUBLICATION_ISBN_FIELD) {
                let lengthValue = newValue.value.length;
                let prefixValue = newValue.value.substring(0, 3);
                if (lengthValue !== 13 && lengthValue !== 10) {
                    formik.setFieldError(prop, `La cantidad de caracteres del ISBN ${newValue.value} no corresponde con el estandar. La cantidad de caracteres permitida son 10 y 13, usted ha ingresado ${lengthValue}.`);
                    return true;
                } else if (lengthValue === 13 && prefixValue !== '978' && prefixValue !== '979') {
                    formik.setFieldError(prop, `El prefijo del ISBN ${newValue.value} no corresponde con el estandar. Los ISBN de 13 caracteres deben iniciar en 978 o 979, usted ha ingreado el prefijo ${newValue.value}.`);
                    return true;
                }
            }
            if (prop === constants.PUBLICATION_ISSN_FIELD) {
                let lengthValue = newValue.value.length;
                if (lengthValue !== 8) {
                    formik.setFieldError(prop, `La cantidad de caracteres del ISSN ${newValue.value} no corresponde con el estandar. La cantidad de caracteres permitida es 8, usted ha ingresado ${lengthValue}.`);
                    return true;
                }
            }
            formik.setFieldValue(prop, formik.values[prop].concat(newValue), true);
        }
    };

    const handleChange = (prop, value) => {
        formik.setErrors({});
        let newValue = value;
        if (prop === constants.PUBLICATION_RANGE_START_FIELD) {
            let oneValue = newValue === '' ? 0 : parseInt(newValue);
            let twoValue = parseInt(formik.values[constants.PUBLICATION_RANGE_END_FIELD]);
            if (oneValue > twoValue) {
                formik.setFieldError(prop, `El rango de paginas inicial no puede ser mayor al rango de paginas final. Usted ha ingresado ${oneValue} y el valor debe ser menor de ${twoValue}.`);
                return true;
            }
            if (oneValue < 0) {
                formik.setFieldError(prop, `El rango de paginas inicial no puede ser negativo. Usted ha ingresado ${oneValue} y el valor debe ser mayor o igual a 0.`);
                return true;
            }
        }
        if (prop === constants.PUBLICATION_RANGE_END_FIELD) {
            let oneValue = parseInt(formik.values[constants.PUBLICATION_RANGE_START_FIELD]);
            let twoValue = newValue === '' ? 0 : parseInt(newValue);
            if (oneValue > twoValue) {
                formik.setFieldError(prop, `El rango de paginas final no puede ser menor al rango de paginas inicial. Usted ha ingresado ${twoValue} y el valor debe ser mayor de ${oneValue}.`);
                return true;
            }
            if (twoValue < 0) {
                formik.setFieldError(prop, `El rango de paginas final no puede ser negativo. Usted ha ingresado ${twoValue} y el valor debe ser mayor o igual a 0.`);
                return true;
            }
        }
        if (prop === constants.PUBLICATION_CITATION_FIELD) {
            let oneValue = parseInt(newValue);
            if (oneValue < 0) {
                formik.setFieldError(prop, `El número de citaciones no puede ser negativo. Usted ha ingresado ${oneValue} y el valor debe ser mayor o igual a 0.`);
                return true;
            }
        }
        formik.setFieldValue(prop, newValue === '' ? 0 : parseInt(newValue), true);
    };

    useEffect(() => {
        if (investigation.hierarchy.stepSubKey) {
            const hierarchy = {
                ...investigation.hierarchy,
                subResource: 'Formulario de publicación',
                action: {
                    type: isEdit ? 'Formulario para editar publicación' : 'Formulario para nueva publicación',
                    value: 'Visualización del formulario de publicación',
                }
            };
            requestPost(`${URL_API_INV}/usability/subresource`, { record: hierarchy });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepSubKey]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_TITLE_FIELD}
                        name={constants.PUBLICATION_TITLE_FIELD}
                        label='Titulo'
                        value={formik.values[constants.PUBLICATION_TITLE_FIELD]}
                        onChange={formik.handleChange}
                        error={formik.touched[constants.PUBLICATION_TITLE_FIELD] && Boolean(formik.errors[constants.PUBLICATION_TITLE_FIELD])}
                        helperText={formik.touched[constants.PUBLICATION_TITLE_FIELD] && formik.errors[constants.PUBLICATION_TITLE_FIELD]}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_SPANISH_TITLE_FIELD}
                        label='Titulo en español'
                        value={formik.values[constants.PUBLICATION_SPANISH_TITLE_FIELD]}
                        onChange={formik.handleChange}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_ENGLISH_TITLE_FIELD}
                        label='Titulo en ingles'
                        value={formik.values[constants.PUBLICATION_ENGLISH_TITLE_FIELD]}
                        onChange={formik.handleChange}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_ABSTRACT_FIELD}
                        label='Abstract'
                        value={formik.values[constants.PUBLICATION_ABSTRACT_FIELD]}
                        onChange={formik.handleChange}
                        error={formik.touched[constants.PUBLICATION_ABSTRACT_FIELD] && Boolean(formik.errors[constants.PUBLICATION_ABSTRACT_FIELD])}
                        helperText={formik.touched[constants.PUBLICATION_ABSTRACT_FIELD] && formik.errors[constants.PUBLICATION_ABSTRACT_FIELD]}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_SPANISH_ABSTRACT_FIELD}
                        label='Abstract en español'
                        value={formik.values[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD]}
                        onChange={formik.handleChange}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD}
                        label='Abstract en ingles'
                        value={formik.values[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD]}
                        onChange={formik.handleChange}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_PUBNAME_ORIGINAL_FIELD}
                        label='Revista o conferencia original'
                        value={formik.values[constants.PUBLICATION_PUBNAME_ORIGINAL_FIELD]}
                        onChange={formik.handleChange}
                        disabled={isEdit}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD}
                        label='Revista o conferencia normalizado'
                        value={formik.values[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD]}
                        onChange={formik.handleChange}
                        error={formik.touched[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD] && Boolean(formik.errors[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD])}
                        helperText={formik.touched[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD] && formik.errors[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD]}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_PUBNAME_SCORE_FIELD}
                        label='Revista o conferencia puntaje'
                        value={formik.values[constants.PUBLICATION_PUBNAME_SCORE_FIELD]}
                        onChange={formik.handleChange}
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id={constants.PUBLICATION_AFFIL_COUNTRY_FIELD}
                        options={constants.CURATOR_COUNTRIES}
                        value={formik.values[constants.PUBLICATION_AFFIL_COUNTRY_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_AFFIL_COUNTRY_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Países de afiliación'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id={constants.PUBLICATION_AFFIL_CITY_FIELD}
                        options={constants.CURATOR_CITIES}
                        value={formik.values[constants.PUBLICATION_AFFIL_CITY_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_AFFIL_CITY_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Ciudades de afiliación'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id={constants.PUBLICATION_AFFIL_NAME_FIELD}
                        options={constants.CURATOR_UNIVERSITIES}
                        value={formik.values[constants.PUBLICATION_AFFIL_NAME_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_AFFIL_NAME_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Universidades de afiliación'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Autocomplete
                        id={constants.PUBLICATION_AUTHORS_FIELD}
                        options={formatDependencesAuthor(dependence)}
                        groupBy={(option) => option.group}
                        value={formik.values[constants.PUBLICATION_AUTHORS_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_AUTHORS_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Autores'
                                error={formik.touched[constants.PUBLICATION_AUTHORS_FIELD] && Boolean(formik.errors[constants.PUBLICATION_AUTHORS_FIELD])}
                                helperText={formik.touched[constants.PUBLICATION_AUTHORS_FIELD] && formik.errors[constants.PUBLICATION_AUTHORS_FIELD]}
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_DOI_FIELD}
                        label='Doi'
                        value={formik.values[constants.PUBLICATION_DOI_FIELD]}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DatePicker
                        id={constants.PUBLICATION_DATE_FIELD}
                        label='Fecha'
                        value={formik.values[constants.PUBLICATION_DATE_FIELD]}
                        onChange={(value) => formik.setFieldValue(constants.PUBLICATION_DATE_FIELD, value, true)}
                        slotProps={{
                            textField: {
                                error: formik.touched[constants.PUBLICATION_DATE_FIELD] && Boolean(formik.errors[constants.PUBLICATION_DATE_FIELD]),
                                helperText: formik.touched[constants.PUBLICATION_DATE_FIELD] && formik.errors[constants.PUBLICATION_DATE_FIELD]
                            },
                        }}
                        // renderInput={(params) => (
                        //     <TextField
                        //         {...params}
                        //         fullWidth
                        //         error={formik.touched[constants.PUBLICATION_DATE_FIELD] && Boolean(formik.errors[constants.PUBLICATION_DATE_FIELD])}
                        //         helperText={formik.touched[constants.PUBLICATION_DATE_FIELD] && formik.errors[constants.PUBLICATION_DATE_FIELD]}
                        //     />
                        // )}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id={constants.PUBLICATION_LANGUAGE_FIELD}>
                            Idioma
                        </InputLabel>
                        <Select
                            labelId={constants.PUBLICATION_LANGUAGE_FIELD}
                            id={constants.PUBLICATION_LANGUAGE_FIELD}
                            label='Idioma'
                            value={formik.values[constants.PUBLICATION_LANGUAGE_FIELD]}
                            onChange={(value) => formik.setFieldValue(constants.PUBLICATION_LANGUAGE_FIELD, value.target.value, true)}
                            error={formik.touched[constants.PUBLICATION_LANGUAGE_FIELD] && Boolean(formik.errors[constants.PUBLICATION_LANGUAGE_FIELD])}
                            // helperText={formik.touched[constants.PUBLICATION_LANGUAGE_FIELD] && formik.errors[constants.PUBLICATION_LANGUAGE_FIELD]}
                            autoWidth
                        >
                            {constants.CURATOR_LANGUAGES.map((item, key) => {
                                return (
                                    <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_PUBLISHER_FIELD}
                        label='Editor'
                        value={formik.values[constants.PUBLICATION_PUBLISHER_FIELD]}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Autocomplete
                        id={constants.PUBLICATION_THESAURUS_FIELD}
                        options={[]}
                        value={formik.values[constants.PUBLICATION_THESAURUS_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_THESAURUS_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Thesaurus'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_SOURCE_FIELD}
                        label='Fuente'
                        value={formik.values[constants.PUBLICATION_SOURCE_FIELD]}
                        onChange={formik.handleChange}
                        error={formik.touched[constants.PUBLICATION_SOURCE_FIELD] && Boolean(formik.errors[constants.PUBLICATION_SOURCE_FIELD])}
                        helperText={formik.touched[constants.PUBLICATION_SOURCE_FIELD] && formik.errors[constants.PUBLICATION_SOURCE_FIELD]}
                        disabled={isEdit}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_RANGE_START_FIELD}
                        label='Rango de pagina inicial'
                        value={formik.values[constants.PUBLICATION_RANGE_START_FIELD]}
                        onChange={(event) => handleChange(constants.PUBLICATION_RANGE_START_FIELD, event.target.value)}
                        type='number'
                        error={Boolean(formik.errors[constants.PUBLICATION_RANGE_START_FIELD])}
                        helperText={formik.errors[constants.PUBLICATION_RANGE_START_FIELD]}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_RANGE_END_FIELD}
                        label='Rango de pagina final'
                        value={formik.values[constants.PUBLICATION_RANGE_END_FIELD]}
                        onChange={(event) => handleChange(constants.PUBLICATION_RANGE_END_FIELD, event.target.value)}
                        type='number'
                        error={Boolean(formik.errors[constants.PUBLICATION_RANGE_END_FIELD])}
                        helperText={formik.errors[constants.PUBLICATION_RANGE_END_FIELD]}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_SOURCE_TYPE_FIELD}
                        label='Tipo de publicación'
                        value={formik.values[constants.PUBLICATION_SOURCE_TYPE_FIELD]}
                        onChange={formik.handleChange}
                        error={formik.touched[constants.PUBLICATION_SOURCE_TYPE_FIELD] && Boolean(formik.errors[constants.PUBLICATION_SOURCE_TYPE_FIELD])}
                        helperText={formik.touched[constants.PUBLICATION_SOURCE_TYPE_FIELD] && formik.errors[constants.PUBLICATION_SOURCE_TYPE_FIELD]}
                        disabled={isEdit}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_VOLUME_FIELD}
                        label='Volumen'
                        value={formik.values[constants.PUBLICATION_VOLUME_FIELD]}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id={constants.PUBLICATION_ISBN_FIELD}
                        options={[]}
                        value={formik.values[constants.PUBLICATION_ISBN_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_ISBN_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='ISBN'
                                error={Boolean(formik.errors[constants.PUBLICATION_ISBN_FIELD])}
                                helperText={formik.errors[constants.PUBLICATION_ISBN_FIELD]}
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        disabled={
                            formik.values[constants.PUBLICATION_SOURCE_TYPE_FIELD] === 'Capítulo de libro' ||
                            formik.values[constants.PUBLICATION_SOURCE_TYPE_FIELD] === 'Libro' ||
                            formik.values[constants.PUBLICATION_SOURCE_TYPE_FIELD] === 'Artículo de conferencia'
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id={constants.PUBLICATION_ISSN_FIELD}
                        options={[]}
                        value={formik.values[constants.PUBLICATION_ISSN_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_ISSN_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='ISSN'
                                error={Boolean(formik.errors[constants.PUBLICATION_ISSN_FIELD])}
                                helperText={formik.errors[constants.PUBLICATION_ISSN_FIELD]}
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_CITATION_FIELD}
                        label='Citaciones'
                        value={formik.values[constants.PUBLICATION_CITATION_FIELD]}
                        onChange={(event) => handleChange(constants.PUBLICATION_CITATION_FIELD, event.target.value)}
                        type='number'
                        error={Boolean(formik.errors[constants.PUBLICATION_CITATION_FIELD])}
                        helperText={formik.errors[constants.PUBLICATION_CITATION_FIELD]}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id={constants.PUBLICATION_ID_FIELD}
                        label='_id'
                        value={formik.values[constants.PUBLICATION_ID_FIELD]}
                        onChange={formik.handleChange}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id={constants.PUBLICATION_URL_FIELD}
                        options={[]}
                        value={formik.values[constants.PUBLICATION_URL_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_URL_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Url'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        id={constants.PUBLICATION_SPANISH_KEYWORDS_FIELD}
                        options={[]}
                        value={formik.values[constants.PUBLICATION_SPANISH_KEYWORDS_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_SPANISH_KEYWORDS_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Palabras clave en español'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        id={constants.PUBLICATION_ENGLISH_KEYWORDS_FIELD}
                        options={[]}
                        value={formik.values[constants.PUBLICATION_ENGLISH_KEYWORDS_FIELD]}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length === 0) {
                                filtered.push({ value: params.inputValue, label: params.inputValue });
                            }
                            return filtered;
                        }}
                        onChange={(event, value) => handleOnChange(constants.PUBLICATION_ENGLISH_KEYWORDS_FIELD, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Palabras clave en ingles'
                            />
                        )}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        disabled={true}
                    />
                </Grid>
            </Grid>
            <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 3 }}>
                Enviar
            </Button>
        </form>
    );
}
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    login: state.login,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Form);