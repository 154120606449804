import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tree } from 'react-arborist';
import { Box, Avatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandMore, ChevronRight, Person3TwoTone, PersonOutlineTwoTone } from '@mui/icons-material';
import { formatDependencesName, formatNumber } from '../../../utils/utils';
import { constants } from '../../../constants';

function TreeSearch(props) {
    const { height, dataTree, params, search, handleClick, settings } = props;

    const key = Object.keys(params)[Object.keys(params).length - 2];
    const selected = params[key];

    function Node(props) {
        const { node, style } = props;
        const { data, isOpen } = node;
        const isLeaf = data.children.length > 0;
        if (data.info.level === 'DEPENDENCE_INSTITUTION_FIELD' && node.isClosed) node.toggle();
        return (
            <ListItemButton
                key={data.id}
                style={{
                    color: 'black',
                    paddingLeft: data.info.level === 'DEPENDENCE_AUTHOR_FIELD' ? style.paddingLeft + 40 : style.paddingLeft + 10,
                    height: 38
                }}
                selected={selected === data.name}
            >
                {isLeaf && (
                    <Box
                        onClick={() => node.toggle()}
                        component={isOpen ? ExpandMore : ChevronRight}
                        color='text.secondary'
                        sx={{ mr: 1 }}
                    />
                )}
                <ListItemIcon sx={{ minWidth: 35 }}>
                    {data.info.level === 'DEPENDENCE_AUTHOR_FIELD' ? (
                        <Avatar
                            onClick={() => handleClick(data)}
                            sx={{
                                width: 30,
                                height: 30,
                                bgcolor: theme => theme.palette[data.info[constants.AUTHOR_GENDER_FIELD] === 'F'
                                    ? 'female'
                                    : data.info[constants.AUTHOR_GENDER_FIELD] === 'M'
                                        ? 'male'
                                        : 'info'].main,
                            }}
                        >
                            {data.info[constants.AUTHOR_IMAGE_FIELD] ? (
                                <Box
                                    component='img'
                                    width='100%'
                                    height='100%'
                                    src={
                                        data.info[constants.AUTHOR_IMAGE_FIELD].includes('http')
                                            ? data.info[constants.AUTHOR_IMAGE_FIELD]
                                            : require('../../../assets/' + data.info[constants.AUTHOR_IMAGE_FIELD])
                                    }
                                />
                            ) : (
                                <Box
                                    component={
                                        data.info[constants.AUTHOR_GENDER_FIELD] === 'F'
                                            ? Person3TwoTone
                                            : PersonOutlineTwoTone
                                    }
                                />
                            )}
                        </Avatar>
                    ) : (
                        <Box
                            onClick={() => handleClick(data)}
                            component={constants.DEPENDENCE_INFO_FIELD[data.info.level].icon} color='text.secondary'
                        />
                    )}
                </ListItemIcon>
                <ListItemText
                    onClick={() => handleClick(data)}
                    primary={formatDependencesName(data.name)}
                />
                {isLeaf && (
                    <Typography
                        variant='body3'
                        color='text.secondary'
                        sx={{ pr: 1 }}
                    >
                        {formatNumber(data.children.length)} {constants.DEPENDENCE_INFO_FIELD[data.info.level]['infoLevel_' + settings.language][data.children.length === 1 ? 0 : 1]}
                    </Typography>
                )}
            </ListItemButton>
        );
    };

    return (
        <Tree
            data={dataTree}
            indent={24}
            width='100%'
            height={height}
            rowHeight={38}
            searchTerm={search === 'Universidad Nacional de Colombia' ? '' : search}
            searchMatch={
                (node, term) => {
                    const value = term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' ').filter(item => item !== '');
                    const valueComparison = node.data.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    if (value.map(itemSearch => valueComparison.indexOf(itemSearch) > -1).reduce((a, b) => a && b)) {
                        node.data.search = true;
                        return true;
                    } else {
                        node.data.search = false;
                        if (node.parent.data.search) {
                            node.data.search = true;
                            // if (node.isOpen) {
                            //     node.toggle();
                            // }
                            return true;
                        }
                    }
                }
            }
            openByDefault={false}
        >
            {Node}
        </Tree>
    );
}
TreeSearch.propTypes = {
    height: PropTypes.number,
    dataTree: PropTypes.array,
    params: PropTypes.object,
    search: PropTypes.string,
    handleClick: PropTypes.func
};
const mapStateToProps = (state) => ({
    settings: state.settings
});
export default connect(mapStateToProps)(TreeSearch);