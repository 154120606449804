import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { formatMessage } from '../../utils/utils';

const Alert = forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            {...props}
        />
    );
});
function SnackbarCustom(props) {
    const { open, severity, message, setSettings } = props;
    const [openComponent, setOpenComponent] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenComponent(false);
        setSettings({ snackbar: formatMessage(false, 'info', '') })
    };
    useEffect(() => {
        if (open) {
            setOpenComponent(true);
        }
    }, [open]);
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                open={openComponent}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
                sx={{ zIndex: 300 }}
                autoHideDuration={4000}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
SnackbarCustom.propTypes = {
    open: PropTypes.bool,
    severity: PropTypes.string,
    message: PropTypes.string,
};
SnackbarCustom.defaultProps = {
    open: false,
    severity: '',
    message: '',
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(SnackbarCustom);