import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Grid, Autocomplete, IconButton, Avatar } from '@mui/material';
import { FilterAltOffTwoTone, Fullscreen, FullscreenExit } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import CardEmpty from '../card/CardEmpty';
import { ForceGraph2D } from 'react-force-graph';
import { configGraph2D } from '../../../assets/theme/g2Theme';

function Graph2D(props) {
    const { infoGraph } = props;
    const ref = useRef(null);
    const { t } = useTranslation();
    const optionsType = [
        { label: t('components.graphics.graph2D.labelRadial'), value: 'radialout' },
        { label: t('components.graphics.graph2D.labelTree'), value: 'td' },
        { label: t('components.graphics.graph2D.labelRandom'), value: null }
    ];
    const [values, setValues] = useState({
        type: optionsType[0],
        search: null,
        searchLinks: [],
        searchNodes: [],
        fullScreen: false
    });
    const color = {
        institution: '#FA1800',
        campus: '#9601FB',
        faculty: '#ff6600',
        uab: '#54D62C',
        author: '#1890FF',
        topic: '#CC2F69'
    };
    const handleType = (event, value) => {
        setValues({ ...values, type: value });
    };
    const handleSearch = (event, value) => {
        let searchNodes = [];
        let searchLinks = [];
        const distance = value.label === 'Estado inicial' ? 0.3 : 0.8;
        const duration = 0;
        const node = infoGraph.data.nodes.find(node => node.id === value.value);
        if (node) {
            ref.current.centerAt(node.x, node.y, duration);
            ref.current.zoom(distance, duration);
        }
        if (value.label !== 'Estado inicial') {
            searchLinks = infoGraph.data.links
                .filter((link) => {
                    const isMatch = link.source.id === value.value || link.target.id === value.value;
                    if (isMatch) {
                        searchNodes.push(link.source.index);
                        searchNodes.push(link.target.index);
                    }
                    return isMatch;
                })
                .map((link) => link.index);
        }
        setValues({
            ...values,
            search: value,
            searchLinks: searchLinks,
            searchNodes: [...new Set(searchNodes)]
        });
    };
    const nodeCanvasObject = useCallback((node, ctx) => {
        const inSearchNodes = values.searchNodes.includes(node.index);
        const inSearch = node.id === values.search?.value;
        ctx.beginPath();
        ctx.arc(node.x, node.y, (inSearchNodes ? (inSearch ? 8 : 6) * 1.4 : 6), 0, 2 * Math.PI, false);
        ctx.fillStyle = color[node.type];
        ctx.fill();
        ctx.strokeStyle = inSearchNodes ? '#ffcc00' : '#FFFFFF';
        ctx.lineWidth = inSearchNodes ? (inSearch ? 4 : 2) : 1.5;
        ctx.stroke();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.searchNodes]);
    const linkCanvasObject = useCallback((link, ctx, globalScale) => {
        const inSearchLinks = values.searchLinks.includes(link.index);
        const inSearch = values.searchLinks.length !== 0;
        ctx.strokeStyle = inSearchLinks ? 'rgba(0, 0, 0, 0.8)' : (inSearch ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.4)');
        ctx.lineWidth = (inSearchLinks ? 1.8 : 1) / globalScale;
        ctx.beginPath();
        ctx.moveTo(link.source.x, link.source.y);
        ctx.lineTo(link.target.x, link.target.y);
        ctx.stroke();
    }, [values.searchLinks]);
    const handleFullScreen = () => {
        const graphContainer = document.getElementById('graph-container');
        if (graphContainer && !values.fullScreen) {
            setValues({ ...values, fullScreen: true });
            graphContainer.style.position = 'fixed';
            graphContainer.style.top = '0';
            graphContainer.style.left = '0';
            graphContainer.style.width = '100%';
            graphContainer.style.height = '100%';
            graphContainer.style.zIndex = '1100';
        } else {
            setValues({ ...values, fullScreen: false });
            graphContainer.style.position = '';
            graphContainer.style.top = '';
            graphContainer.style.left = '';
            graphContainer.style.width = '';
            graphContainer.style.height = '';
            graphContainer.style.zIndex = '';
        }
    };
    return (
        <Box id='graph-container' sx={{ bgcolor: 'white', p: 2 }}>
            <Grid container spacing={3} sx={{ mb: 1 }}>
                <Grid item xs={12} md={5.4}>
                    <Tooltip title={t('components.graphics.graph2D.typeTooltip')}>
                        <Autocomplete
                            disableClearable
                            onChange={handleType}
                            value={values.type}
                            options={optionsType}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph2D.type')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={5.4}>
                    <Tooltip title={t('components.graphics.graph2D.searchTooltip')}>
                        <Autocomplete
                            disableClearable
                            onChange={handleSearch}
                            value={values.search}
                            options={infoGraph.data.options || []}
                            groupBy={(option) => option.group}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph2D.search')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={0.5}>
                    <Tooltip title={t('components.graphics.graph2D.defaultGraph')}>
                        <IconButton onClick={() => handleSearch(null, infoGraph.data.options[0])}>
                            <Avatar
                                sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: theme => theme.palette.lighterBlue.main,
                                    fontSize: 16,
                                }}
                            >
                                <FilterAltOffTwoTone />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={0.5}>
                    <Tooltip title={t('components.graphics.graph2D.screenAll')}>
                        <IconButton onClick={handleFullScreen} >
                            <Avatar
                                sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: theme => theme.palette.lighterBlue.main,
                                    fontSize: 16,
                                }}
                            >
                                {values.fullScreen ? <FullscreenExit /> : <Fullscreen />}
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {infoGraph.data.nodes.length === 0 ? (
                <CardEmpty />
            ) : (
                <ForceGraph2D
                    ref={ref}
                    width={values.fullScreen ? window.innerWidth - 50 : window.innerWidth - 180}
                    height={values.fullScreen ? window.innerHeight - 100 : 500}
                    graphData={infoGraph.data}
                    backgroundColor={configGraph2D.backgroundColor}
                    nodeLabel={node => node.label}
                    dagMode={values.type.value}
                    dagLevelDistance={200}
                    nodeCanvasObject={nodeCanvasObject}
                    linkCanvasObject={linkCanvasObject}
                />
            )}
        </Box>
    );
}
Graph2D.propTypes = {
    colorB: PropTypes.string,
    infoGraph: PropTypes.shape({
        data: PropTypes.object
    })
};
export default Graph2D;