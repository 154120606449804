import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Grid, Paper, Typography, IconButton, CardActions, CardContent, InputAdornment, TextField, Button } from '@mui/material';
import { VisibilityTwoTone, VisibilityOffTwoTone } from '@mui/icons-material';
import { requestPost } from '../../../../utils/request';
import { formatLowerCase, formatMessage } from '../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../constants';
import appbar from '../../../../assets/images/horus/appbar.png';

function Login(props) {
    const { investigation, settings, setSettings, setLogin, setInvestigation } = props;
    const [values, setValues] = useState({
        showPassword: false
    });
    
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const token = sessionStorage.getItem('log-ide');
    const keyDep = location.state?.key === 'hierarchy' ? false : true;
    window.history.replaceState({}, document.title);
    const defaultHierarchy = {
        ...investigation.hierarchy,
        hierarchy: {
            ...investigation.hierarchy.hierarchy,
            dependence: [keyDep, 'Autenticación'],
            hierarchy: ['Inicio de sesión', 'Formulario inicio de sesión'],
        },
        params: params,
        stepLogin: true,
        stepKey: false,
    };

    const validationSchema = yup.object({
        [constants.LOGIN_USER_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_USER_FIELD.name))
            .required('El campo es requerido.'),
        [constants.LOGIN_PASS_FIELD.field]: yup
            .string('Ingrese la ' + formatLowerCase(constants.LOGIN_PASS_FIELD.name))
            .required('El campo es requerido.'),
    });

    const formik = useFormik({
        initialValues: {
            [constants.LOGIN_USER_FIELD.field]: '',
            [constants.LOGIN_PASS_FIELD.field]: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSettings({ backdrop: formatMessage(true, '', 'Validando la información.') });
            const hierarchy = {
                ...defaultHierarchy,
                resource: 'Validación de credenciales'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/auth/login`, {
                    username: values.user,
                    password: values.password,
                    record: hierarchy
                })
            ])
                .then(res => {
                    if (res[0].data.access) {
                        sessionStorage.setItem('log-ide', res[0].data.token);
                        setLogin({ admin: { infoUser: res[0].data.infoUser } });
                        if (res[0].data.infoUser.dependence === 'Administrador') {
                            navigate('/login/Inicio de sesión/panel/Panel de control', { state: { key: 'hierarchy' } });
                        } else if (res[0].data.infoUser.dependence === 'Curaduria') {
                            navigate('/login/Inicio de sesión/curator/Curaduria', { state: { key: 'hierarchy' } });
                        } else if (res[0].data.infoUser.dependence === 'Extension') {
                            navigate('/login/Inicio de sesión/upload/Panel de control', { state: { key: 'hierarchy' } });
                        }
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    } else {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'warning', res[0].data.message)
                        })
                    }
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        },
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    useEffect(() => {
        if (investigation.hierarchy.stepHome) {
            const hierarchy = defaultHierarchy;
            setInvestigation({ hierarchy: hierarchy });
            requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy });
            if (token) {
                const hierarchy = {
                    ...defaultHierarchy,
                    resource: 'Validación de token'
                };
                setSettings({ backdrop: formatMessage(true, '', 'Validando sesión.') });
                Promise.all([
                    requestPost(`${URL_API_INV}/auth/login`, { record: hierarchy })
                ])
                    .then(res => {
                        if (res[0].data.access) {
                            setLogin({ admin: { infoUser: res[0].data.infoUser } });
                            if (res[0].data.infoUser.dependence === 'Administrador') {
                                navigate('/login/Inicio de sesión/panel/Panel de control', { state: { key: 'hierarchy' } });
                            } else if (res[0].data.infoUser.dependence === 'Curaduria') {
                                navigate('/login/Inicio de sesión/curator/Curaduria', { state: { key: 'hierarchy' } });
                            } else if (res[0].data.infoUser.dependence === 'Extension') {
                                navigate('/login/Inicio de sesión/upload/Panel de control', { state: { key: 'hierarchy' } });
                            }
                        } else {
                            sessionStorage.removeItem('log-ide');
                        }
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepHome]);
    
    return (
        <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{
                backgroundImage: `url(${appbar})`,
                height: 'fit-content',
                minHeight: '86vh',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '100% 36%',
                mt: `-${settings.heightTop}px`,
            }}
        >
            <Grid item sm={12} md={6} lg={4}>
                <Paper elevation={3} sx={{ p: 2, m: 3, mt: `${settings.heightTop}px` }}>
                    <CardContent>
                        <Typography
                            variant='h3'
                            component='div'
                            sx={{ textAlign: 'center', mb: 4 }}
                        >
                            {params.login}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                            Utilice su cuenta institucional sin <b>@unal.edu.co</b> para iniciar sesión.
                        </Typography>
                        <form onSubmit={formik.handleSubmit} id='form-login'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id={constants.LOGIN_USER_FIELD.field}
                                        name={constants.LOGIN_USER_FIELD.field}
                                        label={constants.LOGIN_USER_FIELD.label}
                                        value={formik.values[constants.LOGIN_USER_FIELD.field]}
                                        onChange={formik.handleChange}
                                        error={formik.touched[constants.LOGIN_USER_FIELD.field] && Boolean(formik.errors[constants.LOGIN_USER_FIELD.field])}
                                        helperText={formik.touched[constants.LOGIN_USER_FIELD.field] && formik.errors[constants.LOGIN_USER_FIELD.field]}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id={constants.LOGIN_PASS_FIELD.field}
                                        name={constants.LOGIN_PASS_FIELD.field}
                                        label={constants.LOGIN_PASS_FIELD.label}
                                        value={formik.values[constants.LOGIN_PASS_FIELD.field]}
                                        onChange={formik.handleChange}
                                        error={formik.touched[constants.LOGIN_PASS_FIELD.field] && Boolean(formik.errors[constants.LOGIN_PASS_FIELD.field])}
                                        helperText={formik.touched[constants.LOGIN_PASS_FIELD.field] && formik.errors[constants.LOGIN_PASS_FIELD.field]}
                                        variant='filled'
                                        type={values.showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge='end'
                                                    >
                                                        {values.showPassword ? <VisibilityOffTwoTone /> : <VisibilityTwoTone />}
                                                    </IconButton>
                                                </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 3 }} form='form-login'>
                            Ingresar
                        </Button>
                    </CardActions>
                </Paper>
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);