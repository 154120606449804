import React from 'react';
import PropTypes from 'prop-types';
import { Stack, useMediaQuery , Box} from '@mui/material';
import Widget from './Widget';

function Widgets(props) {
    const { isAuthor, widgets, stepTab, handleStepTab, isGroup } = props;
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('md'));
    if ((!widgets || widgets.length < 5) && isGroup) {
        return (
            <Box sx={{ height: 220 }}>
                <Stack direction='column' spacing={1.5} sx={{ height: '100%' }}>
                    {widgets.slice(0, 2).map((widget, key) => (
                        <Box
                            key={key}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                height: '100%',
                            }}
                        >
                            <Widget
                                title={widget.title}
                                total={widget.total}
                                color={widget.color}
                                filter={widget.filter}
                                pie={widget.pie}
                                icon={widget.icon}
                                subtitle={widget.subtitle}
                                sub_total={widget.sub_total}
                                step={widget.step}
                                stepTab={stepTab}
                                handleStepTab={(stepTab) => handleStepTab(stepTab)}
                                sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}
                                isGroup={true}
                            />
                        </Box>
                    ))}
                </Stack>
            </Box>
        );
    }
    return (
        <Stack direction={isSm ?  'column' : 'row'} spacing={ 1.5}>
            {isSm ? (
                <>
                    <Widget
                        title={widgets[0].title}
                        total={widgets[0].total}
                        color={widgets[0].color}
                        filter={widgets[0].filter}
                        pie={widgets[0].pie}
                        icon={widgets[0].icon}
                        subtitle={widgets[0].subtitle}
                        sub_total={widgets[0].sub_total}
                        step={widgets[0].step}
                        stepTab={stepTab}
                        handleStepTab={(stepTab) => handleStepTab(stepTab)}
                        isAuthor={true}
                    />
                    <Stack direction='row' spacing={1}>
                        {widgets.slice(1, 3).map((widget, key) => (
                            <Widget
                                key={key}
                                title={widget.title}
                                total={widget.total}
                                color={widget.color}
                                filter={widget.filter}
                                pie={widget.pie}
                                icon={widget.icon}
                                subtitle={widget.subtitle}
                                sub_total={widget.sub_total}
                                step={widget.step}
                                stepTab={stepTab}
                                handleStepTab={(stepTab) => handleStepTab(stepTab)}
                                isAuthor={true}
                            />
                        ))}
                    </Stack>
                    <Stack direction='row' spacing={1}>
                        {widgets.slice(3, 5).map((widget, key) => (
                            <Widget
                                key={key}
                                title={widget.title}
                                total={widget.total}
                                color={widget.color}
                                filter={widget.filter}
                                pie={widget.pie}
                                icon={widget.icon}
                                subtitle={widget.subtitle}
                                sub_total={widget.sub_total}
                                step={widget.step}
                                stepTab={stepTab}
                                handleStepTab={(stepTab) => handleStepTab(stepTab)}
                                isAuthor={true}
                            />
                        ))}
                    </Stack>
                </>
            ): isAuthor ? (
                <>
                        <Box sx={{ height: 300, width: '100%' }}> 
                            <Stack direction='column' spacing={1} sx={{ height: '100%', width:'100%' }}>
                                {[widgets[0], widgets[4]].map((widget, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            flexGrow: index === 0 ? 2 : 1,
                                            flexShrink: 0,
                                            flexBasis: index === 0 ? '66.67%' : '33.33%',
                                            display: 'flex',
                                            minHeight: 0 
                                        }}
                                    >
                                        <Widget
                                            title={widget.title}
                                            total={widget.total}
                                            color={widget.color}
                                            filter={widget.filter}
                                            pie={widget.pie}
                                            icon={widget.icon}
                                            subtitle={widget.subtitle}
                                            sub_total={widget.sub_total}
                                            step={widget.step}
                                            stepTab={stepTab}
                                            handleStepTab={(stepTab) => handleStepTab(stepTab)}
                                            isAuthor={true}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                        <Box sx={{ height: 300, width: '100%' }}> 
                        <Stack direction='column' spacing={1} sx={{ height: '100%', width:'100%' }}>
                            {widgets.slice(1, 4).map((widget, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        flexGrow:  1,
                                        flexShrink: 0,
                                        display: 'flex',
                                        flexBasis: 0,
                                        minHeight: 0 
                                    }}
                                >
                                    <Widget
                                        title={widget.title}
                                        total={widget.total}
                                        color={widget.color}
                                        filter={widget.filter}
                                        pie={widget.pie}
                                        icon={widget.icon}
                                        subtitle={widget.subtitle}
                                        sub_total={widget.sub_total}
                                        step={widget.step}
                                        stepTab={stepTab}
                                        handleStepTab={(stepTab) => handleStepTab(stepTab)}
                                        isAuthor={true}
                                    />
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                </>
            ) : (
                <>
                    {widgets.map((widget, key) => (
                        <Widget
                            key={key}
                            title={widget.title}
                            total={widget.total}
                            color={widget.color}
                            filter={widget.filter}
                            pie={widget.pie}
                            icon={widget.icon}
                            subtitle={widget.subtitle}
                            sub_total={widget.sub_total}
                            step={widget.step}
                            stepTab={stepTab}
                            handleStepTab={(stepTab) => handleStepTab(stepTab)}
                        />
                    ))}
                </>
            )}
        </Stack>
    );
}
Widgets.propTypes = {
    isAuthor: PropTypes.bool,
    widgets: PropTypes.array,
    stepTab: PropTypes.string,
    handleStepTab: PropTypes.func
};
Widgets.defaultProps = {
    isAuthor: false,
    widgets: [],
    stepTab: 0
};
export default Widgets;