import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, SpeedDial } from '@mui/material';
import { Cached } from '@mui/icons-material';
import TreeVirtualized from '../../../../../components/tree/TreeVirtualized';
import CardGraphics from '../../../../../components/card/CardGraphics';
import Tooltip from '../../../../../components/Tooltip';
import { formatMessage, formatTFHUsability } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { URL_API_INV } from '../../../../../../constants';

function ReportTree(props) {
    const { login, setLogin, setSettings } = props;
    const navigate = useNavigate();
    const handleOnChange = (value, state) => {
        setLogin({
            usability: {
                reportTree: {
                    ...login.usability.reportTree,
                    [state]: value,
                },
            }
        });
    }
    const handleRequest = () => {
        setSettings({ backdrop: formatMessage(true, '', 'Consultando información de trazabilidad.') });
        Promise.all([
            requestPost(`${URL_API_INV}/usability/report/tree`, {}),
        ])
            .then(res => {
                if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                    setLogin({
                        usability: {
                            reportTree: {
                                treeInit: {
                                    ...login.usability.reportTree.treeInit,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeInit)
                                },
                                treeDependence: {
                                    ...login.usability.reportTree.treeDependence,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeDependence)
                                },
                                treeHierarchy: {
                                    ...login.usability.reportTree.treeHierarchy,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeHierarchy)
                                },
                                treeHierarchyType: {
                                    ...login.usability.reportTree.treeHierarchyType,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeHierarchyType)
                                },
                                treeHierarchySearch: {
                                    ...login.usability.reportTree.treeHierarchySearch,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeHierarchySearch)
                                },
                                treeResource: {
                                    ...login.usability.reportTree.treeResource,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeResource)
                                },
                                treeSubResource: {
                                    ...login.usability.reportTree.treeSubResource,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeSubResource)
                                },
                                treeAction: {
                                    ...login.usability.reportTree.treeAction,
                                    processed: formatTFHUsability(res[0].data.infoUsability.treeAction)
                                },
                            }
                        }
                    });
                } else if (res[0].data.access) {
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                } else {
                    sessionStorage.removeItem('log-ide');
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                }
                setSettings({ backdrop: formatMessage(false, '', '') });
            })
            .catch(err => {
                setSettings({
                    snackbar: formatMessage(true, 'error', err),
                    backdrop: formatMessage(false, '', '')
                });
            });
    }
    useEffect(() => {
        handleRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={{ m: 2 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <CardGraphics
                    title='Trazabilidad de inicio'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeInit,
                        onChange: (value) => handleOnChange(value, 'treeInit'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeInit.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Trazabilidad de dependencia'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeDependence,
                        onChange: (value) => handleOnChange(value, 'treeDependence'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeDependence.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title='Trazabilidad de jerarquía'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeHierarchy,
                        onChange: (value) => handleOnChange(value, 'treeHierarchy'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeHierarchy.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Trazabilidad de tipo de jerarquía'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeHierarchyType,
                        onChange: (value) => handleOnChange(value, 'treeHierarchyType'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeHierarchyType.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title='Trazabilidad por tema de búsqueda'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeHierarchySearch,
                        onChange: (value) => handleOnChange(value, 'treeHierarchySearch'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeHierarchySearch.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Trazabilidad de recurso'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeResource,
                        onChange: (value) => handleOnChange(value, 'treeResource'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeResource.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title='Trazabilidad de sub recurso'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeSubResource,
                        onChange: (value) => handleOnChange(value, 'treeSubResource'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeSubResource.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
                <CardGraphics
                    title='Trazabilidad de acción'
                    tools={{
                        usability: false,
                        rangeTime: false,
                        gender: false,
                        topics: false,
                        number: false,
                        language: false,
                        accumulate: false,
                        connection: false,
                        collaboration: false,
                        download: false,
                        notice: false,
                        dialog: false,
                        dataTable: false,
                        graphType: false,
                        graph: false,
                    }}
                    infoNotice={{
                        label: '',
                        type: ''
                    }}
                    infoDialog={{
                        label: '',
                        gif: ''
                    }}
                    infoGraph={{
                        data: login.usability.reportTree.treeAction,
                        onChange: (value) => handleOnChange(value, 'treeAction'),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <TreeVirtualized
                        label={null}
                        search=''
                        data={login.usability.reportTree.treeAction.processed}
                        selected=''
                        handleClick={(value) => null}
                    />
                </CardGraphics>
            </Stack>
            <Tooltip title='Presione para recargar la información de usabilidad.'>
                <SpeedDial
                    icon={<Cached />}
                    ariaLabel='SpeedDial'
                    onClick={() => handleRequest()}
                    sx={{ position: 'fixed', bottom: 30, right: 30 }}
                />
            </Tooltip>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    login: state.login,
    metrics: state.metrics,
});
const mapDispatchToProps = (dispatch) => ({
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportTree);