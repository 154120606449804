import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configPieHalf, configColorGender } from '../../../assets/theme/g2Theme';

function PieHalf(props) {
    const { infoGraph } = props;
    return (
        <ReactG2Plot
            Ctor={Pie}
            options={{
                ...{
                    data: infoGraph.data,
                    ...(infoGraph.colorGender ? configColorGender : {}),
                },
                ...configPieHalf
            }}
        />
    );
}
PieHalf.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.array,
        colorGender: PropTypes.bool
    })
};
export default PieHalf;
