import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, BottomNavigation, BottomNavigationAction, Typography, styled } from '@mui/material';

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        right: -5,
        top: 5,
    },
}));

function Navigation(props) {
    const { options, value, handleStep } = props;
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => handleStep(newValue)}
                sx={{
                    mt: 1, mb: 3.4,
                    '& .Mui-selected': {
                        bgcolor: theme => theme.palette.primary.main,
                        '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                            color: theme => theme.palette.common.white,
                        },
                    },
                    '& .MuiBottomNavigationAction-root:hover': {
                        bgcolor: theme => theme.palette.primary.light,
                        '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                            bgcolor: theme => theme.palette.primary.light,
                            color: theme => theme.palette.primary.darker,
                        },
                    }
                }}
            >
                {options.map((item, key) => {
                    return (
                        <BottomNavigationAction
                            key={key}
                            label={
                                <Typography sx={{ lineHeight: 1.2 }} variant='subtitle1'>
                                    {item.label}
                                </Typography>
                            }
                            icon={
                                <StyledBadge
                                    badgeContent={item.value || 0}
                                    color='info'
                                    max={999}
                                >
                                    <Box sx={{ mt: 0.8 }}>{item.icon}</Box>
                                </StyledBadge>
                            }
                            sx={{
                                display: item.display ? 'inline-flex' : 'none',
                                color: theme => theme.palette.primary.main,
                                bgcolor: theme => theme.palette.primary.lighter,
                                alignItems: 'center',
                                borderRadius: 3,
                                mr: 0.5
                            }}
                        />
                    )
                })}
            </BottomNavigation>
        </Box>
    );
};
Navigation.propTypes = {
    options: PropTypes.array,
    value: PropTypes.number,
    handleStep: PropTypes.func,
};
export default Navigation;