import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configColumnStacked, configColorGender } from '../../../assets/theme/g2Theme';

function ColumnStacked(props) {
    const { infoGraph } = props;
    return (
        <ReactG2Plot
            Ctor={Column}
            options={{
                ...{
                    data: infoGraph.data,
                    xAxis: { title: { text: infoGraph.axis.xAxis } },
                    yAxis: { title: { text: infoGraph.axis.yAxis } },
                    ...(infoGraph.colorGender ? configColorGender : {}),
                },
                ...configColumnStacked
            }}
        />
    );
}
ColumnStacked.propTypes = {
    infoGraph: PropTypes.shape({
        data: PropTypes.array,
        colorGender: PropTypes.bool,
        axis: PropTypes.object
    })
};
export default ColumnStacked;
