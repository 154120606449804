import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CardPublicationsList from '../../../../components/card/CardPublicationsList';
import { requestPost } from '../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../constants';

function ProductsList(props) {
    const { author, investigation } = props;
    const hierarchy = {
        ...investigation.hierarchy,
        resource: 'Lista de productos'
    };
    useEffect(() => {
        requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={{ bgcolor: 'white', p: 3 }}>
            <CardPublicationsList
                data={investigation.metrics.general[constants.METRIC_AUTHORS_INFO_FIELD].processed[constants.AUTHOR_PUBLICATIONS_FIELD]}
                params={investigation.hierarchy.params}
                hierarchy={hierarchy}
                options={{
                    deleted: false,
                    edit: false,
                    order: 'date',
                    relevance: false,
                    author: author
                }}
            />
        </Box>
    )
}
ProductsList.propTypes = {
    author: PropTypes.array
};
ProductsList.defaultProps = {
    author: []
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
});
export default connect(mapStateToProps)(ProductsList);