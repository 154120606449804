import React, { useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Typography, Stack, Box, Button } from '@mui/material';
import { ManageHistoryTwoTone } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import Pie from '../graphics/Pie';
import { formatNumber } from '../../../utils/utils';

const RootStyle = styled(Button)(() => ({
    width: '100%',
    color: 'white',
    borderRadius: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
}));

const IconWrapperStyle = styled('div')(() => ({
    top: 12,
    right: 12,
    width: 25,
    height: 25,
    position: 'absolute'
}));

function Widget(props) {
    const { title, total, filter, chartData, gender, icon, subtitle, sub_total, step, stepTab, handleStepTab, isAuthor, isGroup } = props;
    const rootRef = useRef(null);
    const { t } = useTranslation();

    return (
        <RootStyle ref={rootRef}
            sx={{

                bgcolor: step === stepTab ? theme => theme.palette['green'].main : theme => theme.palette['darkerBlue'].main,
                border: step === stepTab ? 3 : 0,
                boxShadow: step === stepTab ? '-4px 2px 52px 2px rgba(0,0,0,0.37)' : 'auto',
                borderRadius: 1,
                ...isGroup && { height: 100 },
                '&:hover': {
                    bgcolor: theme => theme.palette['green'].main,
                },
                '&:focus': {
                    outline: 'transparent',
                },
            }}
            onClick={() => handleStepTab(step)}
        >
            <Stack
                direction='row'
                height='100%'
                textAlign='left'
                justifyContent='center'
                alignItems='center'
            >
                <Stack
                    sx={{
                        p: 1.5,
                        pt: (isAuthor ? 0.5 : 0.5),
                        pb: (isAuthor ? 0.5 : 0.5),
                        height: '100%',
                        alignItems: 'flex-start',
                        justifyContent: (isAuthor ? 'flex-end' : 'center')
                    }}
                >
                    {icon && (
                        <Box color='white' paddingLeft={0.25}>
                            {cloneElement(icon, { fontSize: 'large' })}
                        </Box>
                    )}
                    {title === 'Temáticas y Areas de trabajo' ? (
                        <Stack alignItems='flex-start'>
                            <Typography variant='subtitle1' lineHeight={1}>Temáticas y</Typography>
                            <Typography variant='subtitle1' lineHeight={1}>Areas de Trabajo</Typography>
                        </Stack>
                    ) : (
                        title !== '' && (
                            <Typography variant='subtitle1' lineHeight={1}>{title}</Typography>
                        )
                    )}
                    {total > 0 && (
                        <Typography sx={{ fontSize: 30, fontWeight: 600, lineHeight: 1 }}>
                            {formatNumber(total)}
                        </Typography>
                    )}
                    {subtitle !== '' && sub_total > 0 && (
                        <Stack direction='row' spacing={1}>
                            <Typography mt={0} sx={{ typography: 'body1' }}>{subtitle}</Typography>
                            <Typography mt={0} sx={{ typography: 'body1' }}>{formatNumber(sub_total)}</Typography>
                        </Stack>
                    )}
                    {chartData.length > 0 && (
                        <Pie
                            infoGraph={{
                                data: chartData,
                                colorGender: gender,
                                formatValue: 'number',
                            }}
                        />
                    )}
                </Stack>
                {filter && (
                    <Tooltip title={t('components.widgets.widget.tooltip')}>
                        <IconWrapperStyle sx={{ color: 'white' }}>
                            <ManageHistoryTwoTone />
                        </IconWrapperStyle>
                    </Tooltip>
                )}
            </Stack>
        </RootStyle>
    );
};
Widget.propTypes = {
    title: PropTypes.string,
    total: PropTypes.number,
    filter: PropTypes.bool,
    chartData: PropTypes.array,
    gender: PropTypes.bool,
    icon: PropTypes.element,
    subtitle: PropTypes.string,
    sub_total: PropTypes.number,
    step: PropTypes.string,
    stepTab: PropTypes.string,
    handleStepTab: PropTypes.func,
    isAuthor: PropTypes.bool,
    isGroup: PropTypes.bool
};
Widget.defaultProps = {
    title: '',
    total: 0,
    filter: false,
    chartData: [],
    gender: false,
    icon: <></>,
    subtitle: '',
    sub_total: 0,
    step: 'products',
    stepTab: 0,
    handleStepTab: () => { },
    isAuthor: false,
    isGroup: false,
};
export default Widget;