import React from 'react';
import { Box, Grid, Backdrop, CircularProgress, Typography } from '@mui/material/';
import PropTypes from 'prop-types';

function CustomBackdrop(props) {
    const { open, message, position } = props
    return (
        <Box>
            <Backdrop
                sx={{
                    zIndex: 500,
                    color: '#fff',
                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                    position: position
                }}
                open={open}
            >
                <Grid
                    spacing={3}
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item xs={12}>
                        <CircularProgress color='inherit' />
                    </Grid>
                    {message && (
                        <Grid item xs={12}>
                            <Typography
                                align='center'
                                sx={{ color: 'inherit' }}
                                variant='h6'
                            >
                                {message}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Backdrop>
        </Box>
    );
}
CustomBackdrop.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string
}
CustomBackdrop.defaultProps = {
    open: true,
    message: '',
}
export default CustomBackdrop;