import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import CardGraphics from '../../../../components/card/CardGraphics';
import ColumnGroup from '../../../../components/graphics/ColumnGroup';
import { requestPost } from '../../../../../utils/request';
import { metricsExt, formatMessage, objectAssignExt } from '../../../../../utils/utils';
import { constants, URL_API_EXT, URL_API_INV } from '../../../../../constants';

function Opportunities(props) {
    const { extension, settings, setExtension, setSettings } = props;
    const { t } = useTranslation();
    const params = useParams();
    const handleOnChange = (value, state) => {
        const newExtension = objectAssignExt(extension, {
            metrics: { opportunities: { [state]: value } },
        });
        setExtension(
            metricsExt(
                [state],
                extension.metrics.opportunities.source,
                extension.hierarchy,
                newExtension
            )
        );
    };
    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.extension.consultingInfoOpportunities')) });
        if (extension.hierarchy.stepKey) {
            const hierarchy = {
                ...extension.hierarchy,
                resource: 'Oportunidades',
            };
            Promise.all([
                requestPost(`${URL_API_EXT}/extension/opportunities`, { search: extension.search.search }),
                requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy }),
            ])
                .then(res => {
                    setExtension(
                        metricsExt(
                            Object.keys(extension.metrics.opportunities),
                            res[0].data,
                            hierarchy,
                            extension
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extension.hierarchy.stepKey, extension.search.search]);
    return (
        <Box>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <CardGraphics
                    title={t('dependence.extension.opportunitiesMain.statusComparison')}
                    tools={{
                        usability: true,
                        rangeTime: true,
                        gender: false,
                        type: false,
                        accumulate: true,
                        graph: false,
                        wordCloud: false,
                        download: true,
                        dialog: false,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: t('dependence.extension.opportunitiesMain.statusComparisonInfo'),
                        gif: '',
                        notice: t('dependence.extension.opportunitiesMain.statusComparisonNotice')
                    }}
                    infoGraph={{
                        data: extension.metrics.opportunities[constants.METRIC_COUNTS_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_COUNTS_FIELD),
                        columns: {
                            group: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                            type: t('dependence.extension.opportunitiesMain.statusComparisonGraphType'),
                            value: t('dependence.extension.opportunitiesMain.statusComparisonGraphValue'),
                        },
                        formatValue: 'number',
                        sortField: 'group'
                    }}
                >
                    <ColumnGroup
                        infoGraph={{
                            data: extension.metrics.opportunities[constants.METRIC_COUNTS_FIELD].processed,
                            colorGender: false,
                            axis: {
                                xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                yAxis: t('dependence.extension.opportunitiesMain.statusComparisonGraphAxisY')
                            }
                        }}
                    />
                </CardGraphics>
            </Stack>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    extension: state.extension,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Opportunities);