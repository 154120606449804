import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Stack, IconButton, DialogContentText, InputAdornment, Grid, TextField, MenuItem, SpeedDial } from '@mui/material';
import { DriveFileRenameOutlineTwoTone, VpnKeyTwoTone, DeleteTwoTone, VisibilityOffTwoTone, VisibilityTwoTone, GroupAddTwoTone } from '@mui/icons-material';
import DataTable from '../../../../../components/DataTable';
import Tooltip from '../../../../../components/Tooltip';
import Dialog from '../../../../../components/Dialog';
import { formatLowerCase, formatMessage, formatDisplay } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../../constants';

function Users(props) {
    const { login, investigation, setSettings, setLogin } = props;
    const [values, setValues] = useState({
        showPassword: false,
        dialog: false,
        type: '',
        label: {
            title: '',
            description: ''
        },
    });
    const navigate = useNavigate();
    const defaultHierarchy = investigation.hierarchy;
    const validationSchema = yup.object({});
    const validationSchemaEdit = yup.object({
        [constants.LOGIN_ID_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_ID_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_USER_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_USER_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_ROLE_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_ROLE_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_NAMES_FIELD.field]: yup
            .string('Ingrese los ' + formatLowerCase(constants.LOGIN_NAMES_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_SURNAMES_FIELD.field]: yup
            .string('Ingrese los ' + formatLowerCase(constants.LOGIN_SURNAMES_FIELD.label))
            .required('El campo es requerido.'),
    });
    const validationSchemaEditPassword = yup.object({
        [constants.LOGIN_ID_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_ID_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_PASS_FIELD.field]: yup
            .string('Ingrese la ' + formatLowerCase(constants.LOGIN_PASS_FIELD.label))
            .required('El campo es requerido.'),
    });
    const validationSchemaDelete = yup.object({
        [constants.LOGIN_ID_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_ID_FIELD.label))
            .required('El campo es requerido.'),
    });
    const validationSchemaAdd = yup.object({
        [constants.LOGIN_USER_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_USER_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_PASS_FIELD.field]: yup
            .string('Ingrese la ' + formatLowerCase(constants.LOGIN_PASS_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_ROLE_FIELD.field]: yup
            .string('Ingrese el ' + formatLowerCase(constants.LOGIN_ROLE_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_NAMES_FIELD.field]: yup
            .string('Ingrese los ' + formatLowerCase(constants.LOGIN_NAMES_FIELD.label))
            .required('El campo es requerido.'),
        [constants.LOGIN_SURNAMES_FIELD.field]: yup
            .string('Ingrese los ' + formatLowerCase(constants.LOGIN_SURNAMES_FIELD.label))
            .required('El campo es requerido.'),
    });
    const formikUsers = useFormik({
        initialValues: {
            [constants.LOGIN_ID_FIELD.field]: '',
            [constants.LOGIN_USER_FIELD.field]: '',
            [constants.LOGIN_PASS_FIELD.field]: '',
            [constants.LOGIN_ROLE_FIELD.field]: '',
            [constants.LOGIN_NAMES_FIELD.field]: '',
            [constants.LOGIN_SURNAMES_FIELD.field]: '',
        },
        validationSchema: values.type === 'edit'
            ? validationSchemaEdit
            : values.type === 'edit-pass'
                ? validationSchemaEditPassword
                : values.type === 'delete'
                    ? validationSchemaDelete
                    : values.type === 'add'
                        ? validationSchemaAdd
                        : validationSchema,
        onSubmit: (data) => {
            setSettings({ backdrop: formatMessage(true, '', 'Procesando la información.') });
            const dataHierarchy = {};
            Object.keys(data).forEach(item => {
                dataHierarchy[item] = item === constants.LOGIN_PASS_FIELD.field ? '*****' : data[item];
            });
            const hierarchy = {
                ...defaultHierarchy,
                resource: 'Lista de usuarios',
                subResource: values.label.title,
                action: { type: 'Información del usuario', value: JSON.stringify(dataHierarchy) },
            };
            Promise.all([
                requestPost(`${URL_API_INV}/auth/users`, {
                    type: values.type,
                    data: data,
                    record: hierarchy
                })
            ])
                .then(res => {
                    if (res[0].data.access) {
                        setLogin({ admin: { infoUsers: res[0].data.infoUsers } });
                        setSettings({ snackbar: formatMessage(true, 'success', res[0].data.message) });
                        handleClose();
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err)
                    });
                });
        },
    });
    const handleClose = () => {
        setValues({
            showPassword: false,
            dialog: false,
            type: '',
            label: {
                title: '',
                description: ''
            },
        });
    };
    const handleEdit = (infoUser) => {
        formikUsers.setFieldValue(constants.LOGIN_ID_FIELD.field, infoUser._id, true);
        formikUsers.setFieldValue(constants.LOGIN_USER_FIELD.field, infoUser.user, true);
        formikUsers.setFieldValue(constants.LOGIN_ROLE_FIELD.field, infoUser.dependence, true);
        formikUsers.setFieldValue(constants.LOGIN_NAMES_FIELD.field, infoUser.names, true);
        formikUsers.setFieldValue(constants.LOGIN_SURNAMES_FIELD.field, infoUser.surnames, true);
        setValues({
            ...values,
            dialog: true,
            type: 'edit',
            label: {
                title: 'Editar información de usuario',
                description: 'Por favor ingrese la nueva información de ' + [infoUser.names, infoUser.surnames].join(' ') + '.'
            },
        });
    };
    const handleEditPassword = (infoUser) => {
        formikUsers.setFieldValue(constants.LOGIN_ID_FIELD.field, infoUser._id, true);
        formikUsers.setFieldValue(constants.LOGIN_PASS_FIELD.field, '', true);
        setValues({
            ...values,
            dialog: true,
            type: 'edit-pass',
            label: {
                title: 'Editar contraseña de usuario',
                description: 'Por favor ingrese la nueva contraseña de ' + [infoUser.names, infoUser.surnames].join(' ') + '.'
            },
        });
    };
    const handleDelete = (infoUser) => {
        const validateAdmin = login.admin.infoUsers.filter(user => user.dependence === 'Administrador').length;
        if ((validateAdmin <= 1) && (infoUser.dependence === 'Administrador')) {
            setValues({
                ...values,
                dialog: true,
                type: 'delete-none',
                label: {
                    title: 'Eliminar usuario',
                    description: 'Lo sentimos, no puede eliminar al unico administrador.'
                },
            });
        } else {
            formikUsers.setFieldValue(constants.LOGIN_ID_FIELD.field, infoUser._id, true);
            setValues({
                ...values,
                dialog: true,
                type: 'delete',
                label: {
                    title: 'Eliminar usuario',
                    description: '¿Estas seguro que deseas eliminar a ' + [infoUser.names, infoUser.surnames].join(' ') + '?'
                },
            });
        }
    };
    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleAdd = () => {
        formikUsers.setFieldValue(constants.LOGIN_ID_FIELD.field, '', true);
        formikUsers.setFieldValue(constants.LOGIN_USER_FIELD.field, '', true);
        formikUsers.setFieldValue(constants.LOGIN_PASS_FIELD.field, '', true);
        formikUsers.setFieldValue(constants.LOGIN_ROLE_FIELD.field, '', true);
        formikUsers.setFieldValue(constants.LOGIN_NAMES_FIELD.field, '', true);
        formikUsers.setFieldValue(constants.LOGIN_SURNAMES_FIELD.field, '', true);
        setValues({
            ...values,
            dialog: true,
            type: 'add',
            label: {
                title: 'Agregar usuario',
                description: 'Por favor ingrese los datos del nuevo usuario.'
            },
        });
    };
    const columnsUsers = [
        { field: constants.LOGIN_USER_FIELD.field, headerName: constants.LOGIN_USER_FIELD.label, flex: 1 },
        { field: constants.LOGIN_NAMES_FIELD.field, headerName: constants.LOGIN_NAMES_FIELD.label, flex: 1 },
        { field: constants.LOGIN_SURNAMES_FIELD.field, headerName: constants.LOGIN_SURNAMES_FIELD.label, flex: 1 },
        { field: constants.LOGIN_ROLE_FIELD.field, headerName: constants.LOGIN_ROLE_FIELD.label, flex: 1 },
        {
            field: '_id', headerName: 'Opciones', flex: 1,
            renderCell: (params) => (
                <Stack direction='row' spacing={0.5}>
                    <Tooltip title='Editar'>
                        <IconButton aria-label='Editar' onClick={() => handleEdit(params.row)}>
                            <DriveFileRenameOutlineTwoTone />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar contraseña'>
                        <IconButton aria-label='Editar contraseña' onClick={() => handleEditPassword(params.row)}>
                            <VpnKeyTwoTone />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar'>
                        <IconButton aria-label='Eliminar' onClick={() => handleDelete(params.row)}>
                            <DeleteTwoTone />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ),
        },
    ];
    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de usuarios.') });
            const hierarchy = {
                ...defaultHierarchy,
                resource: 'Lista de usuarios'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/auth/panel`, { record: hierarchy }),
            ])
                .then(res => {
                    if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                        setLogin({
                            admin: {
                                infoUser: res[0].data.infoUser,
                                infoUsers: res[0].data.infoUsers,
                            }
                        });
                    } else if (res[0].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);
    return (
        <Box>
            <Tooltip title='Presione para agregar un nuevo usuario.'>
                <SpeedDial
                    ariaLabel='addUser'
                    sx={{ position: 'relative', top: 10, right: 10 }}
                    direction='left'
                    icon={<GroupAddTwoTone />}
                    onClick={handleAdd}
                />
            </Tooltip>
            <DataTable
                id='_id'
                rows={login.admin.infoUsers}
                columns={columnsUsers}
                sortModel={[{ field: constants.LOGIN_USER_FIELD.field, sort: 'asc' }]}
                fileName='Lista_usuarios'
            />
            <Dialog
                _id='form-user'
                open={values.dialog}
                title={values.label.title}
                width='md'
                handleClose={handleClose}
                handleBack={{
                    active: false,
                    label: '',
                    function: null
                }}
                handleNext={{
                    active: values.type === 'delete-none' ? false : true,
                    label: 'Aceptar',
                    function: null
                }}
            >
                <DialogContentText>
                    {values.label.description}
                </DialogContentText>
                <form onSubmit={formikUsers.handleSubmit} id='form-user'>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={values.type === 'add' ? 4 : 6} sx={{ display: formatDisplay(values.type, 'user') }}>
                            <TextField
                                fullWidth
                                id={constants.LOGIN_USER_FIELD.field}
                                name={constants.LOGIN_USER_FIELD.field}
                                label={constants.LOGIN_USER_FIELD.label}
                                value={formikUsers.values[constants.LOGIN_USER_FIELD.field]}
                                onChange={formikUsers.handleChange}
                                error={formikUsers.touched[constants.LOGIN_USER_FIELD.field] && Boolean(formikUsers.errors[constants.LOGIN_USER_FIELD.field])}
                                helperText={formikUsers.touched[constants.LOGIN_USER_FIELD.field] && formikUsers.errors[constants.LOGIN_USER_FIELD.field]}
                            />
                        </Grid>
                        <Grid item xs={12} md={values.type === 'add' ? 4 : 12} sx={{ display: formatDisplay(values.type, 'password') }}>
                            <TextField
                                fullWidth
                                id={constants.LOGIN_PASS_FIELD.field}
                                name={constants.LOGIN_PASS_FIELD.field}
                                label={constants.LOGIN_PASS_FIELD.label}
                                value={formikUsers.values[constants.LOGIN_PASS_FIELD.field]}
                                onChange={formikUsers.handleChange}
                                error={formikUsers.touched[constants.LOGIN_PASS_FIELD.field] && Boolean(formikUsers.errors[constants.LOGIN_PASS_FIELD.field])}
                                helperText={formikUsers.touched[constants.LOGIN_PASS_FIELD.field] && formikUsers.errors[constants.LOGIN_PASS_FIELD.field]}
                                type={values.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={handleShowPassword}
                                                edge='end'
                                            >
                                                {values.showPassword ? <VisibilityOffTwoTone /> : <VisibilityTwoTone />}
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={values.type === 'add' ? 4 : 6} sx={{ display: formatDisplay(values.type, 'dependence') }}>
                            <TextField
                                fullWidth
                                id={constants.LOGIN_ROLE_FIELD.field}
                                name={constants.LOGIN_ROLE_FIELD.field}
                                label={constants.LOGIN_ROLE_FIELD.label}
                                value={formikUsers.values[constants.LOGIN_ROLE_FIELD.field]}
                                onChange={(event, value) => formikUsers.setFieldValue(constants.LOGIN_ROLE_FIELD.field, value.props.value, true)}
                                error={formikUsers.touched[constants.LOGIN_ROLE_FIELD.field] && Boolean(formikUsers.errors[constants.LOGIN_ROLE_FIELD.field])}
                                helperText={formikUsers.touched[constants.LOGIN_ROLE_FIELD.field] && formikUsers.errors[constants.LOGIN_ROLE_FIELD.field]}
                                select
                            >
                                <MenuItem key='Administrador' value='Administrador'>Administrador</MenuItem>
                                <MenuItem key='Extension' value='Extension'>Extension</MenuItem>
                                <MenuItem key='Curaduria' value='Curaduria'>Curaduria</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: formatDisplay(values.type, 'names') }}>
                            <TextField
                                fullWidth
                                id={constants.LOGIN_NAMES_FIELD.field}
                                name={constants.LOGIN_NAMES_FIELD.field}
                                label={constants.LOGIN_NAMES_FIELD.label}
                                value={formikUsers.values[constants.LOGIN_NAMES_FIELD.field]}
                                onChange={formikUsers.handleChange}
                                error={formikUsers.touched[constants.LOGIN_NAMES_FIELD.field] && Boolean(formikUsers.errors[constants.LOGIN_NAMES_FIELD.field])}
                                helperText={formikUsers.touched[constants.LOGIN_NAMES_FIELD.field] && formikUsers.errors[constants.LOGIN_NAMES_FIELD.field]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: formatDisplay(values.type, 'surnames') }}>
                            <TextField
                                fullWidth
                                id={constants.LOGIN_SURNAMES_FIELD.field}
                                name={constants.LOGIN_SURNAMES_FIELD.field}
                                label={constants.LOGIN_SURNAMES_FIELD.label}
                                value={formikUsers.values[constants.LOGIN_SURNAMES_FIELD.field]}
                                onChange={formikUsers.handleChange}
                                error={formikUsers.touched[constants.LOGIN_SURNAMES_FIELD.field] && Boolean(formikUsers.errors[constants.LOGIN_SURNAMES_FIELD.field])}
                                helperText={formikUsers.touched[constants.LOGIN_SURNAMES_FIELD.field] && formikUsers.errors[constants.LOGIN_SURNAMES_FIELD.field]}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    login: state.login,
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);