import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Avatar, Chip, IconButton } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import Tooltip from '../Tooltip';
import CardEmpty from '../card/CardEmpty';

function CustomTimeline(props) {
    const { data, handleClick } = props;
    return (
        <Timeline position='alternate'>
            {data.length === 0 ? (
                <CardEmpty />
            ) : (
                data.map((item, key) => {
                    return (
                        <TimelineItem key={key}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align='right'
                            >
                                <Typography component='div' variant='body2' color='text.secondary'>
                                    {item.information}
                                    {item.subInformation && (
                                        <Tooltip title={item.subInformation[0]}>
                                            <Chip label={item.subInformation[1]} color='primary' size='small' variant='outlined' sx={{ ml: 0.5 }} />
                                        </Tooltip>
                                    )}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <IconButton onClick={() => handleClick(item.handleClick)}>
                                    <Avatar sx={{ m: 0.5, bgcolor: item.bgColor }}>
                                        {item.icon}
                                    </Avatar>
                                </IconButton>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant='h6' component='span'>
                                    {item.title}
                                </Typography>
                                <Typography>
                                    {item.description}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            )}
        </Timeline>
    )
}
CustomTimeline.propTypes = {
    data: PropTypes.array,
    handleClick: PropTypes.func
};
export default CustomTimeline;