import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Drawer, Stack, ListItemText, Typography, Button, Card, Avatar, Divider, Grid, useMediaQuery } from '@mui/material';
import { ArrowDropDown, ArrowRight, LogoutTwoTone } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TreeView } from '@mui/x-tree-view/TreeView';
import TreeItem from './tree/TreeItem';
import Scrollbar from './Scrollbar';
import { constants } from '../../constants';
import { initialState } from '../../redux/store';

function CustomDrawer(props) {
    const { view, data, settings, setSettings, setReports, setLoginDefault } = props;
    
    const navigate = useNavigate();
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    
    const handleClose = () => {
        setLoginDefault();
        sessionStorage.removeItem('log-ide');
        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
    };

    const handleOpenDrawer = () => {
        setSettings({ openDrawer: !settings.openDrawer });
    };

    const handleReports = (item) => {
        setReports({ report: item, params: {}, options: initialState.reports.options });
        if (isXs) {
            handleOpenDrawer();
        }
    };
    
    const NewTreeItem = (item) => (
        <TreeItem
            key={item.id}
            nodeId={item.id}
            LabelType={item.type}
            labelText={item['label_' + settings.language]}
            labelInfo={item.children ? String(item.children.length) : ''}
            onClick={() => item.children ? null : handleReports(item)}
        >
            {Array.isArray(item.children) ? item.children.map((subItem) => NewTreeItem(subItem)) : null}
        </TreeItem>
    );
    const drawer = () => {
        return (
            <Scrollbar>
                <Box sx={{ mt: { sm: `${settings.heightTop}px`, xs: 0 } }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {view === 'login' && (
                            <Stack direction='column' spacing={3} sx={{ mr: 3, ml: 3, pb: 1 }}>
                                <ListItemText
                                    primary={
                                        <Typography variant='h5' sx={{ mt: 5 }}>
                                            Información del usuario
                                        </Typography>
                                    }
                                />
                                <Card
                                    sx={{
                                        mb: 1,
                                        textAlign: 'center',
                                        bgcolor: theme => theme.palette.grey[200]
                                    }}
                                >
                                    <Grid
                                        container
                                        direction='row'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <Avatar
                                            alt='avatarLogin'
                                            sx={{
                                                width: 80,
                                                height: 80,
                                                bgcolor: theme => theme.palette.primary.main,
                                                mt: 2, mb: 2
                                            }}
                                        />
                                    </Grid>
                                    <Typography variant='h6'>
                                        {[data[constants.LOGIN_NAMES_FIELD.field], data[constants.LOGIN_SURNAMES_FIELD.field]].join(' ')}
                                    </Typography>
                                    <Typography variant='body1' sx={{ color: 'text.secondary' }}>
                                        {data[constants.LOGIN_USER_FIELD.field]}
                                    </Typography>
                                    <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                                        {data[constants.LOGIN_ROLE_FIELD.field]}
                                    </Typography>
                                    <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />
                                    <Button
                                        variant='contained'
                                        endIcon={<LogoutTwoTone />}
                                        onClick={handleClose}
                                        sx={{ m: 2 }}
                                    >
                                        Cerrar sesión
                                    </Button>
                                </Card>
                            </Stack>
                        )}
                        {view === 'reports' && (
                            <Stack direction='column' spacing={3} sx={{ mb: 30 }}>
                                <ListItemText
                                    primary={
                                        <Typography sx={{ mt: 5, mr: 3, ml: 3 }} variant='h5'>Reportes</Typography>
                                    }
                                />
                                <TreeView
                                    defaultExpanded={['gender-report']}
                                    defaultCollapseIcon={<ArrowDropDown />}
                                    defaultExpandIcon={<ArrowRight />}
                                    sx={{ mb: 5 }}
                                >
                                    {constants.REPORTS.map((item) => NewTreeItem(item))}
                                </TreeView>
                            </Stack>
                        )}
                    </LocalizationProvider>
                </Box>
            </Scrollbar>
        );
    };
    return (
        <Box
            component='nav'
            sx={{
                width: settings.openDrawer ? settings.widthDrawer : 0,
                flexShrink: { sm: 0 }
            }}
        >
            <Drawer
                variant={isXs ? 'temporary' : 'persistent'}
                open={settings.openDrawer}
                onClose={handleOpenDrawer}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    position: 'sticky',
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: settings.openDrawer ? settings.widthDrawer : 0,
                    }
                }}
            >
                {drawer()}
            </Drawer>
        </Box>
    );
}
CustomDrawer.propTypes = {
    view: PropTypes.string,
    data: PropTypes.object,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setReports(state) {
        dispatch({
            type: 'SET_REPORTS',
            state,
        });
    },
    setLoginDefault() {
        dispatch({
            type: 'SET_LOGIN_DEFAULT'
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomDrawer);