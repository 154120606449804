import jQuery from 'jquery';

jQuery(document).ready(function ($) {
    function openAccess(detail, className) {
        if (detail.classList) {
            detail.classList.add(className);
        }
    }

    function closeAccess(detail, className) {
        if (detail.classList) {
            detail.classList.remove(className);
        }
    }

    function accesstab() {
        var mainwin = document.getElementsByClassName('main-horus');
        if (document.getElementById('panel-accesibilidad').style.display === 'none') {
            const elemento = document.getElementById('tx-unal-accesibilidad');
            elemento.style.zIndex = '10';
            document.getElementById('panel-accesibilidad').setAttribute('style', 'display:block');
            openAccess(mainwin, 'accesibility-on');
        } else if (document.getElementById('panel-accesibilidad').style.display === 'block') {
            const elemento = document.getElementById('tx-unal-accesibilidad');
            elemento.style.zIndex = '0';
            document.getElementById('panel-accesibilidad').setAttribute('style', 'display:none');
            closeAccess(mainwin, 'accesibility-on');
        }
    };

    document.getElementById('pestania-accesibilidad-mobil').addEventListener('click', accesstab);
    document.getElementById('pestania-accesibilidad').addEventListener('click', accesstab);
});

export function changeSizeLetter(movement) {
    var percent = document.getElementById('letter-percent').value;
    const newPercent = (
        movement === '+'
            ? percent < '200%' ? parseFloat(percent) + 10 : 200
            : movement === '-'
                ? percent > '100%' ? parseFloat(percent) - 10 : 100 : percent
    );
    document.getElementById('letter-percent').value = newPercent + '%';
    changeStatus('tamanio-letra-' + newPercent, 0);
}

export function changeContrasts(number) {
    var percent = parseFloat(document.getElementById('letter-percent').value);
    changeStatus('tamanio-letra-' + percent, 'contraste-' + number);
}

export function investColors(recover = false) {
    var css = 'html {-webkit-filter: invert(100%);' +
        '-moz-filter: invert(100%);' +
        '-o-filter: invert(100%);' +
        '-ms-filter: invert(100%); }',
        head = document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    if (!window.counter) {
        window.counter = 1;
    } else {
        window.counter++;
        if (window.counter % 2 == 0) { var css = 'html {-webkit-filter: invert(0%); -moz-filter: invert(0%); -o-filter: invert(0%); -ms-filter: invert(0%); }' }
    };
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
}

export function defaultConfig() {
    document.getElementById('letter-percent').value = '100%';
    var contra = document.getElementsByTagName('body')[0];
    contra.removeAttribute('class');
    var head = document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    var css = 'html {-webkit-filter: invert(0%); -moz-filter: invert(0%); -o-filter: invert(0%); -ms-filter: invert(0%); }'
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
}

function changeStatus(style_1, style_2) {
    var style = style_1 + ' ' + style_2;
    document.getElementsByTagName('body')[0].className = style;
}