import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'react-arborist';
import { Box, ListSubheader, Typography, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const heightItem = 40;
function TreeVirtualized(props) {
    const { search, label, data, selected, handleClick } = props;
    function Node(props) {
        const { node, style } = props;
        const { data, isLeaf, isOpen } = node;
        const { id, label, value, icon, onClick, children } = data;
        return (
            <ListItemButton
                key={id}
                style={{ paddingLeft: style.paddingLeft }}
                selected={selected === label}
                sx={{ height: heightItem, textOverflow: 'ellipsis' }}
            >
                {!isLeaf && children?.length !== 0 && (
                    <Box
                        onClick={() => node.toggle()}
                        component={isOpen ? ExpandLess : ExpandMore}
                        color='text.secondary'
                        sx={{ ml: 0.5, mr: 0.5 }}
                    />
                )}
                <ListItemIcon
                    sx={{
                        minWidth: 25,
                        ml: ((!isLeaf && children?.length !== 0) ? 0 : 3.6)
                    }}
                >
                    <Box component={icon} color='text.secondary' />
                </ListItemIcon>
                <ListItemText
                    primary={label}
                    onClick={() => handleClick(onClick)}
                />
                {value && (
                    <Typography variant='body3' sx={{ pr: 1 }}>
                        {value}
                    </Typography>
                )}
            </ListItemButton>
        );
    }
    return (
        <List
            component='nav'
            subheader={
                label && (
                    <ListSubheader component='div'>
                        <Typography variant='body1' sx={{ mt: 1, mb: 2 }}>
                            {label}
                        </Typography>
                    </ListSubheader>
                )
            }
        >
            <Tree
                data={data}
                indent={24}
                width={'100%'}
                height={450}
                rowHeight={heightItem}
                selection={selected}
                openByDefault={search === '' ? false : true}
            >
                {Node}
            </Tree>
        </List>
    );
};
TreeVirtualized.propTypes = {
    label: PropTypes.string,
    search: PropTypes.string,
    data: PropTypes.array,
    selected: PropTypes.string,
    handleClick: PropTypes.func
};
export default TreeVirtualized;