import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack } from '@mui/material';
import { RecentActorsTwoTone, FeedTwoTone, MapsUgcTwoTone, RemoveRedEyeTwoTone } from '@mui/icons-material';
import AppBar from '../../../../components/appbar/Appbar';
import Drawer from '../../../../components/Drawer';
import Navigation from '../../../../components/Navigation';
import Users from './users/Users';
import Feedback from './feedback/Feedback';
import Usability from './usability/Usability';
import News from './news/News';
import { formatMessage } from '../../../../../utils/utils';
import { requestPost } from '../../../../../utils/request';
import { URL_API_INV } from '../../../../../constants';

function Panel(props) {
    const { investigation, settings, login, setSettings, setLogin, setInvestigation } = props;

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const optionsNavigation = [
        {
            label: 'Usuarios',
            icon: <RecentActorsTwoTone />,
            display: true
        },
        {
            label: 'Retroalimentación',
            icon: <MapsUgcTwoTone />,
            display: true
        },
        {
            label: 'Usabilidad',
            icon: <RemoveRedEyeTwoTone />,
            display: true
        },
        {
            label: 'Noticias',
            icon: <FeedTwoTone />,
            display: true
        }
    ];

    useEffect(() => {
        if (investigation.hierarchy.stepHome) {
            Promise.all([
                requestPost(`${URL_API_INV}/auth/login`, { record: 'none' }),
            ])
                .then(res => {
                    if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                        setLogin({ admin: { infoUser: res[0].data.infoUser } });
                        const keyDep = location.state?.key === 'hierarchy' ? false : true;
                        window.history.replaceState({}, document.title);
                        const hierarchy = {
                            ...investigation.hierarchy,
                            hierarchy: {
                                ...investigation.hierarchy.hierarchy,
                                dependence: [keyDep, 'Autenticación'],
                                hierarchy: ['Inicio de sesión', 'Administrador'],
                            },
                            params: params,
                            stepKey: true
                        };
                        setInvestigation({ hierarchy: hierarchy });
                        requestPost(`${URL_API_INV}/usability/hierarchy`, { record: hierarchy });
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    } else if (res[0].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepHome]);
    
    return (
        <Box>
            <Drawer
                view='login'
                data={login.admin.infoUser}
            />
            <Box
                component='main'
                sx={{
                    width: { sm: `calc(100% - ${settings.openDrawer ? settings.widthDrawer : 0}px)` },
                    ml: { sm: `${settings.openDrawer ? settings.widthDrawer : 0}px` }
                }}
            >
                <AppBar
                    hierarchy='general'
                    params={params}
                    value={params.panel}
                />
                <Container sx={{ mt: 3, mb: 3 }}>
                    <Stack direction={{ xs: 'column' }} spacing={3}>
                        <Paper elevation={4}>
                            <TabContext value={login.admin.stepPanel.toString()}>
                                <Navigation
                                    options={optionsNavigation}
                                    value={login.admin.stepPanel}
                                    handleStep={(newValue) => setLogin({ admin: { stepPanel: newValue } })}
                                />
                                <TabPanel value='0'>
                                    <Users />
                                </TabPanel>
                                <TabPanel value='1'>
                                    <Feedback />
                                </TabPanel>
                                <TabPanel value='2'>
                                    <Usability />
                                </TabPanel>
                                <TabPanel value='3'>
                                    <News />
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings,
    login: state.login,
});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Panel);
