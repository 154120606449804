import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Paper, Stack, Grid } from '@mui/material';
import { CalendarMonthTwoTone, Diversity3TwoTone } from '@mui/icons-material';
import AppBar from '../../../components/appbar/Appbar';
import Widgets from '../../../components/widgets/Widgets';
import CardGroup from '../../../components/card/CardGroup';
import Calls from './panels/Calls';
import Members from './panels/Members';
import { requestPost } from '../../../../utils/request';
import { metricsInv, formatMessage, getStepResource, getParamsRoute } from '../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../constants';

function Group(props) {
    const { investigation, dependence, settings, setInvestigation, setSettings } = props;
    const [metric, setMetric] = useState('');

    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const widgets = [
        {
            title: t('dependence.investigation.mincienciasCalls'),
            filter: false,
            icon: <CalendarMonthTwoTone />,
            step: 'calls'
        },
        {
            title: t('dependence.investigation.groupMembers'),
            filter: false,
            icon: <Diversity3TwoTone />,
            step: 'members'
        }
    ];

    const handleRoute = (resource) => {
        params.resource = resource;
        const route = getParamsRoute(params);
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        getStepResource(params, widgets, navigate, location);
        if (params.group && investigation.hierarchy.stepHome) {
            const keyDep = location.state?.key === 'hierarchy' ? false : true;
            window.history.replaceState({}, document.title);
            const hierarchy = {
                ...investigation.hierarchy,
                hierarchy: {
                    ...investigation.hierarchy.hierarchy,
                    dependence: [keyDep, 'Investigación'],
                    hierarchy: ['Grupo de investigación', params.group],
                },
                resource: 'General',
                params: params,
                stepKey: true
            };
            if (metric !== params.group) {
                setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoGroup')) });
                setMetric(params.group);
                Promise.all([
                    requestPost(`${URL_API_INV}/investigation/group`, {
                        _id: params.group,
                        record: hierarchy
                    }),
                ])
                    .then(res => {
                        setInvestigation(
                            metricsInv(
                                Object.keys(investigation.metrics.general),
                                {
                                    [constants.METRIC_BOTH_FIELD]: { [constants.METRIC_YEAR_FIELD]: { [dayjs().year()]: 1 } },
                                    [constants.METRIC_GROUPS_INFO_FIELD]: res[0].data
                                },
                                hierarchy,
                                investigation,
                                settings
                            )
                        );
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, dependence]);

    return (
        <Box sx={{ bgcolor: 'white' }}>
            <AppBar hierarchy='research' params={params} value={params.group}>
                <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignItems='center'
                    sx={{ width: { xs: '100%', sm: '100%' }, ml: { xs: '1%', sm: '1%' } }}
                >
                    <Grid item xs={12} md={9}>
                        <CardGroup
                            group={investigation.metrics.general[constants.METRIC_GROUPS_INFO_FIELD].processed}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Widgets
                            isGroup={true}
                            widgets={widgets}
                            stepTab={params.resource}
                            handleStepTab={(resource) => handleRoute(resource)}
                            sx={{ height: '100%' }}
                        />
                    </Grid>
                </Grid>
            </AppBar>
            <Container
                component='main'
                maxWidth='xl'
            >
                <Stack direction={{ md: 'column' }} sx={{ mt: 3, pb: 3 }} spacing={3}>
                    <Paper elevation={4} sx={{ bgcolor: '#DDDDDD', boxShadow: 0 }}>
                        <TabContext value={params.resource}>
                            <TabPanel value='calls'>
                                <Calls />
                            </TabPanel>
                            <TabPanel value='members'>
                                <Members />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Stack>
            </Container>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    dependence: state.dependence,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Group);