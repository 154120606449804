import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, } from '@mui/material';
import { constants } from '../../../constants';

function GenAppbar(props) {
    const { hierarchy, value } = props;
    return (
        <Typography color='white' variant='h2' sx={{ mt: { xs: 9, sm: 8 } }}>
            <Box
                fontSize='inherit'
                component={constants.DEPENDENCE_INFO_FIELD[hierarchy].icon}
                color='inherit'
                sx={{ mr: 1 }}
            />
            {value}
        </Typography>
    );
}
GenAppbar.propTypes = {
    value: PropTypes.string,
    hierarchy: PropTypes.string
};
export default GenAppbar;