import { useState } from 'react';

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(0);
    const maxPage = Math.ceil(data?.length / itemsPerPage);
    function currentData() {
        const begin = (currentPage) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data?.slice(begin, end);
    }
    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }
    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }
    function jump(page) {
        const pageNumber = Math.max(0, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }
    return { next, prev, jump, currentData, currentPage, maxPage };
}
export default usePagination;